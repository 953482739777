import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '@/custom/components/organisms/ProductView'
import GridView from '@/custom/components/molecules/ProductCard/GridView'
import ListView from '@/custom/components/molecules/ProductCard/ListView'
import ProductContext from '@/custom/context/product/productContext'
import AuthContext from '@/custom/context/auth/authContext'
import AlertContext from '@/custom/context/alert/alertContext'
import Loaders from '@/custom/components/molecules/Loaders'
// import openSocket from 'socket.io-client'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import '../Dashboard.css'

// import SEO from '@/utils/SEO'

const ItemView = (props) => {
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])
    const formikSearch = props.formikSearch
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    let history = useHistory()
    let action = ''

    // const classes = useStyles()
    const { user, isAuthenticated, responseStatus, clearResponse, saveSearch } = authContext
    const { getWatchlist, watch_data, responseStatus: responseStatusProduct } = productContext

    const { setAlert } = alertContext
    const [isLoading, setIsLoading] = useState(true)
    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    useEffect(() => {
        if (state.right) {
            console.log(state, 'state data')
            getProductInfo({ product_id: `${state.data.id}` })
            setShowProduct(true)
            setClick({
                ...click,
                data: `${state.data.id}`,
            })
        }
    }, [viewProduct])

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         getAllDashboardProducts(formikSearch.values)
    //     }
    // }, [formikSearch.values, isAuthenticated])

    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        console.log(location.pathname, 'location.pathname')
        if (location.pathname == '/watchlist/watchlist') {
            action = 'watchlist'
            getWatchlist(formikSearch.values)
        }
    }, [location])

    useEffect(() => {
        if (watch_data && watch_data.records) {
            setViewProduct(watch_data.records.watchlistList)
        }
    }, [watch_data])

    // useEffect(() => {
    //     //console.log('search_alldashboardproducts', search_alldashboardproducts)
    //     setViewProduct(
    //         search_alldashboardproducts.records.length ? search_alldashboardproducts.records : [],
    //     )
    // }, [search_alldashboardproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    // useEffect(() => {
    //     if (responseStatusProduct) {
    //         if (
    //             responseStatusProduct.from === 'addWatchlist' ||
    //             responseStatusProduct.from === 'removeWatchlist'
    //         ) {
    //             if (formikSearch.values.action === 'watchlist') {
    //                 if (responseStatusProduct.status === 'success') {
    //                     getAllDashboardProducts(formikSearch.values)
    //                 }
    //             }
    //         }
    //     }
    // }, [responseStatusProduct])

    return (
        <>
            {/* <SEO title="My Bids" url={window.location.href} /> */}
            {isLoading ? (
                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
            ) : viewProduct.length ? (
                <>
                    <div className={`searchResults ${formikSearch.values.auctionView}`}>
                        <Drawer
                            className="rightDrawer"
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                        >
                            <ProductView
                                data={state.data}
                                drawerHandler={toggleDrawer('right', false)}
                                isDrawer={true}
                                project_id={click.data}
                            />
                        </Drawer>
                        {viewProduct.map((data, index) => (
                            <>
                                {formikSearch.values.auctionView === 'Grid' ||
                                (props.auctionView && props.auctionView === 'Grid') ? (
                                    <>
                                        <GridView
                                            data={data}
                                            from="dashboard"
                                            action={props.type}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </>
                                ) : (
                                    <ListView
                                        data={data}
                                        from="dashboard"
                                        action={props.type}
                                        favId={`searchProd_${index}`}
                                        drawerHandler={toggleDrawer('right', true, data)}
                                    />
                                )}
                            </>
                        ))}
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                        {/* <h6>
                            {'Showing'} {search_alldashboardproducts.setDisp} {'of '}
                            {wa}
                        </h6> */}
                        <Pagination
                            count={Math.ceil(
                                watch_data?.records?.watchlistCount / formikSearch.values.limit,
                            )}
                            page={formikSearch.values.page}
                            onChange={onHandlePage}
                            siblingCount={3}
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                        />
                    </div>
                </>
            ) : (
                <NoRecordsFound />
            )}
        </>
    )
}

export default ItemView
