import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SWITCH_LANGUAGE,
    TIMER_VALUE,
    CLEAR_BID_HISTORY,
    BID_HISTORY_VALUE,
    PENDING_INVOICES,
    UPLOADED_IMAGE,
    LOADERS,
    CLEAR_SEARCH,
    SET_MEMBERSHIP,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                ...action.payload,
            }
        case PENDING_INVOICES:
            return {
                ...state,
                noPendingInvoices: action.payload,
            }
        case SWITCH_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            }
        case CLEAR_BID_HISTORY:
            return {
                ...state,
                bidHistoryValue: 0,
            }
        case BID_HISTORY_VALUE:
            return {
                ...state,
                bidHistoryValue: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case UPLOADED_IMAGE:
            return {
                ...state,
                uploadedFiles: action.payload,
            }
        case TIMER_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            }
        case CLEAR_SEARCH:
            return {
                ...state,
                searchValue: null,
            }
        case LOADERS:
            return {
                ...state,
                isLoading: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case SET_MEMBERSHIP:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
