import {
    GET_STATIC_PAGE,
    GET_INVITE_DATA,
    GET_USER_NOTIFICATION,
    GET_USER_DETAILS,
    CLEAR_RESPONSE,
    GET_CREDITS,
    GET_REFUND,
    RESPONSE_STATUS,
    VIEW_RECEIPT,
    GET_RECEIPT,
    GET_COMPANY_OWNER_DATA,
    GET_SKYFLOW_CARDS,
} from './userTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_CREDITS:
            return {
                ...state,
                credit_data: action.payload,
            }
        case GET_REFUND:
            return {
                ...state,
                refund_data: action.payload,
            }
        case VIEW_RECEIPT:
            return {
                ...state,
                single_invoice: action.payload,
            }
        case GET_USER_NOTIFICATION:
            return {
                ...state,
                user_notification: action.payload,
            }
        case GET_USER_DETAILS:
            return {
                ...state,
                user_details: action.payload,
            }
        case GET_STATIC_PAGE:
            return {
                ...state,
                static_page: action.payload,
            }
        case GET_RECEIPT:
            return {
                ...state,
                receipt_data: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_INVITE_DATA:
            return {
                ...state,
                invite_data: action.payload,
            }
        case GET_COMPANY_OWNER_DATA:
            return {
                ...state,
                company_owner_data: action.payload,
            }
        case GET_SKYFLOW_CARDS:
            return {
                ...state,
                skyflow_cards: action.payload,
            }
        default:
            return state
    }
}
