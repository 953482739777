import React, { useState, useContext, useEffect } from 'react'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import DashboardLayout from '../Dashboard/DashboardLayout'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import SecondaryButton from '@/custom/components/atoms/SecondaryButton'
import { mapData } from '@/custom/common/components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import authContext from '@/custom/context/auth/authContext'
import StripeCardContext from '@/custom/context/stripe/card/cardContext'
import StripeCustomerContext from '@/custom/context/stripe/customer/customerContext'
import UserContext from '@/custom/context/user/userContext'
import CardDetails from '@/custom/components/organisms/CardDetails'
import CustomCommonContext from '@/custom/context/common/commonContext'

import SEO from '@/utils/SEO'

const MyCard = (props) => {
    //user's cards view page
    const [cards, setCards] = useState([])
    const [customer, setCustomer] = useState([])
    const { user, loadUser, isAuthenticated } = useContext(authContext)
    const {
        getUserProfileDetails,
        user_details,
        checkUserCardDetailsAfterSignin,
        responseStatus: responseStatusUser,
    } = useContext(UserContext)
    const {
        allLocations,
        allCategory,
        searchValue,
        setSearchValue,
        noPendingInvoices,
        setPendingInvoices,
    } = useContext(CustomCommonContext)

    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        responseStatus: customerResponseStatus,
        clearResponse,
    } = useContext(StripeCustomerContext)
    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(StripeCardContext)

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }
    //get stripe card
    const getStripeCards = () => {
        console.log('user!@#', user)
        if (user && user.stripe_customer_id) {
            console.log(user.stripe_customer_id, user, 'user.stripe_customer_id')
            getAllStripeCards({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_customer_id,
                object: 'card',
            })
        }
        toggleFullScreenCardPopup(false, 'new', null)
    }
    const { t } = useTranslation()
    //const [addCard, setAddCard] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirm_password: '',
        },
    })
    /*const addCardData = [
        {
            label: t('name'),
            name: 'name',
            type: 'email',
            placeholder: t('enter_card_holder_name'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: t('card_number'),
            name: 'card_number',
            type: 'number',
            placeholder: t('enter_card_number'),
            class: 'col-12',
            formik: formik,
        },
        {
            label: t('name'),
            name: 'name',
            type: 'email',
            placeholder: t('enter_card_holder_name'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'CVV',
            name: 'cvv',
            type: 'text',
            placeholder: t('enter') + ' CVV',
            class: 'col-12',
            formik: formik,
        },
        {
            label: t('expiry_date'),
            name: 'expiry_date',
            type: 'date',
            placeholder: t('date_of_card_expiration'),
            class: 'col-12',
            formik: formik,
        },
    ]*/
    //create stripe customer
    useEffect(() => {
        console.log(user, 'usertrt')
        if (user && user.user_id && !user.stripe_customer_id) {
            // console.log('user@#', user)
            createStripeCustomer(
                {
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    description: 'TEST CUSTOMER',
                    email: user.email,
                    name: user.first_name + ' ' + user.last_name,
                },
                true,
            )
        } else if (user && user.user_id && user.stripe_customer_id) {
            // console.log('%^&stripe', user.stripe_id)
            getStripeCustomer({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_customer_id,
            })
            getStripeCards()
        }
    }, [user])
    useEffect(() => {
        if (
            customerResponseStatus &&
            customerResponseStatus.from == 'create' &&
            customerResponseStatus.status == 'success'
        ) {
            console.log(
                customerResponseStatus.data.data.responseData.id,
                'customerResponseStatus.data.id',
            )
            let userDet = JSON.parse(localStorage.getItem('userData'))
            userDet = {
                ...userDet,
                stripe_customer_id: customerResponseStatus.data.data.responseData.id,
            }
            localStorage.setItem('userData', JSON.stringify(userDet))
            loadUser(userDet)
            // updateStripe({
            //     stripe_customer_id: customerResponseStatus.data.data.responseData.id,
            // })
            clearResponse()
        }
    }, [customerResponseStatus])

    useEffect(() => {
        if (customer_details) {
            console.log(customer_details, 'customettt')
            setCustomer(customer_details)
            if (customer_details.default_source) {
                // updateStripe({
                //     stripe_customer_id: customer_details.id,
                //     stripe_card_id: customer_details.invoice_settings.default_payment_method
                //         ? customer_details.invoice_settings.default_payment_method
                //         : customer_details.default_source,
                // })
                setTimeout(() => {
                    checkUserCardDetailsAfterSignin({})
                }, 500)
            }
        }
    }, [customer_details])

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        console.log(responseStatusCard, 'responseStatusCardresponseStatusCard')
        if (responseStatusCard && responseStatusCard.status == 'success') {
            getStripeCards()
            getStripeCustomer({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_customer_id,
            })
        }
        clearResponseCard()
    }, [responseStatusCard])
    useEffect(() => {
        if (get_all_card_details) {
            // console.log('!@#get_all_card_details', get_all_card_details)
            setCards(get_all_card_details.records)
        }
        return () => {
            setCards([])
        }
    }, [get_all_card_details])
    useEffect(() => {
        if (!user) {
            //loadUser()
        }
        // console.log('user', user)
        //loadUser()
    }, [])
    return (
        <>
            <SEO title="Cards" url={window.location.href} />
            <DashboardLayout title={'CARD DETAILS'}>
                <div className="dashboardInner mySavedCards mySavedCardBidsCnt">
                    <span>
                        {noPendingInvoices
                            ? 'Your account is Deactivated. Please press "Add Card" and input your card information (even if it is the same card). Then go to "Pending Receipts", and press "Recharge" for any Unpaid receipts. Select the newly entered card, and press "Pay Now". Once all Unpaid receipts are Recharged, your account will be Activated.'
                            : null}
                    </span>
                    <div className="bidsCardsCnt">
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                                gridGap: '15px',
                                gap: '15px',
                                background: '#fff',
                                padding: '30px 35px',
                                marginTop: '5px',
                            }}
                        >
                            <div
                                className="emptySavedCard"
                                onClick={() => toggleFullScreenCardPopup(true, 'new', null)}
                            >
                                <img
                                    src="/assets/images/cards.png"
                                    alt="bank"
                                    className="mt-1 mb-1"
                                />
                                <h6>ADD A NEW CARD</h6>
                                <img
                                    src="/assets/images/ccard.png"
                                    alt="bank"
                                    className="mt-1 mb-1"
                                />
                            </div>
                            {cards.length > 0
                                ? cards.map((card) => {
                                      return (
                                          // eslint-disable-next-line react/jsx-key
                                          <div className="saved-card">
                                              <div className="filledSavedCard">
                                                  <div className="row mt-2">
                                                      <div className="col-6 text-left">
                                                          <img
                                                              src="/assets/images/sim.png"
                                                              alt="bank"
                                                          />
                                                      </div>

                                                      {/*  */}
                                                  </div>
                                                  <div className="card-num">
                                                      <span>****</span>
                                                      <span>****</span>
                                                      <span>****</span>
                                                      <span>{card.last4}</span>
                                                  </div>
                                                  {/* {console.log('!@#card', card)} */}
                                                  <div className="clearfix">
                                                      <div className="float-left">
                                                          <div className="fs-12">{card.name}</div>
                                                      </div>
                                                      <div className="float-right">
                                                          <div className="fs-12">
                                                              {card.exp_month} /{' '}
                                                              {card.exp_year.toString().slice(-2)}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="sc-btns-wrap d-flex align-items-center justify-content-between">
                                                  {user && card.id == user?.stripe_card_id ? (
                                                      <>
                                                          <div
                                                              style={{ color: 'white' }}
                                                              className="sc-primary-label"
                                                          >
                                                              Primary Card
                                                          </div>
                                                      </>
                                                  ) : null}
                                                  <IconButton
                                                      className="edtBtn"
                                                      onClick={() =>
                                                          toggleFullScreenCardPopup(
                                                              true,
                                                              'update',
                                                              card,
                                                          )
                                                      }
                                                  >
                                                      <span className="material-icons">edit</span>
                                                  </IconButton>
                                                  {cards.length > 1 && (
                                                      <>
                                                          {user && card.id != user?.stripe_card_id && (
                                                              <>
                                                                  <Tooltip
                                                                      title="Set Primary Card"
                                                                      placement="top"
                                                                  >
                                                                      <IconButton
                                                                          className="defaultBtn"
                                                                          onClick={() =>
                                                                              toggleFullScreenCardPopup(
                                                                                  true,
                                                                                  'default',
                                                                                  card,
                                                                              )
                                                                          }
                                                                      >
                                                                          <span className="material-icons">
                                                                              check_circle
                                                                          </span>
                                                                      </IconButton>
                                                                  </Tooltip>

                                                                  <IconButton
                                                                      className="dltBtn"
                                                                      id="card_delete"
                                                                      onClick={() =>
                                                                          toggleFullScreenCardPopup(
                                                                              true,
                                                                              'delete',
                                                                              card,
                                                                          )
                                                                      }
                                                                  >
                                                                      <span className="material-icons">
                                                                          delete
                                                                      </span>
                                                                  </IconButton>
                                                              </>
                                                          )}
                                                      </>
                                                  )}
                                              </div>
                                          </div>
                                      )
                                  })
                                : ''}
                        </div>

                        <CardDetails
                            allCards={cards}
                            data={manageCard}
                            function={toggleFullScreenCardPopup}
                        />
                    </div>
                    {/*<Popup
                        open={addCard}
                        size="md"
                        handleClose={() => setAddCard(false)}
                        modaltitle={t('add_a_new_card')}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">{Object.values(mapData(addCardData))}</div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <PrimaryButton label={t('save')} />
                                </div>
                            </div>
                        </form>
                    </Popup>*/}
                </div>
            </DashboardLayout>
        </>
    )
}

export default MyCard
