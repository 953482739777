import React from 'react'
import DashboardLayout from '../DashboardLayout'
import * as Yup from 'yup'

import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import ChangePasswordForm from './ChangePasswordForm'
import '../Dashboard.css'

import SEO from '@/utils/SEO'

const MyAccount = () => {
    //Change password page
    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        old_password: Yup.string().required('Required'),
        new_password: Yup.string()
            .notOneOf([Yup.ref('old_password')], 'New password must be different')
            .min(8, 'Minimum 8 characters')
            .required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords Not Match')
            .required('Required'),
    })
    const initialValues = {
        old_password: '',
        new_password: '',
        confirm_password: '',
    }

    const changePassword = [
        {
            label: 'Current password',
            name: 'old_password',
            type: 'password',
            placeholder: 'Enter your Current password',
            class: 'col-md-6 col-sm-12',
        },
        {
            label: 'New Password',
            name: 'new_password',
            type: 'password',
            placeholder: 'Enter your New password',
            class: 'col-md-6 col-sm-12',
        },
        {
            label: 'Confirm New Password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re enter your password',
            class: 'col-md-6 col-sm-12',
        },
    ]

    return (
        <>
            <SEO title="Change Password" url={window.location.href} />
            <DashboardLayout title={'Change Password'}>
                <div className="dashboardInner myAccount">
                    <div className="auctionStatus">
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/profile">
                                {'Profile'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/change">
                                {'Change password'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/notifications">
                                {'My notifications'}
                            </NavLink>
                        </ListItem>
                    </div>
                    <ChangePasswordForm
                        validationPassword={validationPassword}
                        initialValues={initialValues}
                        data={changePassword}
                    />
                </div>
            </DashboardLayout>
        </>
    )
}

export default MyAccount
