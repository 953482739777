import React, { useState, useContext, useEffect } from 'react'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import AuthContext from '@/custom/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import { currencyFormat, dateFormatFront, mapData } from '@/custom/common/components'
import AlertContext from '@/custom/context/alert/alertContext'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import SEO from '@/utils/SEO'
import UserContext from '@/custom/context/user/userContext'
import * as Yup from 'yup'
import { Button } from '@material-ui/core'

const MyCredits = () => {
    const [toggleDialog, setToggleDialog] = useState(false)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const [user, setUser] = useState(false)

    const { setAlert } = alertContext

    const { loadUser, isAuthenticated, applyCoupon, responseStatus, clearResponse } = authContext
    const { getCreditData, credit_data, getUserProfileDetails, user_details } = userContext

    let [viewProduct, setViewProduct] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 12,
        },
    })

    const validationArray = Yup.object({
        coupon_code: Yup.string().required('Enter coupon code'),
    })

    const formikCoupon = useFormik({
        initialValues: {
            coupon_code: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log(values, 'valueddf')
            applyCoupon(values)
        },
    })

    const amount = [
        {
            label: 'Coupon Code',
            name: 'coupon_code',
            type: 'text',
            size: 'small',
            placeholder: 'Enter Coupon Code',
            class: '',
            autoFocus: false,
            formik: formikCoupon,
        },
    ]

    useEffect(() => {
        getCreditData(formik.values)
        getUserProfileDetails({})
    }, [formik.values])

    useEffect(() => {
        if (user_details && user_details?.records) {
            console.log(user_details, 'user_details')
            setUser(user_details.records.userOtherInfo)
        }
    }, [user_details])

    useEffect(() => {
        if (credit_data && credit_data?.records) {
            console.log(credit_data.records, 'refund_data')
            setViewProduct(credit_data.records.responseData.creditAndCouponList)
        }
    }, [credit_data])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }

    useEffect(() => {
        if (responseStatus && responseStatus?.from == 'applyCoupon') {
            setAlert(responseStatus.message, responseStatus.status ? 'success' : 'error')
            console.log(responseStatus, 'responseStatus')
            if (responseStatus.status) {
                getCreditData({})
                getUserProfileDetails({})
            }
        }
        clearResponse()
    }, [responseStatus])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    return (
        <DashboardLayout title={'My Credits/Coupon'}>
            <div className="dashboardInner myWallet">
                <SEO title="My Credits/Coupon" url={window.location.href} />
                <div
                    className="walletHead d-flex justify-content-between align-items-start gap-3 flex-wrap"
                    style={{ gap: '10px' }}
                >
                    <h3 className="walletBalance">
                        <span className="material-icons">local_offer</span>
                        {'Available Credits Amount'}:
                        <span>{user?.deposit_amount ? `$ ${user?.deposit_amount}` : '$0.00'}</span>
                    </h3>
                    {mapData(amount)}
                    <Button
                        id="wallet_balance"
                        variant="outlined"
                        onClick={formikCoupon.handleSubmit}
                        style={{ padding: '7px 15px' }}
                    >
                        {'Apply Coupon'}
                    </Button>
                </div>
                {/* <h5>Find refunds from your auction deposits here.</h5> */}
                {viewProduct.length ? (
                    <>
                        <div className="table-responsive dpTable">
                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">{'Coupon Code'}</th>
                                        <th scope="col">{'Date'}</th>
                                        <th scope="col">{'Amount'}</th>
                                        <th scope="col">{'Notes'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viewProduct.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.coupon_code || '-'}</td>
                                            <td>{dateFormatFront(data.date_added)}</td>
                                            <td>{currencyFormat(data.amount)}</td>
                                            <td>{data.credit_notes}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                            {/* <h6>
                                {'Showing'} {search_allusercredit.setDisp} {'of'}{' '}
                                {search_allusercredit.totalRecords}
                            </h6>
                             */}
                            <Pagination
                                count={Math.ceil(
                                    credit_data?.records?.responseData?.creditAndCouponCount /
                                        formik.values.limit,
                                )}
                                page={formik.values.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
        </DashboardLayout>
    )
}

export default MyCredits
