import axios from 'axios'
import { decryptData, encryptData } from './tweetnacl'
// import { decryptData, encryptData } from './components'
//All Backend API connections are done here
const apiCall = async (method, url, data, headertype, baseurl, custom) => {
    if (global.decryptData && data) {
        data = data instanceof FormData ? data : encryptData(data)
    }
    let site_url = `${global.site_url}/`
    if (baseurl) {
        site_url = `${global.site_url}/${baseurl}/`
    }
    if (custom) {
        site_url = `${global.site_url}/custom/`
        if (baseurl) {
            site_url = `${global.site_url}/custom/${baseurl}/`
        }
    }
    return new Promise(async (resolve, reject) => {
        let type = ''
        if (headertype && headertype === 'formdata') {
            type = 'multipart/form-data'
        } else {
            type = 'application/json'
        }
        const config = {
            headers: {
                'content-type': type,
            },
        }
        switch (method) {
            case 'post':
                try {
                    data = data ? data : {}
                    const res = await axios.post(`${site_url}${url}`, data, config)
                    if (global.decryptData && res.data) {
                        res.data = data instanceof FormData ? res.data : decryptData(res.data)
                    }
                    resolve(res)
                } catch (err) {
                    console.log('responsode error from api', err)
                    if (global.decryptData && err.data) {
                        err.data = decryptData(err.data)
                    }
                    resolve(err)
                }
                break
            case 'get':
                try {
                    const res = await axios.get(`${site_url}${url}`, config)
                    if (global.decryptData && res.data) {
                        res.data = decryptData(res.data)
                    }
                    resolve(res)
                } catch (err) {
                    console.log('responsode error from api', err)
                    if (global.decryptData && err.data) {
                        err.data = decryptData(err.data)
                    }
                    resolve(err)
                }
                break
            default:
                return null
                break
        }
    })
}

const apiCallSeller = async (method, url, data, headertype, baseurl, common, custom) => {
    if (global.decryptData && data) {
        data = data instanceof FormData ? data : encryptData(data)
    }
    let site_url = `${global.site_url}/api/seller/`
    if (baseurl) {
        site_url = `${global.site_url}/api/seller/${baseurl}/`
    }

    if (common) {
        site_url = `${global.site_url}/api/commonFunction/`
        if (baseurl) {
            site_url = `${global.site_url}/api/commonFunction/${baseurl}/`
        }
    }

    if (custom) {
        site_url = `${global.site_url}/api/custom/`
        if (baseurl) {
            site_url = `${global.site_url}/api/custom/${baseurl}/`
        }
    }

    return new Promise(async (resolve, reject) => {
        let type = ''
        if (headertype && headertype === 'formdata') {
            type = 'multipart/form-data'
        } else {
            type = 'application/json'
        }
        const config = {
            headers: {
                'content-type': type,
            },
        }
        switch (method) {
            case 'post':
                try {
                    data = data ? data : {}
                    const res = await axios.post(`${site_url}${url}`, data, config)
                    console.log('responsode from api', res)
                    if (global.decryptData && res.data) {
                        res.data = data instanceof FormData ? res.data : decryptData(res.data)
                    }
                    resolve(res)
                } catch (err) {
                    console.log('responsode error from api', err)
                    if (global.decryptData && err.data) {
                        err.data = decryptData(err.data)
                    }
                    resolve(err)
                }
                break
            case 'get':
                try {
                    console.log('get method', url, config, 'change')
                    const res = await axios.get(`${site_url}${url}`, config)
                    console.log('response get ode from api', res)
                    if (global.decryptData && res.data) {
                        res.data = decryptData(res.data)
                    }
                    resolve(res)
                } catch (err) {
                    console.log('responsode error from api', err)
                    if (global.decryptData && err.data) {
                        err.data = decryptData(err.data)
                    }
                    resolve(err)
                }
                break
            default:
                return null
                break
        }
    })
}

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['authorization']
    }
}

const setIPAddress = (ipaddress) => {
    if (ipaddress) {
        axios.defaults.headers.common['ipaddress'] = ipaddress
    } else {
        delete axios.defaults.headers.common['ipaddress']
    }
}
export { apiCall, apiCallSeller, setAuthToken, setIPAddress }
