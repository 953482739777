import { Button, Tooltip } from '@material-ui/core'
import React, { useState, useContext, useEffect, useRef } from 'react'
import CustomInput from '@/custom/components/atoms/Inputs/CustomInput'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import '@/custom/components/molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '@/custom/components/atoms/FavoriteCheckbox'
import SecondaryButton from '@/custom/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '@/product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BuyerContext from '@/custom/context/buyer/buyerContext'
import AuthContext from '@/custom/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import ProductContext from '@/custom/context/product/productContext'
import CommonContext from '@/custom/context/common/commonContext'
import CustomDialog from '@/custom/components/organisms/Dialog'
import { handleRedirectInternal } from '@/custom/common/components'
import { currencyFormat } from '@/custom/common/components'
import { mapData } from '@/custom/common/components'
import CustomCommonContext from '@/custom/context/common/commonContext'
import UserContext from '@/custom/context/user/userContext'
import AlertContext from '@/custom/context/alert/alertContext'

let randomVariable = Math.random()

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { t } = useTranslation()
    const { setAlert } = alertContext
    const {
        signupModal,
        setSignupModal,
        loginModal,
        setLoginModal,
        allOrganization,
        alldata_all_dropdown,
    } = customCommonContext

    console.log(loginModal, 'login ===>')

    const { searchValue } = commonContext
    const { getAllSearchProducts, getWatchlistCount } = productContext

    let { bidConfirm, bidRestrict, responseStatus, clearResponse } = buyerContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = useState({})
    const [cardCount, setCardCount] = useState(0)
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogCard, setToggleDialogCard] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)
    const [toggleDialogLocation, setToggleDialogLocation] = useState(false)
    const [retrievalOption, setRetrievalOption] = useState([])
    const [userLocation, setUserLocation] = useState(false)
    const [reload, setReload] = useState(false)
    const [bidUpdated, setBidUpdated] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeDialogStatusCard = () => {
        setToggleDialogCard(!toggleDialogCard)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    const changeDialogStatusLocation = () => {
        setToggleDialogLocation(!toggleDialogLocation)
    }

    useEffect(() => {
        if (Object.keys(props.data).length) {
            setProduct({
                ...props.data.prodet,
                incrementamt: props.data.incrementamt,
                next_bid: props.data.next_bid,
            })
            formik.values.id = props.data.prodet.id
            formik.values.product_id = props.data.prodet.id
            setToggleDialog(false)
            console.log(props.data, 'propingdata')
        } else {
            setProduct({})
        }
    }, [props.data])

    useEffect(() => {
        console.log(responseStatus, 'responseStatusBidinside')
        if (
            responseStatus &&
            (responseStatus.from == 'bidRestrict' || responseStatus.from == 'quick')
        ) {
            if (responseStatus.status !== 'error' && responseStatus.from == 'bidRestrict') {
                getWatchlistCount({})
                setToggleDialog(true)
            } else if (responseStatus.status !== 'error' && responseStatus.from == 'quick') {
                getWatchlistCount({})
                handleQuickSubmit()
            } else {
                setAlert(responseStatus.message, 'error')
                setToggleDialog(false)
            }
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        if (product) {
            let options = []

            // product['location_id']?.split(',')?.map((data, index) =>
            //     options.push({
            //         show: alldata_all_dropdown[
            //             alldata_all_dropdown.findIndex((ele) => ele.id == data)
            //         ]?.description,
            //         value: data,
            //     }),
            // )
            // setRetrievalOption(options)
            formik.setFieldValue('id', product.id)
            formikPayment.setFieldValue('cart_id', product.id)
        }
    }, [product])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(
                product ? product.next_bid : 0,
                `${'Min bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .required('Enter Bid'),
    })

    const validationPayment = Yup.object({
        payment_method: Yup.string().required('Required'),
    })

    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }

    const formik = useFormik({
        initialValues: {
            product_id: 0,
            id: 0,
            bid_increment: 0,
            testbid_incre: 0,
            bidplan: 'auto',
            next_bid: '',
            spc_startp: '',
            realtime_nbid: '',
            wsprice: '',
            email: '',
            first_name: '',
            last_name: '',
            bidding_type: 'proxy',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log(toggleDialog, values, 'dialll')
            if (toggleDialog) {
                formik.setSubmitting(false)
                values.first_name = user?.first_name
                values.last_name = user?.last_name
                values.email = user?.email
                values.realtime_nbid = parseFloat(product.next_bid).toFixed(2)
                values.bid_increment = parseFloat(product.incrementamt).toFixed(2)
                values.testbid_incre = product.incrementamt
                values.next_bid = parseFloat(product.next_bid).toFixed(2)
                values.spc_startp = product.startprice
                console.log(values, 'submitting values')
                bidConfirm(values)
                setToggleDialog(false)
                formik.values.wsprice = ''
                formik.setTouched({})
            } else {
                bidRestrict({})
                // setToggleDialog(true)
            }
        },
    })

    const handleQuickSubmit = () => {
        formik.setSubmitting(false)
        formik.values.first_name = user?.first_name
        formik.values.last_name = user?.last_name
        formik.values.email = user?.email
        formik.values.realtime_nbid = parseFloat(product.next_bid).toFixed(2)
        formik.values.bid_increment = parseFloat(product.incrementamt).toFixed(2)
        formik.testbid_incre = product.incrementamt
        formik.values.next_bid = parseFloat(product.next_bid).toFixed(2)
        formik.values.spc_startp = product.startprice
        formik.values.wsprice = parseFloat(product.next_bid).toFixed(2)
        console.log(formik.values, 'submitting values')
        bidConfirm(formik.values)
        formik.setFieldValue('wsprice', '')
        formik.setTouched({})
    }

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const formikPayment = useFormik({
        initialValues: {
            cart_id: 0,
            pay_type: 'bid_deposit',
            payment_method: '',
        },
        validationSchema: validationPayment,
        onSubmit: (values) => {
            formikPayment.setSubmitting(true)
            if (values.payment_method === 'wallet') {
                let formData = {
                    amount: product.deposit_amount,
                    product_id: values.cart_id,
                    credit_notes: `Bid Deposit - ${product.title}`,
                    credit_type: 'user',
                    type: 'projects',
                    creditor_id: user.id,
                }
            } else if (values.payment_method === 'online') {
                let formData = {
                    cart_id: values.cart_id,
                    payment_object: {
                        type: 'projects',
                        amount: 0,
                    },
                    pay_type: values.pay_type,
                }
                // payCartRosoom(formData)
            }
        },
    })

    const bidAmount = [
        {
            // label:
            //     formik.values.bidding_type === 'hard'
            //         ? product && product.next_bid
            //             ? currencyFormat(product.next_bid)
            //             : currencyFormat(0)
            //         : 'Enter max bid',
            label: '',
            name: 'wsprice',
            restrictTwoDigits: true,
            type: 'number',
            // placeholder: t(
            //     product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0),
            // ),
            placeholder: '$ Enter Bid',
            helperText: '',
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
        },

        // {
        //     helperText:
        //         props.helpText &&
        //         (product && product.bidhistory.length > 0
        //             ? 'Enter ' + currencyFormat(product.next_bid) + ' or more'
        //             : product && 'Minimum_Bid' + ' ' + currencyFormat(product.next_bid)),
        //     label: 'Enter Bid Amt',
        //     name: 'wsprice',
        //     type: 'number',
        //     placeholder: `${'Min Bid'} ${
        //         product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
        //     }`,
        //     class: '',
        //     size: 'small',
        //     disabled: canBid ? false : true,
        //     autoFocus: false,
        //     formik: formik,
        //     onChange: removeAlpha,
        // },
    ]

    const modeOfPayment = [
        {
            label: t('Select mode of payment'),
            placeholder: t('Select mode of payment'),
            type: 'select',
            class: 'col-12',
            options: [
                { value: 'online', show: t('online') },
                { value: 'wallet', show: t('wallet') },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]

    const validationLocation = Yup.object({
        location_id: Yup.string().required('Required'),
    })

    const formikLocation = useFormik({
        initialValues: {
            location_id: '',
        },
        validationSchema: validationLocation,
        onSubmit: (values) => {
            formikLocation.setSubmitting(true)
            if (values.location_id !== '') {
                let formData = {
                    table: 'user_retrieval_location',
                    user_id: user.id,
                    project_id: product.id,
                    location_id: values.location_id,
                }
                // dynamicTableAction(formData, 'submit_location')
            }
        },
    })

    const retrievalOptions = [
        {
            label: t('Select Retrieval Location'),
            placeholder: t('Select Retrieval Location'),
            type: 'select',
            class: 'col-12',
            options: retrievalOption.length ? retrievalOption : [],
            name: 'location_id',
            formik: formikLocation,
        },
    ]

    const confirmDetails = [
        {
            label: 'Confirm automatically future bids for this item',
            placeholder: t('Select Retrieval Location'),
            type: 'checkbox',
            class: 'col-12',
            name: 'confirm_bid',
            formik: formik,
        },
    ]

    // useEffect(() => {
    //     if (
    //         dynamicTableValue?.records?.length &&
    //         dynamicTableValue.from === 'previous_user_location'
    //     ) {
    //         setUserLocation(true)
    //     } else if (
    //         dynamicTableValue?.records?.length &&
    //         dynamicTableValue.from === `previous_bidding_details${props.data.id}`
    //     ) {
    //         if (formik.values.wsprice !== '') {
    //             bidConfirm(formik.values)
    //             formik.values.wsprice = ''
    //             setToggleDialog(false)
    //             formik.setTouched({})
    //         }
    //     } else if (
    //         dynamicTableValue?.records?.length <= 0 &&
    //         dynamicTableValue.from === `previous_bidding_details${props.data.id}`
    //     ) {
    //         setToggleDialog(true)
    //     } else {
    //         setUserLocation(false)
    //         setToggleDialog(false)
    //     }
    // }, [dynamicTableValue])
    useEffect(() => {
        console.log(toggleDialog, 'toggg')
    }, [toggleDialog])

    const handleQuick = () => {
        if (user?.quick_enable && user?.quick_enable == '1') {
            bidRestrict({}, 'quick')
        } else {
            setAlert('Enable Quick Bid Button in your Customer Profile', 'error')
        }
    }

    // useEffect(() => {
    //     if (props.quickBid) {
    //         formik.setFieldValue('wsprice', product && product.next_bid ? product.next_bid : 0)
    //     }
    // }, [props.quickBid, product])

    console.log(user?.quick_enable, 'user?.quick_enable')

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start biddingAlignCnt">
                                {props.quickBid ? (
                                    <>
                                        <div className="biddingCnt">
                                            {isAuthenticated ? (
                                                product?.bidtopstatus?.includes('winning') ||
                                                product?.bidtopstatus == 'winner' ? (
                                                    <PrimaryButton
                                                        className={
                                                            user?.quick_enable &&
                                                            user?.quick_enable == '1'
                                                                ? ''
                                                                : 'quickBidNotEnable'
                                                        }
                                                        id="increase_hard_bid"
                                                        label={
                                                            formik.values.bidding_type ===
                                                            'hard' ? (
                                                                <>
                                                                    <span>QUICK BID: </span>
                                                                    {`${currencyFormat(
                                                                        product.next_bid,
                                                                    )}`}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>Increase Max Bid: </span>
                                                                    {`${currencyFormat(
                                                                        product.next_bid,
                                                                    )}`}
                                                                </>
                                                            )
                                                        }
                                                        onClick={() => handleQuick()}
                                                        btnSize="small quickBid"
                                                    />
                                                ) : (
                                                    <>
                                                        <PrimaryButton
                                                            id="place_hard_bid"
                                                            onClick={() => handleQuick()}
                                                            btnSize={`small  ${
                                                                formik.values.bidding_type ===
                                                                'hard'
                                                                    ? 'quickBid'
                                                                    : 'proxyBid'
                                                            }`}
                                                            className={
                                                                user?.quick_enable &&
                                                                user?.quick_enable == '1'
                                                                    ? ''
                                                                    : 'quickBidNotEnable'
                                                            }
                                                        >
                                                            {formik.values.bidding_type ===
                                                            'hard' ? (
                                                                <>
                                                                    <span>Quick Bid: </span>
                                                                    {`${currencyFormat(
                                                                        product.next_bid,
                                                                    )}`}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>Quick Bid: </span>{' '}
                                                                    {` ${currencyFormat(
                                                                        product.next_bid,
                                                                    )}`}
                                                                </>
                                                            )}
                                                        </PrimaryButton>
                                                    </>
                                                )
                                            ) : (
                                                <PrimaryButton
                                                    id="login_to_bid"
                                                    label={t('Login to bid')}
                                                    btnSize="small"
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'login')
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : null}
                                {
                                    <>
                                        {/* <br /> */}
                                        <div className="biddingCnt biddingInputBtnCnt">
                                            <div className="bidBoxCnt">
                                                <div className="customBiddingField">
                                                    {Object.values(mapData(bidAmount))}
                                                </div>
                                                {/* Helper Text */}
                                                <p className="MuiFormHelperText-root text-left MuiFormHelperText-contained MuiFormHelperText-marginDense">
                                                    {formik.values.bidding_type === 'hard'
                                                        ? ''
                                                        : `Enter ${
                                                              product && product.next_bid
                                                                  ? currencyFormat(product.next_bid)
                                                                  : currencyFormat(0)
                                                          } or more`}
                                                </p>
                                            </div>
                                            <p className="bidInstruction">
                                                <b>
                                                    *Bid amount does not include $3 minimum, or 17%
                                                    Buyer premium, whichever is greater.
                                                </b>
                                            </p>

                                            {/* Helper Text */}
                                            {isAuthenticated ? (
                                                product.bidtopstatus === 'winner' ? (
                                                    <PrimaryButton
                                                        id="increase_hard_bid"
                                                        label={
                                                            formik.values.bidding_type === 'hard'
                                                                ? t('Increase bid')
                                                                : t('Increase max bid')
                                                        }
                                                        type="submit"
                                                        btnSize="small"
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        id="place_hard_bid"
                                                        label={
                                                            formik.values.bidding_type === 'hard'
                                                                ? 'Place max bid'
                                                                : 'Place max bid'
                                                        }
                                                        type="submit"
                                                        btnSize="small"
                                                    />
                                                )
                                            ) : (
                                                <PrimaryButton
                                                    id="login_to_bid"
                                                    label={t('Login to bid')}
                                                    btnSize="small"
                                                    onClick={
                                                        () =>
                                                            handleRedirectInternal(history, 'login')
                                                        // setLoginModal(true)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title={'Bid Confirmation'}
                        open={toggleDialog ? true : false}
                        function={changeDialogStatus}
                    >
                        <h5>{`Place a bid for up to ${
                            formik.values.wsprice ? currencyFormat(formik.values.wsprice) : ''
                        } for "${product.title}"`}</h5>

                        {/* <h5>{t('Please confirm if you want to continue with this action')}</h5> */}
                        {/* <div className="row">{Object.values(mapData(confirmDetails))}</div> */}

                        <div className="actionWrapper">
                            <Button id="cancel" onClick={() => changeDialogStatus()}>
                                {t('Cancel')}
                            </Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                id="confirm_bid_submit"
                                type="submit"
                                label={'Confirm'}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={'No Cards Found'}
                        open={toggleDialogCard}
                        function={changeDialogStatusCard}
                        className="cardCnfModal"
                    >
                        <h5>{"You haven't added any cards yet!"}</h5>
                        <h5>{'Please add a card to continue Bidding'}</h5>
                        <div className="actionWrapper">
                            <Button
                                id="cancel"
                                className="cancelBtn"
                                onClick={() => setToggleDialogCard(false)}
                            >
                                Cancel
                            </Button>
                            <PrimaryButton
                                id="add_card"
                                label="Continue"
                                btnSize="small"
                                onClick={() =>
                                    handleRedirectInternal(history, 'payments/saved_cards')
                                }
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={t('Retrieval Location')}
                        open={toggleDialogLocation}
                        function={changeDialogStatusLocation}
                        disableBackdropClick
                    >
                        <p>{t('Retrieval Location has to be selected before placing bid')}</p>

                        <div className="row">{Object.values(mapData(retrievalOptions))}</div>

                        <div className="actionWrapper">
                            <Button
                                id="location_cancel"
                                onClick={() => setToggleDialogLocation(false)}
                            >
                                {t('Cancel')}
                            </Button>
                            <PrimaryButton
                                id="location_submit"
                                onClick={formikLocation.handleSubmit}
                                type="button"
                                label={t('Submit')}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={t('Submit a deposit')}
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                    >
                        <h4>{t('Deposit has to be placed before placing bid on the product')}</h4>
                        <h5>
                            {t('Deposit amount')}: {currencyFormat(product.deposit_amount)}
                        </h5>

                        <div className="row">{Object.values(mapData(modeOfPayment))}</div>

                        <div className="actionWrapper">
                            <Button
                                id="bidding_cancel"
                                onClick={() => setToggleDialogDeposit(false)}
                            >
                                {t('Cancel')}
                            </Button>
                            <PrimaryButton
                                id="bidding_submit"
                                onClick={formikPayment.handleSubmit}
                                type="button"
                                label={t('Submit')}
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
