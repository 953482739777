import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button, Fade, IconButton, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '@/custom/components/atoms/Inputs/CustomInput'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import SecondaryButton from '@/custom/components/atoms/SecondaryButton'
import './ProductCard.css'
import FavouriteCheckbox from '@/custom/components/atoms/FavoriteCheckbox'
// import Popup from '@/custom/organisms/Popup'
import Timer from '@/custom/common/timer'
import { useFormik } from 'formik'
// import Buynow from '../Buynow/BuynowItem'
import {
    handleRedirectInternal,
    currencyFormat,
    HtmlToText,
    dateFormatFront,
} from '@/custom/common/components'
import CustomCommonContext from '@/custom/context/common/commonContext'
import ProductContext from '@/custom/context/product/productContext'
import AuthContext from '@/custom/context/auth/authContext'
// import Bidding from '../Bidding/BiddingItem'
import { FALLBACK_IMAGE } from '@/utils'
import CustomDialog from '../../organisms/CustomDialog'
import AlertContext from '@/custom/context/alert/alertContext'
const ListView = (props) => {
    const [ctgDialog, setCtgDialog] = useState(false)
    const [condition, setCondition] = useState(false)
    const [notesValue, setNotesValue] = useState('')
    const [editNotes, setEditNotes] = useState(false)
    const [productCondition, setProductCondition] = useState()
    const [addCard, setAddCard] = useState(false)
    const [notes, setNotes] = useState(false)
    let product = props.data
    const authContext = useContext(AuthContext)
    const [discount, setDiscount] = useState('')
    const customCommonContext = useContext(CustomCommonContext)
    const {
        addLotNotes,
        removeLotNotes,
        updateLotNotes,
        responseStatus,
        clearResponse,
    } = useContext(ProductContext)

    const { user, isAuthenticated } = authContext
    const {
        configVariables,
        allLocations,
        alldata_all_dropdown,
        setBidHistoryValue,
    } = customCommonContext
    const { setAlert } = useContext(AlertContext)

    const history = useHistory()

    const toggleModal = (state, action) => {
        setAddCard(!addCard)
    }

    const handleConditionDialog = (data) => {
        setCondition(data)
        setCtgDialog(true)
    }

    const handleCloseDialog = () => {
        setCtgDialog(false)
        setCondition(false)
    }

    useEffect(() => {
        // console.log(props.data, 'propsdata')

        if (product && product.msrp && product.msrp > 0) {
            let sku_count = product?.sku_count ? product?.sku_count : 1
            let msrp_org = parseFloat(product?.msrp * sku_count).toFixed(2)
            let discount =
                (parseFloat(msrp_org) - parseFloat(product.wprice).toFixed(2)) /
                parseFloat(msrp_org)
            discount = parseInt(discount * 100)
            console.log(discount, 'disccc')
            if (discount > 0) {
                setDiscount(`${discount} %`)
            } else {
                setDiscount('')
            }
        } else {
            setDiscount('')
        }
    }, [product])

    useEffect(() => {
        if (product?.notes && product?.notes !== '') {
            formik.values.lot_notes_id = product?.noteId
            setNotesValue(product?.notes)
            setEditNotes(false)
        } else {
            formik.values.lot_notes_id = 0
            formik.values.lot_notes = ''
            setEditNotes(true)
            setNotesValue('')
        }
    }, [product])

    const formik = useFormik({
        initialValues: { product_id: '', lot_notes: '', add: 1, lot_notes_id: 0 },
        onSubmit: (values) => {
            if (values.add && values.lot_notes_id) {
                if (values.lot_notes !== '') {
                    updateLotNotes(
                        {
                            lot_notes: formik.values.lot_notes,
                            lot_notes_id: formik.values.lot_notes_id,
                            product_id: props.data.id,
                        },
                        `updatenotes${user?.id}${props.data.id}`,
                    )
                } else {
                    setAlert('Please Enter Bidding Notes', 'error')
                }
            } else if (values.add) {
                if (values.lot_notes !== '') {
                    addLotNotes(
                        {
                            lot_notes: formik.values.lot_notes,
                            product_id: props.data.id,
                        },
                        `addnotes${user?.id}${props.data.id}`,
                    )
                } else {
                    setAlert('Please Enter Bidding Notes', 'error')
                }
            } else {
                removeLotNotes(
                    {
                        lot_notes_id: formik.values.lot_notes_id,
                        product_id: props.data.id,
                    },
                    `deletenotes${user?.id}${props.data.id}`,
                )
            }
        },
    })

    const notesAddFunction = () => {
        if (formik.values.add && formik.values.lot_notes_id) {
            addLotNotes(
                {
                    lot_notes: formik.values.lot_notes,
                    product_id: props.data.id,
                },
                `addnotes${user?.id}${props.data.id}`,
            )
        } else if (values.add) {
            updateLotNotes(
                {
                    lot_notes: formik.values.lot_notes,
                    lot_notes_id: formik.values.lot_notes_id,
                    product_id: props.data.id,
                },
                `addnotes${user?.id}${props.data.id}`,
            )
        }
    }

    const deleteNotesFunction = () => {
        removeLotNotes(
            {
                lot_notes_id: formik.values.lot_notes_id,
                product_id: props.data.id,
            },
            `deletenotes${user?.id}${props.data.id}`,
        )
    }

    useEffect(() => {
        if (responseStatus && responseStatus.status == 'success') {
            console.log(responseStatus, 'responseStatus')
            setAlert(responseStatus.message, responseStatus.status)
            if (responseStatus.from == `addnotes${user?.id}${props?.data?.id}`) {
                product.notes = notesValue
                product.noteId = responseStatus.data.lotNotesId
                formik.values.lot_notes_id = responseStatus.data.lotNotesId
                setEditNotes(false)
            } else if (responseStatus.from == `updatenotes${user?.id}${props?.data?.id}`) {
                product.notes = notesValue
                setEditNotes(false)
            } else if (responseStatus.from == `deletenotes${user?.id}${props?.data?.id}`) {
                product.notes = ''
                product.noteId = 0
                formik.values.lot_notes = ''
                formik.values.lot_notes_id = 0
                setNotesValue('')
            }
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        // setProductCondition(
        //     alldata_all_dropdown[alldata_all_dropd own.findIndex((ele) => ele.id == data)]
        //         ?.description,
        // )
        // console.log(product, 'pro ==>')
    })

    const handleChange = (event, newPage) => {
        setNotesValue(event.target.value)
    }

    const handleTerms = () => {
        let newWindow = null
        newWindow = window.open(
            `${process.env.REACT_APP_FRONTEND_URL}/terms`,
            '_blank',
            'noopener,noreferrer',
        )

        if (newWindow) newWindow.opener = null
    }

    const [inputFieldToggle, setInputFieldToggle] = useState(false)

    const handleInputFieldToggle = () => {
        setInputFieldToggle((prev) => !prev)
    }

    return (
        <>
            <Fade in={true} timeout={600}>
                <div className="productCardList d-flex justify-content-between align-items-start">
                    <div className="psListLt d-flex">
                        <div className="pclImg">
                            {console.log(product, '===>product')}
                            <img
                                src={product.second_image}
                                onError={(e) => FALLBACK_IMAGE(e)}
                                alt={product.ptitle}
                                onClick={props.drawerHandler}
                            />
                            {props.pastPage ? null : (
                                <FavouriteCheckbox
                                    watchlisted={product.watchlist_id}
                                    project_id={product.id}
                                />
                            )}

                            {/* <div className="listBidStatus">
                                {isAuthenticated && (
                                    <>
                                        {product.bidtopstatus === 'losing this item' && (
                                            <h4 className="productWinningStatus outbid">
                                                {product.wprice < product.rprice
                                                    ? 'Reserve not met'
                                                    : 'Outbid'}
                                            </h4>
                                        )}
                                        {product?.bidtopstatus &&
                                            product?.bidtopstatus?.includes('winning') && (
                                                <h4 className="productWinningStatus winning">
                                                    {product.wprice < product.rprice
                                                        ? 'Reserve not met'
                                                        : 'Winning'}
                                                </h4>
                                            )}
                                        {product?.bidtopstatus &&
                                            product?.bidtopstatus?.includes('won') && (
                                                <h4 className="productWinningStatus won">
                                                    {'Won'}
                                                </h4>
                                            )}
                                        {product?.bidtopstatus?.includes('lost') && (
                                            <h4 className="productWinningStatus lost">{'Lost'}</h4>
                                        )}
                                       
                                    </>
                                )}
                            </div> */}
                        </div>

                        <div className="listContent">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* <small className="status">{product.conditionunique}</small> */}
                                <small className="location">{product.location}</small>
                            </div>
                            <h2
                                onClick={props.drawerHandler}
                                className="listProdTitle"
                                id="list_product_title"
                            >
                                {product.ptitle}
                            </h2>
                            <div className="listLotDetails">{HtmlToText(product.description)}</div>

                            {/* <div className="listBidInfo d-flex justify-content-start align-items-center">
                            <h6>{t('auction')} id:</h6>
                            <p className="d-flex justify-content-between align-items-center">
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}
                            <div className="listBidInfo d-flex justify-content-start align-items-center">
                                <h6>Condition :</h6>
                                <span
                                    onClick={() => handleConditionDialog(product.conditionunique)}
                                    className={`baDge ${
                                        product.conditionunique.toLowerCase() == 'appears new'
                                            ? 'Nw'
                                            : product.conditionunique.toLowerCase() == 'damaged'
                                            ? 'dm'
                                            : product.conditionunique.toLowerCase() == 'opened box'
                                            ? 'oB'
                                            : product.conditionunique == 'Scratch & Dent – Damaged'
                                            ? 'sD'
                                            : product.conditionunique ==
                                                  'Scratch & Dent – New Condition' ||
                                              product.conditionunique ==
                                                  'Scratch & Dent – Good Condition' ||
                                              product.conditionunique ==
                                                  'Scratch & Dent – Fair Condition'
                                            ? 'Nw'
                                            : 'sv'
                                    } `}
                                >
                                    {product.conditionunique}
                                </span>
                            </div>
                            {/* <div className="listBidInfo d-flex justify-content-start align-items-center">
                                <h6>Floor Location :</h6>
                                {product.inventory_loc}
                            </div> */}

                            {product.market_status === 'open' ? (
                                <>
                                    <>
                                        <div className="listBidInfo d-flex justify-content-start align-items-center">
                                            <h6>Current Price :</h6>
                                            <p className="d-flex justify-content-between align-items-center">
                                                <span>{currencyFormat(product.wprice)}</span>
                                                {/* <span className="retailPrice ml-2">
                                                        (<s>$597.00</s>)
                                                    </span> */}
                                                {/* <span className="listDivider">|</span>
                                                <span
                                                    className="cursorDecoy"
                                                    onClick={() => setBidHistoryValue(product.id)}
                                                >
                                                    {product.bid_count} Bids
                                                </span> */}
                                            </p>
                                        </div>
                                        {/* {product?.msrp > 0 &&
                                        product?.pricestart > 0 &&
                                        product?.msrp * product?.sku_count > product?.pricestart ? (
                                            <div className="pvOfferZone">
                                                <p>
                                                    Retail: <s>${product?.msrp}</s>
                                                </p>
                                                <p>
                                                    Save <span className="disCnut">{discount}</span>{' '}
                                                    off retail price
                                                </p>
                                            </div>
                                        ) : null} */}
                                    </>
                                </>
                            ) : (
                                <>
                                    {product.latestbid == 0 ? null : (
                                        <div className="listBidInfo d-flex justify-content-start align-items-center">
                                            <h6>Sold Price :</h6>
                                            <p className="d-flex justify-content-between align-items-center">
                                                <span>{currencyFormat(product.wprice)}</span>
                                                {product.market_status === 'open' ? (
                                                    <>
                                                        <span className="listDivider">|</span>
                                                        <span
                                                            className="cursorDecoy"
                                                            onClick={() =>
                                                                setBidHistoryValue(product.id)
                                                            }
                                                            id="list_bidcount"
                                                        >
                                                            {product.bid_count} Bids
                                                        </span>
                                                    </>
                                                ) : null}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}

                            <div className="listBidInfo d-flex justify-content-start align-items-center">
                                <h6>Lot ID:</h6>
                                {product.auctionid}
                            </div>

                            {product.market_status &&
                                (product?.msrp > 0 &&
                                product?.pricestart > 0 &&
                                product?.msrp * product?.sku_count > product?.pricestart &&
                                discount != '' ? (
                                    <div className="pvOfferZone">
                                        <h6>Retail:</h6>
                                        <p>
                                            <s>
                                                {' '}
                                                $
                                                {product?.msrp
                                                    ? parseFloat(
                                                          product?.msrp * product?.sku_count,
                                                      ).toFixed(2)
                                                    : null}
                                            </s>
                                        </p>
                                        <p>
                                            {discount.length > 1 && (
                                                <>
                                                    Save <span className="disCnut">{discount}</span>{' '}
                                                    off retail price
                                                </>
                                            )}
                                        </p>
                                    </div>
                                ) : null)}
                            {product.market_status !== 'open' && product.latestbid == 0 ? (
                                <div
                                    style={{ fontWeight: '500' }}
                                    className="listBidInfo d-flex justify-content-start align-items-center"
                                >
                                    No Bids
                                </div>
                            ) : null}

                            {product.market_status === 'open' &&
                                isAuthenticated &&
                                (product.notes && product.notes !== '' && !editNotes ? (
                                    <div className="customNotesBox">
                                        <span className="font-weight-bold">Notes:</span>
                                        <p className="mb-0"> {product.notes}</p>

                                        <IconButton
                                            onClick={() => {
                                                formik.values.add = 0
                                                formik.handleSubmit()
                                                setInputFieldToggle(false)
                                            }}
                                        >
                                            <i className="material-icons">delete</i>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setEditNotes(true)
                                                setInputFieldToggle(true)
                                            }}
                                        >
                                            <i className="material-icons">edit</i>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div className="customNotesBox">
                                        {/* <CustomInput
                                            size="small"
                                            placeholder="Bidding Notes"
                                            value={notesValue}
                                            maxLength={40}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                        <IconButton
                                            onClick={() => {
                                                formik.values.lot_notes = notesValue
                                                formik.values.add = 1
                                                formik.handleSubmit()
                                            }}
                                        >
                                            <i className="material-icons">check</i>
                                        </IconButton> */}
                                        {product.notes ? (
                                            <>
                                                <CustomInput
                                                    size="small"
                                                    placeholder="Bidding Notes"
                                                    value={notesValue}
                                                    maxLength={40}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        formikNotes.values.lot_notes = notesValue
                                                        formikNotes.values.add = 1
                                                        formikNotes.handleSubmit()
                                                    }}
                                                >
                                                    <i className="material-icons">check</i>
                                                </IconButton>
                                            </>
                                        ) : inputFieldToggle ? (
                                            <>
                                                <CustomInput
                                                    size="small"
                                                    placeholder="Bidding Notes"
                                                    value={notesValue}
                                                    maxLength={40}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        formik.values.lot_notes = notesValue
                                                        formik.values.add = 1
                                                        formik.handleSubmit()
                                                    }}
                                                >
                                                    <i className="material-icons">check</i>
                                                </IconButton>
                                                <IconButton
                                                    style={{ padding: '0px' }}
                                                    onClick={() => {
                                                        setInputFieldToggle(false)
                                                    }}
                                                >
                                                    <i className="material-icons">close</i>
                                                </IconButton>
                                            </>
                                        ) : (
                                            <Button
                                                className="addNotesBtnCnt"
                                                onClick={handleInputFieldToggle}
                                            >
                                                <span className="material-icons">description</span>
                                                <span>Add Notes</span>
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            {product.conditionunique == 'Scratch & Dent – New Condition' ||
                            product.conditionunique == 'Scratch & Dent – Good Condition' ||
                            product.conditionunique == 'Scratch & Dent – Fair Condition' ||
                            product.conditionunique == 'Scratch & Dent – Damaged' ? (
                                <p
                                    className="dentPro mb-3"
                                    onClick={() => handleConditionDialog(product.conditionunique)}
                                >
                                    *14 DAY Money Back Guarantee on all Scratch & Dent items. NO
                                    QUESTIONS ASKED!
                                </p>
                            ) : null}
                        </div>
                    </div>

                    <div className="listActBtn">
                        {product.conditionunique == 'Scratch & Dent – New Condition' ||
                        product.conditionunique == 'Scratch & Dent – Good Condition' ||
                        product.conditionunique == 'Scratch & Dent – Fair Condition' ||
                        product.conditionunique == 'Scratch & Dent – Damaged' ? (
                            <p>*Inspected by our Appliance team</p>
                        ) : null}
                        {product.market_status === 'open' ? (
                            <>
                                <div className="d-flex justify-content-center align-items-center flex-wrap">
                                    <p className="d-flex align-items-center timerListView flex-wrap">
                                        {/* <span className="material-icons-outlined">timer</span> */}
                                        {product.date_closed ? (
                                            <span>
                                                <Timer
                                                    date_added={product.date_added}
                                                    date_closed={product.date_closed}
                                                    withText={1}
                                                    endText={'Time left' + ':'}
                                                    startText={'Starts in' + ':'}
                                                ></Timer>
                                            </span>
                                        ) : null}
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="listTimer">
                                    <h6 className="w-100 d-flex justify-content-center align-items-center">
                                        Closed on: {dateFormatFront(product.date_closed)}
                                    </h6>
                                    {/* <p className="d-flex justify-content-center align-items-center w-100">
                                    {dateFormatFront(product.date_closed)}
                                </p> */}
                                </div>
                            </>
                        )}
                        {props.from === 'dashboard' &&
                        (props.action === 'won' || props.action === 'buynow') &&
                        product.buynowed &&
                        product.buynowed === user.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            id="listView_common_invoice"
                                            label="View invoice"
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            id="make_pay_list"
                                            label="Make payment"
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `checkout/invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        ) : product.market_status === 'open' && !product.future_active ? (
                            <>
                                <div className="listBidBox">
                                    {product.auction ? (
                                        <>
                                            {product.auctiontype == 'reverse' ? (
                                                <></>
                                            ) : // <ReverseBidding
                                            //     data={product}
                                            //     type={
                                            //         parseInt(
                                            //             configVariables?.proxy_bidding_settings,
                                            //         ) === 0
                                            //             ? 'hard'
                                            //             : 'proxy'
                                            //     }
                                            // />
                                            product.custom_auctionlot_bid_type ? (
                                                <></>
                                            ) : (
                                                // <Bidding
                                                //     data={product}
                                                //     type={`${product.custom_auctionlot_bid_type}`}
                                                // />
                                                <></>
                                                // <Bidding data={product} type={'hard'} />
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                        // <Buynow
                                        //     data={product}
                                        //     editQuantity={false}
                                        //     isOnlyBuynow={product.auction === 0 ? true : false}
                                        // />
                                    )}
                                </div>
                            </>
                        ) : product.buynowed && product.buynowed === user.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            id="listView_paid_invoice"
                                            label="View invoice"
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            id="make_pay_list"
                                            label="Make payment"
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `checkout/invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div className="moreInfo">
                            <Button id="listView_info" onClick={props.drawerHandler}>
                                View more info
                                <span className="material-icons">arrow_right_alt</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
            <CustomDialog
                function={handleCloseDialog}
                className="conditionModal"
                title={
                    <div className="d-flex align-items-center justify-content-between">
                        Conditions
                    </div>
                }
                open={ctgDialog}
            >
                {condition ? (
                    condition == 'Scratch & Dent – New Condition' ||
                    condition == 'Scratch & Dent – Good Condition' ||
                    condition == 'Scratch & Dent – Fair Condition' ||
                    condition == 'Scratch & Dent – Damaged' ? (
                        <div className="mb-4">
                            <p>
                                Scratch & Dent marked appliances have a 14-day Money Back Guarantee.
                                No Questions asked! All Scratch & Dent Appliances have been tested
                                and inspected by our Appliance team and are guaranteed to be in the
                                condition described. If you are not satisfied for any reason, you
                                may return the item for a full refund up to 14 days after pickup. If
                                you are not able to pick up your Scratch & Dent item within the
                                4-day pickup window, you will receive a Full Refund. The Full
                                Refund, including Hammer Price, Buyers Premium and sales tax, will
                                be issued to the credit card used at purchase. This guarantee is
                                only for appliances marked Scatch & Dent. The standard terms and
                                conditions apply to all other items. See complete terms and
                                conditions{' '}
                                <a className="cursorPointer" onClick={() => handleTerms()}>
                                    here.
                                </a>{' '}
                            </p>{' '}
                        </div>
                    ) : (
                        <div className="mb-4">
                            <p>
                                Item Condition Gradesare <span className="oB">OPENED BOX </span>,
                                <span className="dm">DAMAGED</span>, and{' '}
                                <span className="Nw">APPEARS NEW</span>. (See complete{' '}
                                <a onClick={() => handleRedirectInternal(history, `terms`)}>
                                    Terms and Conditions
                                </a>
                                ).
                            </p>
                            <ul>
                                <li>
                                    <span className="oB">OPENED BOX</span> items were sold by a
                                    retailer, and potentially used before being returned by the
                                    customer. Opened Box items
                                    <u>may be damaged</u>or have an opened box with missing pieces,
                                    missing cables, missing batteries etc.
                                </li>
                                <li>
                                    <span className="dm">DAMAGED</span> items have visible damage or
                                    could be incomplete, missing parts and have not been tested for
                                    functionality.
                                </li>
                                <li>
                                    <span className="Nw">APPEARS NEW</span> items appear to be in a
                                    new condition or have not been used previously. DB may offer a
                                    refund for all Appears New items with discovered defects.
                                    <u>
                                        Customer MUST report defects to DB prior to leaving the
                                        Pickup Center with the item.
                                    </u>
                                </li>
                            </ul>
                            <p>
                                DB will include stock photos when available, Buyer accepts that DB
                                taken photos and stock photos may be inaccurate. It is the
                                responsibility of the Buyer to inspect all items before placing any
                                bid on the Site.
                            </p>
                            <p>
                                Buyers may inspect
                                <span className="oB">OPENED BOX</span>,
                                <span className="dm">DAMAGED</span>
                                ,items only at the
                                <b>Inspection Table</b>.
                                <u>
                                    Buyers are not permitted to open or inspect items on the
                                    warehouse floor
                                </u>
                                .
                            </p>
                            <p>
                                <span className="Nw">APPEARS NEW</span> items are not available to
                                be inspected before the auction closes. Winners of
                                <span className="Nw">APPEARS NEW</span> items are asked to inspect
                                their items condition before they leave the Pickup Center.
                            </p>
                        </div>
                    )
                ) : null}
            </CustomDialog>
        </>
    )
}
export default ListView
