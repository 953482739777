export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_HOME = 'GET_ALL_HOME'
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_WATCHLIST_PROD = 'GET_WATCHLIST_PROD'
export const GET_WON = 'GET_WON'
export const GET_LOST = 'GET_LOST'
export const GET_OUTBID = 'GET_OUTBID'
export const GET_WINNING = 'GET_WINNING'
export const GET_DASHBOARD = 'GET_DASHBOARD'
export const GET_EXPIRED = 'GET_EXPIRED'
export const GET_SAVED_SEARCH = 'GET_SAVED_SEARCH'
export const GET_SIMILAR = 'GET_SIMILAR'
export const GET_PRODUCT_INFO = 'GET_PRODUCT_INFO'
export const GET_ALL_BID_HISTORY = 'GET_ALL_BID_HISTORY'
export const GET_INVOICE_DETAIL = 'GET_INVOICE_DETAIL'
export const WATCH_COUNT = 'WATCH_COUNT'
