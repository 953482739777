import React, { useContext } from 'react'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import AuthContext from '@/custom/context/auth/authContext'

import SingleProfile from './Profile'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import SEO from '@/utils/SEO'

const MyAccount = () => {
    //Profile page
    const authContext = useContext(AuthContext)

    const { user, loadUser } = authContext

    return (
        <>
            <SEO title="Profile" url={window.location.href} />
            <DashboardLayout title={'Profile'}>
                <div className="dashboardInner myAccount">
                    <div className="auctionStatus">
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/profile">
                                {'Profile'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/change">
                                {'Change password'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/notifications">
                                {'My notifications'}
                            </NavLink>
                        </ListItem>
                    </div>
                    <div className="accountCnt">
                        <SingleProfile />
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export default MyAccount
