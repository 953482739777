import React, { useContext, useEffect, useState } from 'react'
import { mapData, converDate } from '@/custom/common/components'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../organisms/Dialog/index'
import UserContext from '@/custom/context/user/userContext'
import StripeCardContext from '@/custom/context/stripe/card/cardContext'
import AuthContext from '@/custom/context/auth/authContext'
import ProductCommonContext from '@/custom/context/common/commonContext'
import AlertContext from '@/custom/context/alert/alertContext'
import Popup from '../../organisms/Popup'
import Loaders from '../../molecules/Loaders'

function CardDetails(props) {
    const authContext = useContext(AuthContext)
    const productCommonContext = useContext(ProductCommonContext)
    const alertContext = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const { setAlert } = alertContext
    const { user, loadUser } = authContext
    const {
        updateStripe,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = useContext(UserContext)
    const {
        getPreviousData,
        clearPreviousData,
        previous_data,
        allCountries,
        allStates,
    } = productCommonContext
    const {
        get_all_card_details,
        createStripeCard,
        createStripeCardWithCustomer,
        defaultStripePaymentMethodCard,
        deleteStripeCard,
        updateStripeCard,
        responseStatus,
        clearResponse,
    } = useContext(StripeCardContext)
    const [disabled, setDisabled] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [reload, setReload] = useState(false)
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const validationCard = Yup.object({
        card_number: Yup.string()
            .min(12, 'Invalid credit card number!')
            .max(18, 'Invalid credit card number!')
            .required('Required!'),
        card_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .required('Required!'),
        card_exp_month: Yup.string().required('Required!'),
        card_exp_year: Yup.string().required('Required!'),
        card_cvc: Yup.string()
            .required('Required!')
            .min(3, 'Mininum 3 Numbers required')
            .max(4, 'Maximum 4 Numbers required'),
        card_address_line1: Yup.string().required('Required!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required('Required!'),
        card_address_state: Yup.string().required('Required!'),
        card_address_country: Yup.string().required('Required!'),
        card_address_zip: Yup.string().required('Required!'),
        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    const cardFormik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_customer_id : '',
            card_number: '',
            card_name: '',
            card_exp_month: '',
            card_exp_year: '',
            card_cvc: '',
            cvv: '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_address_country: '',
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
        },
        validationSchema: validationCard,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (values.customer_id) {
                setDisabled(true)
                createStripeCard({
                    ...values,
                    card_address_country: values.card_address_country,
                })
            } else {
                const paymentInfo = {
                    account_id: values.account_id,
                    description: 'NPS',
                    email: user.email,
                    name: values.card_name,
                    address: {
                        line1: values.card_address_line1,
                        line2: values.card_address_line2,
                        city: values.card_address_city,
                        state: values.card_address_state,
                        postal_code: values.card_address_zip,
                        country: values.card_address_country,
                    },
                    card_name: values.card_name,
                    card_address_line1: values.card_address_line1,
                    card_address_city: values.card_address_city,
                    card_address_state: values.card_address_state,
                    card_address_zip: values.card_address_zip,
                    card_address_country: values.card_address_country,
                    card_number: values.card_number,
                    card_exp_month: values.card_exp_month,
                    card_exp_year: values.card_exp_year,
                    card_cvc: values.card_cvc,
                    card_token: true,
                }
                console.log(paymentInfo, 'paymenttt')

                createStripeCardWithCustomer(paymentInfo)
            }
            setLoading(true)
        },
    })

    const validationupdateCard = Yup.object({
        exp_month: Yup.string().required('Required!'),
        exp_year: Yup.string().required('Required!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_customer_id : '',
            source_id: '',
            exp_month: '',
            exp_year: '',
        },
        validationSchema: validationupdateCard,
        onSubmit: (values) => {
            updateStripeCard(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (dataValue.status === 'delete') {
                deleteStripeCard({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_customer_id,
                    source_id: props.data.data.id,
                })
                setChangeStatus(false)
                props.function(false, 'new', null)
            } else if (dataValue.status == 'default') {
                updateStripe(
                    {
                        stripe_customer_id: user.stripe_customer_id,
                        stripe_card_id: props.data.data.id,
                    },
                    false,
                    'defaultCard',
                )
                let userDet = JSON.parse(localStorage.getItem('userData'))
                userDet = {
                    ...userDet,
                    stripe_card_id: props.data.data.id,
                }
                localStorage.setItem('userData', JSON.stringify(userDet))
                loadUser(userDet)
                // defaultStripePaymentMethodCard({
                //     account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                //     customer_id: user.stripe_customer_id,
                //     source_id: props.data.data.id,
                // })
            }
        },
    })

    // useEffect(() => {
    //     console.log('here')
    //     if (previous_data) {
    //         if (previous_data.records.length) {
    //             // setAlert(
    //             //     'Removal of Card/ACH is permitted only after 6 weeks from the last bid',
    //             //     'error',
    //             // )
    //             // formikSelection.values.data = ''
    //             // formikSelection.values.status = ''
    //             // clearPreviousData()
    //             // props.function(false, 'new', null)
    //         } else if (formikSelection.values.status) {
    //             setChangeStatus(true)
    //         }
    //     }
    // }, [previous_data])
    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                if (get_all_card_details.records.length > 1) {
                    setChangeStatus(true)
                } else {
                    setAlert('Default card is required', 'error')
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                }
            } else if (props.data.status === 'update') {
                updateCardformik.values.source_id = props.data.data.id
                updateCardformik.values.customer_id = props.data.data.customer
                updateCardformik.values.exp_month = ('0' + props.data.data.exp_month).slice(-2)
                updateCardformik.values.exp_year = props.data.data.exp_year
                setReload(!reload)
            } else if (props.data.status === 'default') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            }
        }
    }, [props.data])
    useEffect(() => {
        if (user) {
            if (user.stripe_customer_id) {
                cardFormik.setFieldValue('customer_id', user.stripe_customer_id)
            }
        }
    }, [user])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser && responseStatusUser.from == 'defaultCard') {
                console.log(responseStatusUser, 'responseStatusUser')
                setAlert(
                    responseStatusUser.data.statusText,
                    responseStatusUser.data.status ? 'success' : 'error',
                )
                setChangeStatus(false)
                props.function(false, 'new', null)
                clearResponseUser()
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'card_source_create') {
                if (responseStatus.status === 'success') {
                    console.log(responseStatus.data.data.responseData.id, 'response add card')
                    cardFormik.resetForm()
                    if (props.allCards.length == 0) {
                        updateStripe({
                            stripe_customer_id: user.stripe_customer_id,
                            stripe_card_id: responseStatus.data.data.responseData.id,
                        })
                        let userDet = JSON.parse(localStorage.getItem('userData'))
                        userDet = {
                            ...userDet,
                            stripe_card_id: responseStatus.data.data.responseData.id,
                        }
                        localStorage.setItem('userData', JSON.stringify(userDet))
                        loadUser(userDet)
                    }
                    clearResponse()
                    setAlert(responseStatus.data.data.message, responseStatus.status)
                    props.function(false, 'new', null)
                } else {
                    console.log(responseStatus, 'responseStatus')
                    setAlert(responseStatus.data.data.message, responseStatus.status)
                    clearResponse()
                }
                setDisabled(false)
            } else if (responseStatus.from === 'card_source_create_customer_card') {
                if (responseStatus.status === 'success') {
                    console.log(responseStatus.data.data.responseData.id, 'response add card')
                    cardFormik.resetForm()
                    if (props.allCards.length == 0) {
                        updateStripe({
                            stripe_customer_id: responseStatus.data.data.responseData.id,
                            stripe_card_id:
                                responseStatus.data.data.responseData.default_source || '',
                        })
                        let userDet = JSON.parse(localStorage.getItem('userData'))
                        userDet = {
                            ...userDet,
                            stripe_card_id:
                                responseStatus.data.data.responseData.default_source || '',
                        }
                        localStorage.setItem('userData', JSON.stringify(userDet))
                        loadUser(userDet)
                    }
                    clearResponse()
                    setAlert(responseStatus.data.data.message, responseStatus.status)
                    props.function(false, 'new', null)
                } else {
                    console.log(responseStatus, 'responseStatus')
                    setAlert(responseStatus.data.data.message, responseStatus.status)
                    clearResponse()
                }
                setDisabled(false)
            }
            if (
                responseStatus.from === 'card_source_default' ||
                responseStatus.from === 'card_source_delete'
            ) {
                setAlert(responseStatus.data.data.message, responseStatus.status)
                clearResponse()
            }
            setLoading(false)
        }
    }, [responseStatus])

    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'exp_year',
                options: creditCardYearOptions,
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: 'Card Holder Name',
                type: 'text',
                placeholder: 'Enter your card holder name',
                class: 'col-md-6',
                name: 'card_name',
            },
            {
                label: 'Card No',
                type: 'number',
                maxLength: 16,
                placeholder: 'Enter your card no',
                class: 'col-md-6',
                name: 'card_number',
            },
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-md-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-md-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
            },
            {
                label: 'CVV',
                type: 'number',
                placeholder: 'Enter your Cvv',
                class: 'col-12',
                inputProps: true,
                maxLength: 4,
                name: 'card_cvc',
            },
            {
                label: 'Card address line 1',
                type: 'text',
                placeholder: 'Enter card address line1',
                class: 'col-md-12',
                name: 'card_address_line1',
            },
            {
                label: 'Card address line 2',
                type: 'text',
                placeholder: 'Enter card address line2',
                class: 'col-12',
                name: 'card_address_line2',
            },
            {
                label: 'Card address city',
                type: 'text',
                placeholder: 'Enter card address city',
                class: 'col-md-6',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: 'Card address zip',
                type: 'text',
                placeholder: 'Enter card address zip',
                class: 'col-md-6',
                name: 'card_address_zip',
            },
            {
                label: 'Card address country',
                placeholder: 'Select card address country',
                class: 'col-md-6',
                type: 'select',
                options: [{ show: 'United States', value: 'US' }],
                name: 'card_address_country',
            },
            {
                label: 'Card address state',
                placeholder: 'Select card address state',
                class: 'col-md-6',
                type: 'select',
                options: allStates.map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.state
                    busCategoryChanged.value = buscat.state
                    return busCategoryChanged
                }),
                name: 'card_address_state',
            },
        ],
    }
    useEffect(() => {
        if (props.data.status === 'new' && props?.userDetails) {
            cardFormik.setFieldValue('card_address_city', props.userDetails.city)
            cardFormik.setFieldValue('card_address_zip', props.userDetails.zip)
            cardFormik.setFieldValue('card_address_country', 'US')
            cardFormik.setFieldValue('card_address_line1', props.userDetails.address1)
            cardFormik.setFieldValue('card_address_line2', props.userDetails.address2)
            cardFormik.setFieldValue('card_address_state', props.userDetails.state)
        }
    }, [props?.userDetails, props.data])

    return (
        <>
            <Loaders name="home" isLoading={loading} />
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Card Details"
            >
                <div className="cardDetails">
                    <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                        <div className="row">{mapData(cardDetails)}</div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    id="cardDetails_submit"
                                    disabled={disabled}
                                    btnSize="large"
                                    type="submit"
                                    label={'Save'}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>

            <CustomDialog title={'Confirmation'} open={changeStatus} function={changeChangeStatus}>
                <h5 style={{ fontWeight: '400' }}>
                    {formikSelection.values.status === 'delete'
                        ? 'Are you sure you want to delete ?'
                        : 'Are you sure you want to set this card as primary card ?'}
                </h5>
                <div className="actionWrapper">
                    <Button id="cardDetails_cancel" onClick={() => setChangeStatus(false)}>
                        Cancel
                    </Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton id="cardDetails_confirm" type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle="Update Card Details"
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="col-12">
                            <PrimaryButton
                                id="cardDetails_save"
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default CardDetails
