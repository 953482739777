import { setAuthToken } from '../common/api'

export class response {
    constructor(dispatch, RESPONSE_STATUS) {
        this.dispatch = dispatch
        this.RESPONSE_STATUS = RESPONSE_STATUS
    }

    async commonErrorResponse(from, noAlert, data = {}) {
        this.dispatch({
            type: this.RESPONSE_STATUS,
            payload: {
                status: 'error',
                message: data?.data?.message || 'Something went wrong!',
                type: 0,
                from: from,
                noAlert,
            },
        })
    }

    async commonResponse(data, from, noAlert) {
        console.log(data, 'fghghgh')
        if (data?.status) {
            this.dispatch({
                type: this.RESPONSE_STATUS,
                payload: {
                    status: data.status ? data.status : false,
                    message: data.statusText,
                    // type: data.data.responseType,
                    data: data,
                    from: from,
                    noAlert,
                },
            })
        } else if (!data?.status) {
            // if (data.data.responseType === 403) {
            //     this.dispatch({
            //         type: 'LOGOUT',
            //     })
            //     setAuthToken(false)
            //     window.location.href = '/login'
            // }
            this.dispatch({
                type: this.RESPONSE_STATUS,
                payload: {
                    status: data.status ? data.status : false,
                    message: data.statusText,
                    // type: data.data.responseType,
                    from: from,
                    // to: data.data.to,
                    noAlert,
                },
            })
        } else {
            this.commonErrorResponse(from)
        }
    }
}
