import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../../custom/components/atoms/PrimaryButton'
import '../Dashboard.css'
import { mapData } from '../../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../custom/context/auth/authContext'
import UserContext from '../../../custom/context/user/userContext'
import CommonContext from '../../../custom/context/common/commonContext'
import AlertContext from '@/custom/context/alert/alertContext'
import { Button, IconButton, ListItem } from '@material-ui/core'
import CustomDialog from '@/custom/components/organisms/Dialog'

const MyAccount = () => {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const commonContext = useContext(CommonContext)
    const userDetails = JSON.parse(localStorage?.userData || {})
    const { allNotifications, configFeatures, allStates } = commonContext
    const { loadUser, isAuthenticated } = authContext
    const {
        updateProfile,
        updatePreference,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
        user_details,
        updateUsername,
        updateMobilenumber,
        getUserProfileDetails,
    } = userContext
    const alertContext = useContext(AlertContext)
    const [toggleDialog, setToggleDialog] = useState(false)
    const { setAlert } = alertContext
    const [reload, setReload] = useState(false)
    const [editable, setEditable] = useState(false)
    const [user, setUser] = useState({})
    const [userOtherInfo, setUserOtherInfo] = useState({})

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }
    const [phoneVerify, setPhoneVerify] = React.useState(false)
    const [bulkPopup, setBulkPoup] = useState(false)

    const [currentCountry, setCurrentCountry] = useState()
    const [address, setAddress] = useState([])
    const [cards, setCards] = useState([])

    console.log(`user_details  `, user_details, localStorage.userData)

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const changePhoneVerify = () => {
        formik.values.verify_code = ''
        setPhoneVerify(!phoneVerify)
    }
    const toggleFullScreenPopup = (popup, status, data) => {
        setManage({ popup, status, data })
    }
    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim('Remove trailing and leading whitespaces')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .typeError('Required!')
            .required('First Name is Required!'),
        last_name: Yup.string()
            .trim('Remove trailing and leading whitespaces')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .typeError('Required!')
            .required('Last Name is Required!'),
        phone: Yup.string()
            .trim()
            .nullable()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(12, 'Please enter correct mobile number')
            .required('Required!'),
        username: Yup.string()
            .test('noWhiteSpace', 'White space not allowed', (value) => {
                return !/\s/.test(value)
            })
            .min(2, 'Mininum 2 characters')
            .max(50, 'Maximum 50 characters'),
        email: Yup.string().email(),
        strt_address: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'some_kind_of_special_characters_not_allowed')
            .max(250, 'maximum_250_characters')
            .required('Required'),
        strt_address2: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'some_kind_of_special_characters_not_allowed')
            .max(250, 'maximum_250_characters'),
        country: Yup.string().trim().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'the_positive_number_only')
            .max(5, 'Maximum 5 Characters')
            .required('Required'),
    })

    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        current_password: Yup.string().required('Required'),
        new_password: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords Not Match')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            strt_address: '',
            strt_address2: '',
            phone: '+',
            email: '',
            tax_exempt_id: '',
            verify_code: '',
            phone_verified: 0,
            city: '',
            username: '',
            country: '',
            state: '',
            zip: '',
            quick_enable: 0,
            verified_phonenum: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            const castValues = validationArray.cast(values)
            // updateProfile(castValues)
            // if (user.phone === castValues.phone) {
            //     updateProfile(castValues)
            // } else if (
            //     castValues.phone_verified === 1 &&
            //     formik.values.verified_phonenum === formik.values.phone
            // ) {
            //     updateProfile(castValues)
            // } else {
            //     setPhoneVerify(true)
            // }

            if (user.username !== castValues.username) {
                updateUsername({ username: castValues.username })
            } else if (user.phone !== castValues.phone) {
                updateMobilenumber({ phone: castValues.phone })
            } else {
                updateProfile(castValues)
            }
        },
    })

    useEffect(() => {
        if (user_details && user_details?.records) {
            console.log(user_details, 'user_details')
            setUser(user_details.records.userData)
            let userDet = JSON.parse(localStorage.getItem('userData'))
            userDet = {
                ...userDet,
                first_name: user_details.records.userData.first_name,
            }
            localStorage.setItem('userData', JSON.stringify(userDet))
            loadUser(userDet)
            setUserOtherInfo(user_details.records?.userOtherInfo)
            if (user_details.records?.userOtherInfo) {
                formikUpload.setFieldValue(
                    `taxexcempt_file`,
                    user_details.records?.userOtherInfo.tax_exempt_file_link &&
                        user_details.records?.userOtherInfo.tax_exempt_file_link !== ''
                        ? [user_details.records?.userOtherInfo.tax_exempt_file_link]
                        : [],
                )
            }
        }
    }, [user_details])

    useEffect(() => {
        setEditable(false)
        getUserProfileDetails({})
    }, [])

    useEffect(() => {
        if (responseStatusUser) {
            console.log(responseStatusUser, 'responseStatusUser')

            if (responseStatusUser.from === 'updateProfile') {
                setAlert(
                    responseStatusUser.data.statusText,
                    responseStatusUser.data.status ? 'success' : 'error',
                )
                if (responseStatusUser.data.status) {
                    setEditable(false)
                    getUserProfileDetails()
                    loadUser()
                }
                setAlert(
                    responseStatusUser.data.statusText,
                    responseStatusUser.data.status ? 'success' : 'error',
                )
            }
            if (responseStatusUser.from === 'updateMobilenumber') {
                setAlert(
                    responseStatusUser.data.statusText,
                    responseStatusUser.data.status ? 'success' : 'error',
                )
                if (responseStatusUser.data.status) {
                    updateProfile(formik.values)
                }
            }
            if (responseStatusUser.from === 'updateUsername') {
                if (!responseStatusUser.data.status) {
                    setAlert(
                        responseStatusUser.data.status_text,
                        responseStatusUser.data.status ? 'success' : 'error',
                    )
                }
                if (responseStatusUser.data.status) {
                    if (user.phone !== formik.values.phone) {
                        updateMobilenumber({ phone: formik.values.phone })
                    } else {
                        updateProfile(formik.values)
                    }
                }
            }
            clearResponseUser()
        }
    }, [responseStatusUser])
    function handleSubmit() {
        createStripeCustomer(
            {
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                description: 'TEST CUSTOMER',
                email: formik.values.email,
                name: 'name',
            },
            true,
        )
        /*formik.values.first_name + ' ' + formik.values.last_name,*/
    }

    useEffect(() => {
        if (formik.values.phone_verified === 1) {
            formik.submitForm()
        }
    }, [formik.values.phone_verified])

    useEffect(() => {
        if (localStorage?.qrPopup && userDetails?.user_id) {
            setTimeout(() => {
                setToggleDialog(true)
            }, 500)
        }
    }, [localStorage?.qrPopup, userDetails?.user_id])

    useEffect(() => {
        if (user) {
            console.log(user, 'editvalues')
            formik.values.first_name = user.first_name
            formik.values.last_name = user.last_name
            formik.values.username = user.username
            formik.values.strt_address = user.address1
            formik.values.strt_address2 = user.address2
            formik.values.phone = user.phone
            formik.values.phone_verified = 0
            formik.values.verified_phonenum = formik.values.verified_phonenum
            formik.values.email = user.email
            formik.values.city = user.city
            formik.values.country = user.country
            formik.values.state = user.state
            formik.values.quick_enable = user.quick_enable
            formik.values.zip = user.zip
            setReload(!reload)
        }
    }, [user])
    // useEffect(() => {
    //     setBulkPoup(false)
    // }, [formik.values.avatar])

    const formikChange = useFormik({
        initialValues: {
            change_password: 1,
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationPassword,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })
    const setUserAddress = () => {
        setAddress(user.address_details.filter((inner) => inner.status === 'active'))
        toggleFullScreenPopup(false, 'new', null)
    }
    useEffect(() => {
        if (user) {
            if (user.address_details) {
                console.log(user.address_details, 'user_address_details')
                setUserAddress()
            }
        }
    }, [user && user.address_details])
    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
        },
        onSubmit: (values) => {
            updatePreference(values)
        },
    })
    const emailPreference = [
        {
            name: 'email_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.email === 1),
            formik: formikPreference,
        },
    ]
    useEffect(() => {
        console.log(formik.values, 'fmi')
    }, [formik.values])
    const smsPreference = [
        {
            name: 'sms_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.sms === 1),
            formik: formikPreference,
        },
    ]

    const changePassword = [
        {
            label: 'Current Password',
            name: 'current_password',
            type: 'password',
            placeholder: 'Enter Your Current Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: 'Password',
            name: 'new_password',
            type: 'password',
            placeholder: 'Enter Your Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: 'Confirm Password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re-Enter Your Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
    ]
    const handleFileUpload = (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: reader.result,
            })
        }
        reader.readAsDataURL(file)
    }
    // const uploadImage = [
    //     {
    //         type: 'uploadDropZone',
    //         formik: formik,
    //         name: 'avatar',
    //         titleText: 'Upload profile image',
    //         innerText: 'Recommended dimensions: 380px * 380px',
    //         class: 'col-12',
    //         folder: 'profile',
    //         multiple: false,
    //         accept: 'image/*',
    //     },
    // ]
    const profile = [
        {
            label: 'First Name',
            type: 'text',
            placeholder: 'Enter Your First Name',
            class: 'col-md-6 col-sm-12',
            name: 'first_name',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Last Name',
            placeholder: 'Enter Your Last Name',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'last_name',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Username',
            placeholder: 'Enter Your User Name',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'username',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Email Address',
            placeholder: 'Enter Your Email Address',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            disabled: true,
            name: 'email',
            formik: formik,
        },
        {
            label: 'Phone Number',
            placeholder: 'Phone Number',
            class: 'col-md-6 col-sm-12',
            type: 'phone',
            name: 'phone',
            formik: formik,
            countryCodeEditable: false,
            disabled: !editable,
            // disabled: true,
        },

        {
            label: 'Address Line 1',
            placeholder: 'Enter address line 1',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'strt_address',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Address Line 2',
            placeholder: 'Enter address line 2',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'strt_address2',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Country',
            placeholder: 'Select Country',
            class: 'col-md-6 col-sm-12',
            type: 'select',
            options: [{ show: 'United States', value: 'United States' }],
            name: 'country',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'State',
            placeholder: 'Select state',
            class: 'col-md-6 col-sm-12',
            type: 'select',
            options: allStates.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.state
                busCategoryChanged.value = buscat.state
                return busCategoryChanged
            }),
            name: 'state',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'City',
            placeholder: 'Enter your city',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'city',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Zipcode',
            placeholder: 'Enter your zip code',
            class: 'col-md-3 col-sm-12',
            type: 'number',
            name: 'zip',
            formik: formik,
            disabled: !editable,
        },
        {
            label: 'Enable Quick Bid Button',
            placeholder: 'Enable Quick Bid Button',
            type: 'checkbox',
            class: 'col-12',
            name: 'quick_enable',
            disabled: !editable,
        },
    ]

    const formikUpload = useFormik({
        initialValues: {
            taxexcempt_file: [],
        },
    })

    const taxData = [
        {
            type: 'uploadDropZone',
            formik: formikUpload,
            name: 'taxexcempt_file',
            titleText: 'Upload Tax Exempt',
            innerText: 'Recommended dimensions: 380px * 380px',
            class: 'col-12',
            folder: 'profile',
            multiple: false,
            accept: 'application/pdf',
        },
    ]

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                <div className="accChild">
                    {/* <div className="prflImgWrpr">
                        <img
                            className="prflImg"
                            src={
                                formik.values.avatar.length
                                    ? `${global.site_url}/uploads/profile/${formik.values.avatar}`
                                    : `${global.site_url}/uploads/profile/file_upload-1625693520372_947677.jpeg`
                            }
                            alt=""
                        />
                        {console.log('profile-image------>', formik.values.avatar)}
                        <Button onClick={() => setBulkPoup(true)} className="edtPrflBtn">
                            <EditIcon />
                        </Button>
                    </div> */}
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                        <h4 className="m-0  d-flex align-items-center">
                            {'Profile Details'}{' '}
                            {!editable ? (
                                <span
                                    onClick={() => setEditable(true)}
                                    className="material-icons-outlined ml-3 profileEditICon"
                                >
                                    edit
                                </span>
                            ) : null}
                        </h4>
                        <div className="">
                            <img
                                src={`${process.env.REACT_APP_DOMAIN}/uploads/user_${userDetails?.user_id}.png`}
                                height="120"
                                width="120"
                            />
                        </div>
                    </div>
                    <div className="row">{Object.values(mapData(profile))}</div>
                </div>
                {editable ? (
                    <PrimaryButton type="submit" id="profile_savebutton" label={'Save'} />
                ) : null}

                <div className="savedAddressCnt mt-5">
                    <h2 className="dashTitle mb-3">Tax Exempt Certificate</h2>
                    <div className="row">{Object.values(mapData(taxData))}</div>
                    <div className="savedAddress"></div>
                </div>
                <div className="row dsig">
                    <div className="col-md-12 text-left ionoti2 not-sm-head">
                        If you are purchasing items for resale and live in the state we sell from,
                        please upload the Tax Exemption Certificate here.
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left ">
                            <b>Status:</b>
                            {userOtherInfo?.tax_excempt == 1
                                ? 'Exempt'
                                : userOtherInfo?.tax_excempt == 0 &&
                                  userOtherInfo?.taxfile_check == 1 &&
                                  userOtherInfo?.taxfile_org != ''
                                ? 'Pending Approval'
                                : 'Non-Exempt'}
                        </div>
                        <div className="form-group">
                            <div className="greyBox text-left ">
                                The State of Georgia accepts 3 different examption certificates.
                                <b>
                                    DealBadger is unable to provide Exempt status without one of
                                    these documents.
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 form-group text-left ">
                                <u>Valid Exemption Documents Include:</u>
                                <ul>
                                    <li>{`1) Georgia ST-5`}</li>
                                    <li>{`2) MTC Uniform Resale Certificate`}</li>
                                    <li>{`3) Streamline Sales Tax Certificate`}</li>
                                </ul>
                            </div>
                            <div className="col-md-6 form-group text-left ">
                                <div
                                    className="align-items-center d-flex flex-wrap flxPdf gap-3 justify-content-start"
                                    style={{ gap: '15px' }}
                                >
                                    <a
                                        href="/assets/tax_file_1.pdf"
                                        target="_blank"
                                        className="aTag"
                                    >
                                        <img src="/assets/images/pdfico.webp" width="35" />
                                        <b>Georgia ST-5</b>
                                    </a>
                                    <a
                                        href="/assets/tax_file_2.pdf"
                                        target="_blank"
                                        className="aTag"
                                    >
                                        <img src="/assets/images/pdfico.webp" width="35" />
                                        <b>MTC Uniform Resale Certificate</b>
                                    </a>
                                    <a
                                        href="/assets/tax_file_3.pdf"
                                        target="_blank"
                                        className="aTag"
                                    >
                                        <img src="/assets/images/pdfico.webp" width="35" />
                                        <b>Streamline Sales Tax Certificate</b>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <p>
                            All 3 are attached for reference and all 3{' '}
                            <b>
                                <i>
                                    need to include the customer`s valid sales tax registration
                                    number{' '}
                                </i>
                            </b>
                            from the state of Georgia.
                        </p>
                    </div>
                </div>
                <CustomDialog
                    title={`Checkout QR Code`}
                    open={toggleDialog}
                    function={() => {
                        localStorage.removeItem('qrPopup', true)
                        setToggleDialog(false)
                    }}
                    handleClose={() => {
                        localStorage.removeItem('qrPopup', true)
                        setToggleDialog(false)
                    }}
                    disableBackdropClick
                    className="qrPopUpDialog"
                >
                    <img
                        src={`${process.env.REACT_APP_DOMAIN}/uploads/user_${userDetails?.user_id}.png`}
                        height="300"
                        width="300"
                    />
                    <div className="qrUserName">
                        <h5>{user?.username}</h5>
                        <h5 style={{ fontWeight: '400' }}>Username </h5>
                    </div>
                    <div className="actionWrapper">
                        {/* <Button
                            onClick={() => {
                                localStorage.removeItem('qrPopup', true)
                                setToggleDialog(false)
                            }}
                        >
                            {'Close'}
                        </Button> */}
                    </div>
                </CustomDialog>
            </form>
        </>
    )
}

export default MyAccount
