import React, { useState, useContext, useEffect } from 'react'
import CustomCommonContext from '../../../context/common/commonContext'
import AuthContext from '@/custom/context/auth/authContext'

const HeadAlert = () => {
    const { loaderSet, getStates, getCategories, getLocations } = useContext(CustomCommonContext)
    const { loadUser } = useContext(AuthContext)

    useEffect(() => {
        loaderSet(false)
        getStates()
        getCategories()
        getLocations()
        // loaderSet(true)
        if (localStorage.token) {
            loadUser()
        } else {
            loaderSet(false)
        }

        // getGlobalVariable()
        // getAllCountries()
        // getAllStates()
    }, [])

    // useEffect(() => {
    //     if (callEnd) loaderSet(false)
    // }, [callEnd])

    // useEffect(() => {
    //     if (allUISettings) {
    //         allUISettings.map((data, index) => {
    //             if (data.ui_key == 'primColor') {
    //                 document.documentElement.style.setProperty('--primColor', data.ui_value)
    //             } else if (data.ui_key == 'secColor') {
    //                 document.documentElement.style.setProperty('--secColor', data.ui_value)
    //             }
    //         })
    //     }
    // }, [allUISettings])

    return <></>
}
export default HeadAlert
