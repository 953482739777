import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '@/custom/components/organisms/ProductView'
import GridView from '@/custom/components/molecules/ProductCard/GridView'
import ListView from '@/custom/components/molecules/ProductCard/ListView'
import { messageHandler } from '@/views/Common/socketHandler'
import BuyerContext from '@/custom/context/buyer/buyerContext'
import ProductContext from '@/custom/context/product/productContext'
import AuthContext from '@/custom/context/auth/authContext'
import AlertContext from '@/custom/context/alert/alertContext'
import Loaders from '@/custom/components/molecules/Loaders'
// import openSocket from 'socket.io-client'
import { socket } from '@/custom/common/socket'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import UserContext from '@/custom/context/user/userContext'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import Dialog from '@/custom/components/organisms/Dialog'
import '../Dashboard.css'

// import SEO from '@/utils/SEO'
let randomVariable = Math.random()
const Winning = (props) => {
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])
    const formikSearch = props.formikSearch
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const [showProduct, setShowProduct] = useState(false)
    const [locationData, setLocationData] = useState(false)
    const [locationPopup, setLocationPopup] = useState(false)
    const [updated, setUpdated] = useState(false)
    const {
        checkLogedUserAlreadySeenPickupLocation,
        createUpdateUserSeenPickupLocation,
        responseStatus: responseStatusLocation,
        clearResponse: clearResponseLocation,
    } = useContext(UserContext)
    let history = useHistory()
    let action = ''

    // const classes = useStyles()
    const { user, isAuthenticated, responseStatus, clearResponse, saveSearch } = authContext
    const { responseStatus: responseStatusBid, clearResponse: clearResponseBid } = useContext(
        BuyerContext,
    )
    const {
        getWinningData,
        winning_data,
        responseStatus: responseStatusProduct,
        product_info,
        getWatchlistCount,
        getProductInfo,
    } = productContext

    const { setAlert } = alertContext
    const [isLoading, setIsLoading] = useState(true)
    const [click, setClick] = useState({})
    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const toggleDrawer = (anchor, open, data) => (event) => {
        console.log(data, 'dataproduct')
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setShowProduct(false)
            if (isAuthenticated) {
                checkLogedUserAlreadySeenPickupLocation({ product_id: `${data.id}` })
                getProductInfo({ product_id: `${data.id}` })
                setClick({
                    anchor: anchor,
                    open: open,
                    data: `${data.id}`,
                })
            } else {
                getProductInfo({ product_id: `${data.id}` })
                setShowProduct(true)
                setClick({
                    anchor: anchor,
                    open: open,
                    data: `${data.id}`,
                })
            }
        } else {
            setShowProduct(false)
            setClick({})
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const viewSingleProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewSingleProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewSingleProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    const bidAddtime_handler = (data) => {
        console.log(data, 'bidAddtime_handler')
        handler(data, 'bidAddtime')
    }

    const realclosedupdates_handler = (data) => {
        console.log(data, 'realclosedupdates_handler')
        handler(data, 'realclosedupdates')
    }

    useEffect(() => {
        if (state.right) {
            console.log(state, 'state data')
            getProductInfo({ product_id: `${state.data?.prodet?.id}` })
            setShowProduct(true)
            setClick({
                ...click,
                data: `${state.data?.prodet?.id}`,
            })
        }
    }, [viewProduct])

    useEffect(() => {
        socket.on('bidAddtime', bidAddtime_handler)
        socket.on('realclosedupdates', realclosedupdates_handler)
        return () => {
            socket.off('bidAddtime', bidAddtime_handler)
            socket.off('realclosedupdates', realclosedupdates_handler)
        }
    }, [])

    useEffect(() => {
        console.log(responseStatusBid, 'responseStatusBid')
        if (responseStatusBid && responseStatusBid.from == 'bidConfirm') {
            console.log(responseStatusBid.data, 'responseStatusBid456')
            handler(responseStatusBid.data, 'bidAddtime')
        }
        clearResponseBid()
    }, [responseStatusBid])

    useEffect(() => {
        if (responseStatusLocation) {
            if (
                responseStatusLocation.from == 'checkLogedPickupLoc' &&
                responseStatusLocation?.data
            ) {
                console.log(responseStatusLocation.data, 'locate')
                if (responseStatusLocation.data?.statusCode == 1) {
                    setLocationData(responseStatusLocation.data)
                    setShowProduct(true)
                } else {
                    setShowProduct(false)
                    setLocationData(responseStatusLocation.data)
                    setLocationPopup(true)
                }
            }
            if (responseStatusLocation.from == 'createUpdateUserSeenPickupLocation') {
                console.log(responseStatusLocation.data, 'locate')
                setLocationPopup(false)
                setShowProduct(true)
            }
            clearResponseLocation()
        }
    }, [responseStatusLocation])

    const handleLocationPopup = () => {
        createUpdateUserSeenPickupLocation({
            flag: 0,
            product_id: click.data,
        })
        setLocationPopup(!locationPopup)
    }

    useEffect(() => {
        if (product_info?.records && click.open && showProduct) {
            setState({ ...state, [click.anchor]: open, data: product_info?.records })
        }
    }, [product_info, click, showProduct])

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         getAllDashboardProducts(formikSearch.values)
    //     }
    // }, [formikSearch.values, isAuthenticated])

    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        if (formikSearch.values) {
            setIsLoading(true)
            randomVariable = Math.random()
            getWinningData(formikSearch.values, randomVariable)
        }
    }, [formikSearch.values])

    useEffect(() => {
        console.log(winning_data, 'won_data')
        if (winning_data && winning_data.records && winning_data.from == randomVariable) {
            setViewProduct(winning_data.records.winningList)
            setTimeout(() => {
                setUpdated(true)
            }, 500)
        }
    }, [winning_data])

    useEffect(() => {
        if (updated) {
            setIsLoading(false)
            setUpdated(false)
        }
    }, [updated])

    // useEffect(() => {
    //     //console.log('search_alldashboardproducts', search_alldashboardproducts)
    //     setViewProduct(
    //         search_alldashboardproducts.records.length ? search_alldashboardproducts.records : [],
    //     )
    // }, [search_alldashboardproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const updateFunction = (pid) => {
        getProductInfo({
            product_id: click.data,
        })
    }
    useEffect(() => {
        if (responseStatusProduct) {
            if (
                responseStatusProduct.from === 'addWatchlist' ||
                responseStatusProduct.from === 'removeWatchlist'
            ) {
                setAlert(responseStatusProduct.message, responseStatusProduct.status)
                // randomVariable = Math.random()
                // getWinningData(formikSearch.values, randomVariable)
                getWatchlistCount({})
            }
            clearResponse()
        }
    }, [responseStatusProduct])

    return (
        <>
            {/* <SEO title="My Bids" url={window.location.href} /> */}
            {isLoading ? (
                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
            ) : viewProduct.length ? (
                <>
                    <div className={`searchResults ${formikSearch.values.auctionView}`}>
                        <Drawer
                            className="rightDrawer"
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                        >
                            <ProductView
                                data={state.data}
                                drawerHandler={toggleDrawer('right', false)}
                                isDrawer={true}
                                project_id={click.data}
                                updateFunction={updateFunction}
                            />
                        </Drawer>
                        {viewProduct.map((data, index) => (
                            <>
                                {formikSearch.values.auctionView === 'Grid' ||
                                (props.auctionView && props.auctionView === 'Grid') ? (
                                    <>
                                        <GridView
                                            data={data}
                                            from="dashboard"
                                            action={props.type}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </>
                                ) : (
                                    <ListView
                                        data={data}
                                        from="dashboard"
                                        action={props.type}
                                        favId={`searchProd_${index}`}
                                        drawerHandler={toggleDrawer('right', true, data)}
                                    />
                                )}
                            </>
                        ))}
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                        {/* <h6>
                            {'Showing'} {search_alldashboardproducts.setDisp} {'of '}
                            {wa}
                        </h6> */}
                        <Pagination
                            count={Math.ceil(
                                winning_data?.records?.winningCount / formikSearch.values.limit,
                            )}
                            page={formikSearch.values.page}
                            onChange={onHandlePage}
                            siblingCount={3}
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                        />
                    </div>
                </>
            ) : (
                <NoRecordsFound />
            )}
            <Dialog
                title="Pickup Location"
                open={locationPopup}
                className="scanDialog"
                function={handleLocationPopup}
                disableBackdropClick={true}
                handleClose={handleLocationPopup}
            >
                <div>
                    <p className="text-left">
                        <b>Pickup Location For this Auction :</b> {locationData.pickup_address}
                    </p>
                    <p className="text-left">
                        <b>Last Pickup Day :</b> {locationData.pickup_day_4}
                    </p>
                    <p className="text-left">
                        <b>Last Pickup Time :</b> {locationData.begin_time_4} -{' '}
                        {locationData.end_time_4}
                    </p>
                    <div className="d-flex align-items-center justify-content-center">
                        <PrimaryButton
                            className="mr-3"
                            onClick={() =>
                                createUpdateUserSeenPickupLocation({
                                    flag: 1,
                                    product_id: click.data,
                                })
                            }
                        >
                            Don`t Show Again
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() =>
                                createUpdateUserSeenPickupLocation({
                                    flag: 0,
                                    product_id: click.data,
                                })
                            }
                        >
                            Close
                        </PrimaryButton>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Winning
