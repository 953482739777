import React, { useContext } from 'react'
import CheckBox from '@/custom/components/atoms/CheckBox'
import { handleRedirectInternal } from '@/custom/common/components'
import ProductCommonContext from '@/custom/context/common/commonContext'
import SavedSearchBody from './SavedSearch/SavedSearchBody'
import { useHistory } from 'react-router-dom'
import './Dashboard.css'

import SEO from '@/utils/SEO'

function SavedSearch() {
    //saved products page
    const history = useHistory()
    const productCommonContext = useContext(ProductCommonContext)
    const { setSearchValue } = productCommonContext

    // SEARCH
    const getSearchBarObjet = (value) => {
        setSearchValue({
            filters: {
                searchbar: {
                    value: value,
                },
            },
        })
        handleRedirectInternal(history, 'search')
    }

    return (
        <>
            <SEO title="My Saved Searches" url={window.location.href} />
            <SavedSearchBody getSearchBarObjet={getSearchBarObjet} />
        </>
    )
}

export default SavedSearch
