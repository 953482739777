import React, { useReducer } from 'react'
import BuyerContext from './buyerContext'
import BuyerReducer from './buyerReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './buyerTypes'

const BuyerState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(BuyerReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Bid Confirm
    const bidConfirm = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'placeBid', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    status: res?.data.status ? 'success' : 'error',
                    message: res?.data?.statusText ? res?.data?.statusText : '',
                    data: res?.data.finalresults,
                    from: 'bidConfirm',
                },
            })
            // resp.commonResponse(res.data, 'bidConfirm')
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    const bidRestrict = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkBidRestriction', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    status: res?.data.status ? 'success' : 'error',
                    message: res?.data?.statusText ? res?.data?.statusText : '',
                    from: fromVariable ? fromVariable : 'bidRestrict',
                },
            })
            // resp.commonResponse(res.data, 'bidConfirm')
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    const bidCustomData = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'bidCustomData', formData, '', 'bid')])
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    // Bid Confirm
    const preBidConfirm = async (formData) => {
        try {
            await Promise.all([apiCall('post', 'preBidConfirm', formData, '', 'bid')])
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    // Bid Cancel
    const bidCancel = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'bidCancel', formData, '', 'bid')])
            resp.commonResponse(res.data, 'bidCancel')
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    const bidAward = async (formData) => {
        const from = 'bidAward'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'manualAward', formData, '', 'seller/bid'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // TTWBid Confirm
    const ttwBidConfirm = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'confirm', formData, '', 'ttwbid')])
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    const ttwBidTemp = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'bidConfirmTemp', formData, '', 'ttwbidTemp'),
            ])
            resp.commonResponse(res.data, 'bidTemp')
        } catch (err) {
            resp.commonErrorResponse('bidTemp')
        }
    }

    const cancelttwbidTemp = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cancelttwbidTemp', formData, '', 'ttwbidTemp'),
            ])
            resp.commonResponse(res.data, 'cancelttwbidTemp')
        } catch (err) {
            resp.commonErrorResponse('cancelttwbidTemp')
        }
    }

    const getFutureProxyList = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'future_proxy', formData, '', 'ttwbid'),
            ])
            resp.commonResponse(res.data, 'future_proxy')
        } catch (err) {
            resp.commonErrorResponse('future_proxy')
        }
    }

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <BuyerContext.Provider
            value={{
                responseStatus: state.responseStatus,
                clearResponse,
                preBidConfirm,
                bidCustomData,
                bidRestrict,
                bidConfirm,
                bidCancel,
                bidAward,
                ttwBidConfirm,
                ttwBidTemp,
                cancelttwbidTemp,
                getFutureProxyList,
            }}
        >
            {props.children}
        </BuyerContext.Provider>
    )
}

export default BuyerState
