import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './ProductView.css'
// import Buynow from '@/custom/components/molecules/Buynow/BuynowItem'
import CustomCommonContext from '@/custom/context/common/commonContext'
import { useFormik } from 'formik'
import { dateFormatFront, currencyFormat } from '@/custom/common/components'
// import TertiaryButton from '@/custom/components/atoms/TertiaryButton'
// import ReverseBidding from '@/custom/components/molecules/Reverse/Bidding/BiddingItem'
import Timer from '@/custom/common/timer'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import ProductContext from '@/custom/context/product/productContext'
import AuthContext from '@/custom/context/auth/authContext'
import Loaders from '@/custom/components/molecules/Loaders'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import { handleRedirectInternal, isValidURL } from '@/custom/common/components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SEO from '@/utils/SEO'
import { mapData } from '@/custom/common/components'
import AlertContext from '@/custom/context/alert/alertContext'
import FavouriteCheckbox from '@/custom/components/atoms/FavoriteCheckbox'
import ImageZoom from '@/custom/components/organisms/ImageZoom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { format, parseISO } from 'date-fns'
import CustomInput from '@/custom/components/atoms/Inputs/CustomInput'
import CustomDialog from '../CustomDialog'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment-timezone'

// import DynamicContext from '@/custom/context/product/productContext'

const ProductView = (props) => {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const [viewSingleProduct, setViewSingleProduct] = useState({})
    const [productModal, setProductModal] = useState(false)
    const [currImage, setCurrImage] = useState('')
    let [viewBidHistory, setViewBidHistory] = useState([])
    const [product, setProduct] = useState()
    const [condition, setCondition] = useState(false)
    const [discount, setDiscount] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [formattedDate, setFormattedDate] = useState(false)
    const [manage, setManage] = React.useState(false)
    const history = useHistory()
    const match = useRouteMatch()
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { allLocations, alldata_all_dropdown, setBidHistoryValue } = customCommonContext
    const alertContext = useContext(AlertContext)
    const updateFunction = props.updateFunction

    const { isAuthenticated, user } = authContext

    const {
        similar_products,
        getAllSimilarProducts,
        search_allbidhistory,
        getAllBidHistory,
        addLotNotes,
        removeLotNotes,
        updateLotNotes,
        responseStatus,
        clearResponse,
        getWatchlistCount,
    } = productContext

    const isDesktopResp = useMediaQuery({ query: '(min-width: 600px)' })

    const { setAlert } = alertContext
    // const dynamicContext = useContext(DynamicContext)
    // const {
    //     notesUpdate: dynamicTableAction,
    //     responseStatus: responseStatusNotes,
    //     clearResponse: clearNotes,
    // } = dynamicContext

    const updateLoading = (data) => {
        setIsLoading(data)
    }
    const [editNotes, setEditNotes] = useState(false)
    const [notesValue, setNotesValue] = useState('')

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: '10px',
        },
    }))(MuiAccordionDetails)

    const [imageIndex, setImageIndex] = useState(0)
    const [imageZoomModal, setImageZoomModal] = useState(false)
    const [ctgDialog, setCtgDialog] = useState(false)
    const [toggletext, setToggletext] = useState(false)

    // const imageFullScreen = (imgindex) => {
    //     setImageIndex(imageRef?.current?.state?.currentIndex)
    // }
    // const imgPopup = (popupView) => {
    //     setImageZoomModal(popupView)
    // }
    // if (!images[currentImage.current.getCurrentIndex()]?.hasOwnProperty('embedUrl')) {
    //     imgPopup(true)
    // }

    const printViewSelector = useRef()
    const currentImage = useRef(null)

    const noteLength = 200
    const descriptionNotes = product?.prodet?.auctionnotes || ''

    const handleToggleNotes = () => {
        setToggletext((prevToggletext) => !prevToggletext)
    }

    const renderCustomControls = () => {
        setProductModal(true)
        setImageIndex(currentImage?.current?.state?.currentIndex)
        setImageZoomModal(true)
        setCurrImage(
            images[currentImage?.current?.state?.currentIndex]?.file_name ||
                images[currentImage?.current?.state?.currentIndex]?.original,
        )
    }

    const handleNotesChange = (event, newPage) => {
        setNotesValue(event.target.value)
    }

    // const handleGalleryView = () => {
    //     setProductModal(true)
    //     setImageZoomModal(true)
    //     setImageIndex(imageRef?.current?.state?.currentIndex)
    //     setCurrImage(
    //         images[imageRef?.current?.state?.currentIndex]?.file_name ||
    //             images[imageRef?.current?.state?.currentIndex]?.original,
    //     )
    // }

    const handlePath = (data) => {
        window.open(data, '_blank')
    }

    const toggleImage = (nav) => {
        if (nav == 'next') {
            if (!images[(imageIndex + 1) % images?.length]?.hasOwnProperty('embedUrl')) {
                setImageIndex((imageIndex + 1) % images?.length)
            }
        } else {
            if (
                !images[(imageIndex + images?.length - 1) % images?.length]?.hasOwnProperty(
                    'embedUrl',
                )
            ) {
                setImageIndex((imageIndex + images?.length - 1) % images?.length)
            }
        }
    }

    useEffect(() => {
        console.log(props?.project_id, product?.prodet?.id, 'product?.prodet?.id')
        if (props?.project_id == product?.prodet?.id) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [props?.project_id, product])

    useEffect(() => {
        if (product && product.prodet && product.prodet.msrp_og && product.prodet.msrp_og > 0) {
            let sku_count = product.prodet.sku_count ? product.prodet.sku_count : 1
            let msrp_org = parseFloat(product.prodet.msrp * sku_count).toFixed(2)
            let discount =
                (parseFloat(msrp_org) - parseFloat(product.current_bid).toFixed(2)) /
                parseFloat(msrp_org)
            discount = parseInt(discount * 100)
            console.log(discount, 'disccc')
            if (discount > 0) {
                setDiscount(`${discount} %`)
            } else {
                setDiscount('')
            }
        } else {
            setDiscount('')
        }
    }, [product])

    useEffect(() => {
        // console.log(props.data, 'propsdata')
        // if (props?.data?.prodet?.msrp_og && props?.data?.prodet?.msrp_og > 0) {
        //     let msrp_org = props.data.prodet?.msrp_og
        //     let discount =
        //         (parseFloat(msrp_org) - parseFloat(props?.data?.prodet?.wprice).toFixed(2)) /
        //         parseFloat(msrp_org)
        //     discount = parseInt(discount * 100)
        //     console.log(discount, 'disccc')
        //     if (discount > 0) {
        //         setDiscount(`${discount} %`)
        //     } else {
        //         setDiscount('')
        //     }
        // } else {
        //     setDiscount('')
        // }
        if (props?.data?.prodet?.id) {
            setProduct(props.data)
        } else {
            setProduct({})
        }
        setViewSingleProduct(props.data)
        if (props.data?.date_closed) {
            const dateForm = parseISO(props.data.date_closed)
            setFormattedDate(format(dateForm, "EEEE, MMMM do, yyyy 'at' h:mm:ss a"))
        }
        console.log(props.data?.prodet, 'notesval')
        if (props.data?.prodet?.notes && props.data?.prodet?.notes !== '') {
            setNotesValue(props.data?.prodet?.notes)
            formikNotes.values.lot_notes_id = props.data?.prodet?.noteId
            setEditNotes(false)
        } else {
            setEditNotes(true)
            setNotesValue('')
        }
    }, [props.data])

    const formikNotes = useFormik({
        initialValues: { product_id: '', lot_notes: '', add: 1, lot_notes_id: 0 },
        onSubmit: (values) => {
            if (values.add && values.lot_notes_id) {
                if (values.lot_notes !== '') {
                    updateLotNotes(
                        {
                            lot_notes: formikNotes.values.lot_notes,
                            lot_notes_id: formikNotes.values.lot_notes_id,
                            product_id: props.data.prodet.id,
                        },
                        `updatenotes${user?.id}${props.data.prodet.id}`,
                    )
                } else {
                    setAlert('Please Enter Bidding Notes', 'error')
                }
            } else if (values.add) {
                if (values.lot_notes !== '') {
                    addLotNotes(
                        {
                            lot_notes: formikNotes.values.lot_notes,
                            product_id: props.data.prodet.id,
                        },
                        `addnotes${user?.id}${props.data.prodet.id}`,
                    )
                } else {
                    setAlert('Please Enter Bidding Notes', 'error')
                }
            } else {
                removeLotNotes(
                    {
                        lot_notes_id: formikNotes.values.lot_notes_id,
                        product_id: props.data.prodet.id,
                    },
                    `deletenotes${user?.id}${props.data.prodet.id}`,
                )
            }
        },
    })

    const notesAddFunction = () => {
        dynamicTableAction(
            {
                notes: formikNotes.values.notes,
                id: props.data.project_notes_id ? props.data.project_notes_id : 0,
                active: 1,
                project_id: props.data.id,
                user_id: user?.id,
                table: 'project_notes',
            },
            `addnotes${user?.id}${props.data.id}`,
        )
    }

    const deleteNotesFunction = () => {
        dynamicTableAction(
            {
                id: props.data.project_notes_id,
                table: 'project_notes',
                active: 2,
            },
            `deletenotes${user?.id}${props.data.id}`,
        )
    }

    useEffect(() => {
        if (responseStatus && responseStatus.status == 'success') {
            console.log(responseStatus, 'responseStatus')
            setAlert(responseStatus.message, responseStatus.status)
            if (responseStatus.from == `addnotes${user?.id}${props.data.prodet.id}`) {
                product.prodet.notes = notesValue
                product.prodet.noteId = responseStatus.data.lotNotesId
                formikNotes.values.lot_notes_id = responseStatus.data.lotNotesId
                setEditNotes(false)
            } else if (responseStatus.from == `updatenotes${user?.id}${props.data.prodet.id}`) {
                product.prodet.notes = notesValue
                setEditNotes(false)
            } else if (responseStatus.from == `deletenotes${user?.id}${props.data.prodet.id}`) {
                product.prodet.notes = ''
                product.prodet.noteId = 0
                formikNotes.values.lot_notes = ''
                formikNotes.values.lot_notes_id = 0
                setNotesValue('')
            }
            clearResponse()
        }
    }, [responseStatus])

    const productBiddingStatus = (userInfo, productInfo) => {
        if (product.bidtopstatus && product.bidtopstatus !== '') return product.bidtopstatus

        if (userInfo && productInfo) {
            // return none if no top status
            if (productInfo.bidhistory?.length < 1) {
                return 'none'
            }
            if (didUserBid(userInfo.id, productInfo.bidhistory)) {
                if (productInfo.market_status === 'closed') {
                    if (productInfo.bidwinneruser === userInfo.id) {
                        return 'won'
                    } else {
                        return 'lost'
                    }
                }
                if (productInfo.market_status === 'open') {
                    if (productInfo.bidhistory[0].user_id === userInfo.id) {
                        return 'winner'
                    } else {
                        return 'outbid'
                    }
                }
            }
            return 'none'
            //check if user is winning

            //check
        }
    }

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'addWatchlist' ||
                responseStatus.from === 'removeWatchlist'
            ) {
                setAlert(responseStatus.message, responseStatus.status)
                if (props.updateFunction) {
                    props.updateFunction(props.data.prodet.id)
                }
                getWatchlistCount({})
            }
            clearResponse()
        }
    }, [responseStatus])

    const renderRightNav = (onClick, disabled) => {
        return (
            <Button
                className={`image-gallery-icon image-gallery-right-nav ${
                    disabled ? 'disabled' : ''
                }`}
                disabled={disabled}
                onClick={onClick}
            >
                <span className="material-icons">expand_more</span>
            </Button>
        )
    }

    const renderLeftNav = (onClick, disabled) => {
        return (
            <Button
                className={`image-gallery-icon image-gallery-left-nav ${
                    disabled ? 'disabled' : ''
                }`}
                disabled={disabled}
                onClick={onClick}
            >
                <span className="material-icons">expand_less</span>
            </Button>
        )
    }

    useEffect(() => {
        if (viewSingleProduct && Object.keys(viewSingleProduct).length) {
            setProduct(viewSingleProduct)
        }
    }, [viewSingleProduct])

    useEffect(() => {
        if (product?.prodet?.id && props.pid == product?.prodet?.id) {
            getAllSimilarProducts({
                limit: 5,
                product_id: product.prodet.id,
            })
        }
    }, [product?.prodet?.id ? product.prodet.id : 0])

    const handleConditionDialog = (data) => {
        setCondition(data)
        setCtgDialog(true)
    }

    const handleCloseDialog = () => {
        setCtgDialog(false)
        setCondition(false)
    }

    useEffect(() => {
        // if (!props.isDrawer) {
        //     setIsLoading(false)
        // }
        console.log(similar_products, 'similar_products')
        if (similar_products?.records?.similarProduct) {
            setViewProduct(similar_products.records.similarProduct)
        }
    }, [similar_products])

    useEffect(() => {
        if (props.viewProductUpdate && viewProduct.length) {
            props.viewProductUpdate(viewProduct)
        }
    }, [viewProduct])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    useEffect(() => {
        if (product) {
            if (product.attachments) {
                if (product.attachments.length) {
                    product.attachments.forEach(function (data) {
                        data['original'] = isValidURL(data['avatarorg'])
                            ? data['avatarorg']
                            : global.images_url + data['avatarorg']
                        data['thumbnail'] = isValidURL(data['avatarorg'])
                            ? data['avatarorg']
                            : global.images_url + data['avatarorg']
                    })
                    setImages(product.attachments)
                } else {
                    setImages([])
                }
            } else {
                setImages([])
            }
            getAllBidHistory({
                limit: 10,
                page: 1,
                product_id: product?.prodet?.id,
            })
        } else {
            setImages([])
        }
    }, [product])

    useEffect(() => {
        setViewBidHistory(search_allbidhistory.records.length ? search_allbidhistory.records : [])
    }, [search_allbidhistory])

    // useEffect(() => {
    //     if (responseStatusBid) {
    //         if (responseStatusBid.from === 'bidCancel') {
    //             if (responseStatusBid.status === 'success') {
    //                 getAllBidHistory({
    //                     limit: 10,
    //                     page: 1,
    //                     id: product?.id,
    //                 })
    //                 setAlert(responseStatusBid.message, 'success')
    //                 clearResponseBid()
    //             } else {
    //                 setAlert(responseStatusBid.message, 'error')
    //                 clearResponseBid()
    //             }
    //         }
    //     }
    // }, [responseStatusBid])

    const cancelBid = () => {
        if (search_allbidhistory?.records?.length > 0) {
            let topValid = search_allbidhistory.records.find((bid) => bid.declined === 0)
            bidCancel({ id: topValid.bidid })
        }
    }

    const isBidCanceAllowed = () => {
        let diff = 0
        if (product.date_closed) {
            diff = new Date(product.date_closed).getTime() - new Date().getTime()
        }
        let allowed = product.custom_auctionlot_bid_retract_minutes * 60 * 1000
        return (
            product.custom_auctionlot_bid_retract_check === 1 &&
            product.custom_auctionlot_bid_retract_minutes > 0 &&
            search_allbidhistory.records?.length > 0 &&
            diff > 0 &&
            diff > allowed
        )
    }

    const substractMinutes = (date, minutes) => {
        const d = new Date(date)
        const newTime = d.getTime() - minutes * 60 * 1000
        const newDate = new Date(newTime)
        return newDate.toISOString()
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    const closePopUp = () => {
        setManage(false)
        formik.handleReset()
    }
    const handleMessageSubmit = async (values) => {
        let messageFormData = {
            ...values,
            attachinsidename: '',
            attachorginalname: '',
            sellermsgpost: product.user_id,
            status: 'open',
            user_id: user.id,
            projectmsgpost: product.id,
        }

        saveMessage(messageFormData)
    }
    const formik = useFormik({
        initialValues: {
            message: '',
        },

        onSubmit: (values) => {
            handleMessageSubmit(values)
            setManage(false)
            formik.handleReset()
        },
    })

    const message = []
    message.push({
        label: 'Message',
        placeholder: `Type message to admin`,
        class: 'col-md-12',
        name: 'message',
        formik: formik,
    })
    const handleGoBack = () => {
        history.goBack()
    }

    const handlePrint = (htmlDom) => {
        if (htmlDom) {
            // Create a new window or iframe for printing
            const printWindow = window.open(
                '',
                '_blank',
                'width=1000,height=800,scrollbars=yes,resizable=yes,top=100,left=300,fullscreen=yes,',
            )

            // Collect all styles from the main document
            const styles = [...document.styleSheets]
                .map((styleSheet) => {
                    try {
                        return [...styleSheet.cssRules].map((rule) => rule.cssText).join('')
                    } catch (e) {
                        console.warn('Could not load stylesheet', styleSheet)
                        return ''
                    }
                })
                .join('\n')

            const links = [...document.querySelectorAll('link[rel="stylesheet"]')]
                .map((link) => link.outerHTML)
                .join('')

            // Write the HTML of the DOM element to the new window
            printWindow.document.write(`
              <html>
                <head>
                  <title>Print Preview</title>
                  ${links}
                   <style>${styles}</style>
                </head>
                <body>
                  ${htmlDom.outerHTML}  <!-- Print only this DOM element -->
                </body>
              </html>
            `)

            // Close the document to complete the content writing process
            printWindow.document.close()

            // Wait for the content to be fully loaded, then print
            printWindow.onload = () => {
                printWindow.focus()
                printWindow.print()
                printWindow.close() // Close the print window after printing
            }
        } else {
            console.log('Print No loaded Check Ref DOC')
        }
    }

    const handleTerms = () => {
        let newWindow = null
        newWindow = window.open(
            `${process.env.REACT_APP_FRONTEND_URL}/terms`,
            '_blank',
            'noopener,noreferrer',
        )

        if (newWindow) newWindow.opener = null
    }

    const handlePickup = () => {
        let newWindow = null
        newWindow = window.open(
            `${process.env.REACT_APP_FRONTEND_URL}/pickup`,
            '_blank',
            'noopener,noreferrer',
        )

        if (newWindow) newWindow.opener = null
    }

    const handleLocation = (data) => {
        handlePath(`https://www.google.co.in/maps/place/${data || ''}`)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    // <div p={3}>
                    <p>{children}</p>
                    // </div>
                )}
            </div>
        )
    }
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    const [value, setValue] = React.useState(0)

    const handleTabsChange = (event, newValue) => {
        setValue(newValue)
    }

    const [inputFieldToggle, setInputFieldToggle] = useState(false)

    const handleInputFieldToggle = () => {
        setInputFieldToggle((prev) => !prev)
    }

    return (
        <>
            <div className="productView customContainer" id="productView" ref={printViewSelector}>
                {manage && (
                    <CustomDialog
                        title={
                            <div className="d-flex align-items-center justify-content-between">
                                Message
                                <IconButton onClick={() => closePopUp(false)}>
                                    <span className="material-icons">clear</span>
                                </IconButton>
                            </div>
                        }
                        open={manage}
                    >
                        <form>
                            <div className="row mb-4">{Object.values(mapData(message))}</div>
                            <div className="actionWrapper">
                                <PrimaryButton
                                    onClick={formik.handleSubmit}
                                    id="confirm_bid_submit"
                                    type="button"
                                    label={'Submit'}
                                />
                            </div>
                        </form>
                    </CustomDialog>
                )}
                {isLoading ? (
                    <Loaders name="product_view" isLoading={isLoading} loop={1} />
                ) : product ? (
                    <>
                        <div className="productNavi d-flex align-items-center justify-content-between">
                            <div
                                className="goBack d-flex align-items-center"
                                onClick={props.drawerHandler ? props.drawerHandler : handleGoBack}
                            >
                                <span className="material-icons mr-2">arrow_back</span>
                                Back to results
                            </div>
                            <div
                                className="goBack d-flex align-items-center"
                                onClick={() => handlePrint(printViewSelector.current)}
                            >
                                <span className="material-icons-outlined mr-2">print</span>
                                Print
                            </div>
                        </div>

                        {isAuthenticated && (
                            <>
                                {(product?.bidtopstatus?.includes('losing this item') ||
                                    product?.bidtopstatus?.includes('outbidder')) &&
                                    product.prodet.market_status == 'open' && (
                                        <div className="pvBidStatus">
                                            <h4 className="outbid">{t('You have been outbid')}.</h4>
                                        </div>
                                    )}
                                {product?.bidtopstatus &&
                                    (product?.bidtopstatus?.includes('winning') ||
                                        product?.bidtopstatus == 'winner') && (
                                        <div className="pvBidStatus">
                                            <h4 className="winning">
                                                {t('You are winning this item')}.
                                            </h4>
                                        </div>
                                    )}
                                {product?.bidtopstatus && product?.bidtopstatus?.includes('Won') && (
                                    <div className="pvBidStatus">
                                        <h4 className="won">
                                            {t('Congratulations you have won this item')}
                                        </h4>
                                    </div>
                                )}
                                {product?.bidtopstatus?.includes('losing this item') &&
                                    product.prodet.market_status != 'open' && (
                                        <div className="pvBidStatus">
                                            <h4 className="lost">
                                                {t('Unfortunately you have lost this item')}
                                            </h4>
                                        </div>
                                    )}
                            </>
                        )}

                        <div
                            className={`${
                                props.isDrawer ? 'productViewDrawer ' : ''
                            }productViewLayoutCut asdas`}
                        >
                            {/* {isAuthenticated ? (
                                    <>
                                        <div className="pvBidBox">
                                            {product.market_status === 'open' &&
                                            !product.future_active ? (
                                                <>
                                                    {product.auction ? (
                                                        <>
                                                            <Bidding data={product} type="proxy" />
                                                            <Bidding data={product} type="hard" />
                                                        </>
                                                    ) : null}

                                                    {product.buynow &&
                                                    product.bprice >= product.wprice ? (
                                                        <Buynow
                                                            data={product}
                                                            editQuantity={true}
                                                            isOnlyBuynow={
                                                                product.auction === 0 ? true : false
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : null}
                                        </div>
                                        {product.bidtopstatus === 'winner' && (
                                            <div className="pvBidStatus">
                                                <h4 className="winning">
                                                    {'Yayy you are winning this item caps'}.
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'won' && (
                                            <div className="pvBidStatus">
                                                <h4 className="won">
                                                    {'Congratulations you have won this item'}
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'lost' && (
                                            <div className="pvBidStatus">
                                                <h4 className="lost">
                                                    {'Unfortunately you have lost this item'}
                                                </h4>
                                            </div>
                                        )}
                                    </>
                                )} */}
                            <div
                                className="pvLt"
                                style={{ marginTop: props.isDrawer ? '24px' : '' }}
                            >
                                {props.isDrawer && (
                                    <div className="productDeatailsView">
                                        {' '}
                                        <h6 className="lotID">
                                            LOT ID:{product?.prodet?.auctionid}
                                        </h6>
                                        {/* Tit */}
                                        <div className="pvProductInfo">
                                            <h2 className="googleSearch">
                                                {product?.prodet?.title}
                                                {/* <a
                                                target="_blank"
                                                rel="noreferrer"
                                                className="googleSearch"
                                                href={`https://www.google.com/search?q=${product.title}`}
                                            >
                                                <span className="material-icons">search</span>
                                            </a> */}
                                            </h2>
                                            {/* <Button className="d-flex align-items-center addWatch">
                                            <span className="material-symbols-outlined mr-2">
                                                favorite
                                            </span>
                                            Add to watchlist
                                        </Button> */}
                                            {product?.prodet?.market_status == 'open' ? (
                                                <FavouriteCheckbox
                                                    watchlisted={product?.prodet?.watchlist_id}
                                                    project_id={product?.prodet?.id}
                                                    // fill
                                                    // addLabel="Add to Favorites"
                                                    // removeLabel="Remove from Favorites"
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                )}

                                {images.length > 0 ? (
                                    !props?.isDrawer ? (
                                        <ImageGallery
                                            items={images}
                                            ref={currentImage}
                                            thumbnailPosition={'bottom'}
                                            showNav={true}
                                            showBullets={false}
                                            showPlayButton={false}
                                            onClick={renderCustomControls}
                                            showFullscreenButton={false}
                                            renderRightNav={renderRightNav}
                                            renderLeftNav={renderLeftNav}
                                            onErrorImageURL="/assets/images/noImage.png"
                                            // onImageError={(e) => FALLBACK_IMAGE(e)}
                                            // onThumbnailError={(e) => FALLBACK_IMAGE(e)}
                                        />
                                    ) : isDesktopResp ? (
                                        <ImageGallery
                                            items={images}
                                            ref={currentImage}
                                            thumbnailPosition={'left'}
                                            showNav={true}
                                            showBullets={false}
                                            showPlayButton={false}
                                            onClick={renderCustomControls}
                                            showFullscreenButton={false}
                                            renderRightNav={renderRightNav}
                                            renderLeftNav={renderLeftNav}
                                            onErrorImageURL="/assets/images/noImage.png"
                                            // onImageError={(e) => FALLBACK_IMAGE(e)}
                                            // onThumbnailError={(e) => FALLBACK_IMAGE(e)}
                                        />
                                    ) : (
                                        <ImageGallery
                                            items={images}
                                            ref={currentImage}
                                            showNav={true}
                                            showBullets={true}
                                            showPlayButton={false}
                                            showThumbnails={false}
                                            onClick={renderCustomControls}
                                            showFullscreenButton={false}
                                            renderRightNav={renderRightNav}
                                            renderLeftNav={renderLeftNav}
                                            onErrorImageURL="/assets/images/noImage.png"
                                            additionalClass="custom-gallery"
                                        />
                                    )
                                ) : null}
                            </div>
                            {/* <PrimaryButton
                                id="contactseller_button"
                                label={'Contact Seller'}
                                type="submit"
                                btnSize="small"
                                onClick={() => setManage(true)}
                            /> */}
                            <div className="pvRt">
                                <div className="productDeatailsView">
                                    {!props.isDrawer && (
                                        <>
                                            <h6 className="lotID">
                                                LOT ID:{product?.prodet?.auctionid}
                                            </h6>
                                            {/* Tit */}
                                            <div className="pvProductInfo">
                                                <h2 className="googleSearch">
                                                    {product?.prodet?.title}
                                                    {/* <a
                                                target="_blank"
                                                rel="noreferrer"
                                                className="googleSearch"
                                                href={`https://www.google.com/search?q=${product.title}`}
                                            >
                                                <span className="material-icons">search</span>
                                            </a> */}
                                                </h2>
                                                {/* <Button className="d-flex align-items-center addWatch">
                                            <span className="material-symbols-outlined mr-2">
                                                favorite
                                            </span>
                                            Add to watchlist
                                        </Button> */}
                                                {product?.prodet?.market_status == 'open' ? (
                                                    <FavouriteCheckbox
                                                        watchlisted={product?.prodet?.watchlist_id}
                                                        project_id={product?.prodet?.id}
                                                        // fill
                                                        // addLabel="Add to Favorites"
                                                        // removeLabel="Remove from Favorites"
                                                    />
                                                ) : null}
                                            </div>
                                        </>
                                    )}{' '}
                                    {product?.prodet?.market_status === 'open' &&
                                        isAuthenticated &&
                                        (product?.prodet?.notes &&
                                        product?.prodet?.notes !== '' &&
                                        !editNotes ? (
                                            <div className="customNotesBox">
                                                <span className="font-weight-bold">Notes:</span>
                                                <p className="mb-0">{product?.prodet?.notes}</p>
                                                <IconButton
                                                    onClick={() => {
                                                        formikNotes.values.add = 0
                                                        formikNotes.handleSubmit()
                                                        setInputFieldToggle(false)
                                                    }}
                                                >
                                                    <i className="material-icons">delete</i>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        setEditNotes(true)
                                                        setInputFieldToggle(true)
                                                    }}
                                                >
                                                    <i className="material-icons">edit</i>
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <div className="customNotesBox">
                                                {product?.prodet?.notes ? (
                                                    <>
                                                        <CustomInput
                                                            size="small"
                                                            placeholder="Bidding Notes"
                                                            value={notesValue}
                                                            maxLength={40}
                                                            onChange={handleNotesChange}
                                                            variant="outlined"
                                                        />
                                                        <IconButton
                                                            onClick={() => {
                                                                formikNotes.values.lot_notes = notesValue
                                                                formikNotes.values.add = 1
                                                                formikNotes.handleSubmit()
                                                            }}
                                                        >
                                                            <i className="material-icons">check</i>
                                                        </IconButton>
                                                    </>
                                                ) : inputFieldToggle ? (
                                                    <>
                                                        <CustomInput
                                                            size="small"
                                                            placeholder="Bidding Notes"
                                                            value={notesValue}
                                                            maxLength={40}
                                                            onChange={handleNotesChange}
                                                            variant="outlined"
                                                        />
                                                        <IconButton
                                                            onClick={() => {
                                                                formikNotes.values.lot_notes = notesValue
                                                                formikNotes.values.add = 1
                                                                formikNotes.handleSubmit()
                                                            }}
                                                        >
                                                            <i className="material-icons">check</i>
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ padding: '0px' }}
                                                            onClick={() => {
                                                                setInputFieldToggle(false)
                                                            }}
                                                        >
                                                            <i className="material-icons">close</i>
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    <Button
                                                        className="addNotesBtnCnt"
                                                        onClick={handleInputFieldToggle}
                                                    >
                                                        <span className="material-icons">
                                                            description
                                                        </span>
                                                        <span>Add Notes</span>
                                                    </Button>
                                                )}
                                            </div>
                                        ))}
                                    <div className={props.isDrawer ? 'locationBadgeCnt' : ''}>
                                        {/* Location */}
                                        <div className="locaProduct">
                                            <span className="material-icons-outlined mr-2">
                                                location_on
                                            </span>
                                            <span
                                                className="txt"
                                                onClick={() =>
                                                    handleLocation(
                                                        product.prodet.inspectiontime_pickupaddress,
                                                    )
                                                }
                                            >
                                                {product?.prodet?.location}
                                            </span>
                                        </div>
                                        {/* Badge */}
                                        {/* <div className="productLabel">Appears new</div> */}
                                        {/* Product bidding Infpormation */}
                                        {/* { ? (<p
                                        className="dentPro mb-3"
                                        onClick={() =>
                                            handleConditionDialog(
                                                product.prodet.conditionunique,
                                            )
                                        }
                                    >
                                        *14 DAY Money Back Guarantee on all Scratch
                                        & Dent items. NO QUESTIONS ASKED!
                                    </p>) : null} */}
                                        <div className="text-left">
                                            {product?.prodet?.conditionunique ? (
                                                <span
                                                    onClick={() =>
                                                        handleConditionDialog(
                                                            product.prodet.conditionunique,
                                                        )
                                                    }
                                                    // key={index}
                                                    style={{ width: 'fit-content' }}
                                                    className={`baDge asdasd d-block ml-0  ${
                                                        product.prodet.conditionunique.toLowerCase() ==
                                                        'appears new'
                                                            ? 'Nw'
                                                            : product.prodet.conditionunique.toLowerCase() ==
                                                              'damaged'
                                                            ? 'dm'
                                                            : product.prodet.conditionunique.toLowerCase() ==
                                                              'opened box'
                                                            ? 'oB'
                                                            : product.prodet.conditionunique ==
                                                              'Scratch & Dent – Damaged'
                                                            ? 'sD'
                                                            : product.prodet.conditionunique ==
                                                                  'Scratch & Dent – New Condition' ||
                                                              product.prodet.conditionunique ==
                                                                  'Scratch & Dent – Good Condition' ||
                                                              product.prodet.conditionunique ==
                                                                  'Scratch & Dent – Fair Condition'
                                                            ? 'Nw'
                                                            : 'sv'
                                                    } `}
                                                >
                                                    {product.prodet.conditionunique}
                                                </span>
                                            ) : null}
                                            {product?.prodet?.conditionunique ==
                                                'Scratch & Dent – Good Condition' ||
                                            product?.prodet?.conditionunique ==
                                                'Scratch & Dent – New Condition' ||
                                            product?.prodet?.conditionunique ==
                                                'Scratch & Dent – Fair Condition' ||
                                            product?.prodet?.conditionunique ==
                                                'Scratch & Dent – Damaged' ? (
                                                <>
                                                    <p
                                                        className="mb-0"
                                                        style={{ color: 'green', fontSize: '12px' }}
                                                    >
                                                        *14 DAY Money Back Guarantee on all Scratch
                                                        & Dent items. NO QUESTIONS ASKED!
                                                    </p>
                                                    <p style={{ color: 'grey', fontSize: '12px' }}>
                                                        *Inspected by our Appliance Team
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="biddingConteiner">
                                        <div className="bidInformaiton">
                                            <div className="mb-3" style={{ textAlign: 'left' }}>
                                                {product?.bidtopstatus && isAuthenticated ? (
                                                    <span
                                                        style={{
                                                            color: product?.bidtopstatus_color,
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        {product?.bidtopstatus}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div
                                                className="pvBidsandOffers"
                                                style={{
                                                    marginBottom: props.pastPage ? '8px' : '',
                                                }}
                                            >
                                                <div className="pvhardBid">
                                                    {product?.prodet?.market_status === 'open' ? (
                                                        <>
                                                            {/* <label>
                                                                {product.cbidtext}
                                                            </label> */}
                                                            <h3>
                                                                {currencyFormat(
                                                                    product?.current_bid,
                                                                )}
                                                            </h3>
                                                            <p>Current bid</p>
                                                        </>
                                                    ) : product?.current_bid ? (
                                                        <>
                                                            <div className="pabChild pvCurrentBid">
                                                                <h4>
                                                                    {currencyFormat(
                                                                        product?.current_bid,
                                                                    )}
                                                                </h4>
                                                                <label>{product.cbidtext}</label>
                                                            </div>
                                                            {/* <p>Current bid</p> */}
                                                        </>
                                                    ) : null}
                                                    {}
                                                </div>

                                                <div className="pvOfferZone">
                                                    {product?.prodet?.sku_count &&
                                                    product?.prodet?.sku_count > 1 ? (
                                                        <>
                                                            <p>Ct. {product?.prodet?.sku_count}</p>
                                                        </>
                                                    ) : null}

                                                    {product?.prodet?.msrp > 0 &&
                                                    product?.prodet?.msrp_og > 0 &&
                                                    discount != '' ? (
                                                        <>
                                                            <p>
                                                                Retail:{' '}
                                                                <s>
                                                                    $
                                                                    {product?.prodet?.msrp
                                                                        ? parseFloat(
                                                                              product?.prodet
                                                                                  ?.msrp *
                                                                                  product?.prodet
                                                                                      ?.sku_count,
                                                                          ).toFixed(2)
                                                                        : null}
                                                                </s>
                                                            </p>
                                                            <p>Save {discount} off retail price</p>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="pvTimerContain">
                                                {product?.prodet?.market_status === 'open' ? (
                                                    <div className="pvTimerBox">
                                                        <span className="material-icons-outlined mr-2">
                                                            timer
                                                        </span>
                                                        <h4>
                                                            <Timer
                                                                date_added={
                                                                    product?.prodet?.date_added
                                                                }
                                                                date_closed={
                                                                    product?.prodet?.date_closed
                                                                }
                                                                withText={1}
                                                                endText="Ends in "
                                                                startText="Starts in "
                                                            ></Timer>
                                                        </h4>
                                                    </div>
                                                ) : (
                                                    <div className="pvTimerBox pvClosedTimer">
                                                        <span className="material-icons-outlined mr-2">
                                                            timer
                                                        </span>
                                                        <label>{t('Closed on')}:</label>
                                                        <h4>
                                                            {dateFormatFront(
                                                                product?.prodet?.date_closed,
                                                            )}
                                                        </h4>
                                                    </div>
                                                )}

                                                <Button
                                                    id="view_bid_history"
                                                    className="pvBidHistBtn"
                                                    onClick={() =>
                                                        setBidHistoryValue(product.prodet.id)
                                                    }
                                                >
                                                    <img
                                                        src="../assets/images/gavel.png"
                                                        className="mr-2"
                                                    />
                                                    {viewBidHistory.length} {t('bids')}
                                                </Button>
                                            </div>
                                            {/* <div className="pvGetPro">
                                                <label>Bidding ends</label>
                                                <p className="txt">
                                                    {product?.prodet?.custom_date_format}
                                                </p>
                                            </div> */}
                                            <div className="pvGetPro d-flex align-items-center justify-content-start gap-3">
                                                <label className="pvFloorLabel">
                                                    Floor Location :
                                                </label>
                                                <p className="txt m-0">
                                                    {product?.prodet?.inventory_loc}
                                                </p>
                                            </div>

                                            <div className="pvGetPro d-flex align-items-center justify-content-start gap-3">
                                                {/* <h6 className="mb-0 mr-3">Condition :</h6>
                                                {product?.prodet?.conditionunique ? (
                                                    <span
                                                        onClick={() =>
                                                            handleConditionDialog(
                                                                product.prodet.conditionunique,
                                                            )
                                                        }
                                                        // key={index}
                                                        style={{ width: 'fit-content' }}
                                                        className={`baDge d-block ml-0  ${
                                                            product.prodet.conditionunique.toLowerCase() ==
                                                            'appears new'
                                                                ? 'Nw'
                                                                : product.prodet.conditionunique.toLowerCase() ==
                                                                  'damaged'
                                                                ? 'dm'
                                                                : product.prodet.conditionunique.toLowerCase() ==
                                                                  'opened box'
                                                                ? 'oB'
                                                                : product.prodet.conditionunique ==
                                                                  'Scratch & Dent – Damaged'
                                                                ? 'sD'
                                                                : 'sv'
                                                        } `}
                                                    >
                                                        {product.prodet.conditionunique}
                                                    </span>
                                                ) : null} */}
                                            </div>
                                            {/* {product?.prodet?.conditionunique ==
                                                'Scratch & Dent – Good Condition' ||
                                            product?.prodet?.conditionunique ==
                                                'Scratch & Dent – New Condition' ||
                                            product?.prodet?.conditionunique ==
                                                'Scratch & Dent – Fair Condition' ||
                                            product?.prodet?.conditionunique ==
                                                'Scratch & Dent – Damaged' ? (
                                                <p
                                                    className="dentPro mb-3"
                                                    onClick={() =>
                                                        handleConditionDialog(
                                                            product.prodet.conditionunique,
                                                        )
                                                    }
                                                >
                                                    *14 DAY Money Back Guarantee on all Scratch &
                                                    Dent items. NO QUESTIONS ASKED!
                                                </p>
                                            ) : null} */}
                                            <div className="pvGetPro biddingEndsCnt">
                                                <label>Bidding ends</label>
                                                <p className="txt">
                                                    {product?.prodet?.date_closed ? (
                                                        <>
                                                            {moment(product?.prodet?.date_closed)
                                                                .tz('America/New_York')
                                                                .format(
                                                                    'dddd, MMM Do, YYYY, hh:mm A z',
                                                                )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        {console.log(product, 'product121212')}
                                        <div className="bidForm">
                                            {isAuthenticated ? (
                                                <>
                                                    {product?.prodet?.market_status === 'open' ? (
                                                        <Bidding
                                                            data={product}
                                                            type="proxy"
                                                            quickBid={true}
                                                        />
                                                    ) : null}
                                                    <div className="pvBidBox">
                                                        {product.market_status === 'open' &&
                                                        !product.future_active ? (
                                                            <>
                                                                {product.auction ? (
                                                                    <>
                                                                        {product.auctiontype ==
                                                                        'reverse' ? (
                                                                            <>
                                                                                {/* <ReverseBidding
                                                                                    data={product}
                                                                                    type="proxy"
                                                                                />
                                                                                <ReverseBidding
                                                                                    data={product}
                                                                                    type="hard"
                                                                                /> */}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {/* {product.custom_auctionlot_bid_type ===
                                                                                'hard' ? (
                                                                                    <Bidding
                                                                                        data={
                                                                                            product
                                                                                        }
                                                                                        type={
                                                                                            'hard'
                                                                                        }
                                                                                    />
                                                                                ) : product.custom_auctionlot_bid_type ===
                                                                                  'proxy' ? (
                                                                                    <Bidding
                                                                                        data={
                                                                                            product
                                                                                        }
                                                                                        type={
                                                                                            'proxy'
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                         <Bidding
                                                                                            data={
                                                                                                product
                                                                                            }
                                                                                            type={
                                                                                                'proxy'
                                                                                            }
                                                                                        />
                                                                                        <Bidding
                                                                                            data={
                                                                                                product
                                                                                            }
                                                                                            type={
                                                                                                'hard'
                                                                                            }
                                                                                            quickBid={
                                                                                                true
                                                                                            }
                                                                                        /> 
                                                                                    </>
                                                                                )} */}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                                {/* {product.buynow &&
                                                                product.bprice >= product.wprice ? (
                                                                    <Buynow
                                                                        data={product}
                                                                        editQuantity={true}
                                                                        isOnlyBuynow={
                                                                            product.auction === 0
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )} */}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    {product.bidtopstatus === 'winner' &&
                                                        isAuthenticated &&
                                                        isBidCanceAllowed() && (
                                                            <>
                                                                <div>
                                                                    <div className="text-right mt-2">
                                                                        <Timer
                                                                            date_added={
                                                                                search_allbidhistory
                                                                                    .records[0]
                                                                                    .created_at
                                                                            }
                                                                            date_closed={substractMinutes(
                                                                                product.date_closed,
                                                                                product.custom_auctionlot_bid_retract_minutes,
                                                                            )}
                                                                            withText={1}
                                                                            endText="Cancel By: "
                                                                            startText="Starts in: "
                                                                            closedText="Cancellation Expired"
                                                                        ></Timer>
                                                                    </div>

                                                                    <Button
                                                                        className="pvBidHistBtn btn my-2"
                                                                        onClick={() => cancelBid()}
                                                                    >
                                                                        <span className="material-icons">
                                                                            undo
                                                                        </span>{' '}
                                                                        {'Cancel My Bid'}
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                </>
                                            ) : (
                                                <PrimaryButton
                                                    id="productView_loginBtn"
                                                    btnSize="mt-3"
                                                    label={'Login to bid'}
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'login')
                                                    }
                                                />
                                            )}
                                            {isAuthenticated &&
                                            product?.prodet?.market_status === 'open' ? null : (
                                                <p className="bidInstruction2">
                                                    <b>
                                                        *Bid amount does not include $3 minimum, or
                                                        17% Buyer premium, whichever is greater.
                                                    </b>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {/* product Information */}
                                    <div className="pvGetPro description">
                                        <div className="pvDescriptionCnt">
                                            <label>Description:</label>{' '}
                                            {product?.prodet?.condition_descriptors1},
                                            <span style={{ marginLeft: '4px' }}>
                                                {product?.prodet?.condition_descriptors2}
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                {product?.prodet?.condition_descriptors3 ===
                                                'Heavy/Larger Item' ? (
                                                    <>
                                                        , Heavy/Larger Item: DealBadger teammates
                                                        are not able to assist in loading items.
                                                        Find local movers{' '}
                                                        <a
                                                            onClick={() => handlePickup()}
                                                            style={{
                                                                color: 'blue',
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            HERE
                                                        </a>{' '}
                                                        that can assist with large items.
                                                    </>
                                                ) : null}
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: toggletext
                                                            ? descriptionNotes
                                                            : `${descriptionNotes.substring(
                                                                  0,
                                                                  noteLength,
                                                              )}...`,
                                                    }}
                                                ></div>

                                                {descriptionNotes.length > noteLength && (
                                                    <button
                                                        onClick={handleToggleNotes}
                                                        style={{
                                                            color: 'green',
                                                            background: 'transparent',
                                                        }}
                                                    >
                                                        {toggletext ? 'Show Less' : 'Show More'}
                                                    </button>
                                                )}
                                            </div>

                                            {/* Conditionally render the Read More/Read Less button */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end flex-wrap">
                                        <div>
                                            {/* <div className="pvGetPro description">
                                                <label>MSRP:</label>
                                                {product?.prodet?.msrp}
                                            </div>
                                            <div className="pvGetPro description">
                                                <label>SKU Count:</label>
                                                {product?.prodet?.sku_count}
                                            </div>
                                            <div className="pvGetPro description">
                                                <label>MSRP * SKU Count:</label>
                                                {product?.prodet?.msrp
                                                    ? parseFloat(
                                                          product?.prodet?.msrp *
                                                              product?.prodet?.sku_count,
                                                      ).toFixed(2)
                                                    : null}
                                            </div> */}
                                            <div className="pvGetPro description">
                                                <label>SKU MSRP:</label>
                                                {product?.prodet?.msrp}
                                            </div>
                                        </div>
                                        <div className="ViewFullDetails mb-3">
                                            <PrimaryButton
                                                btnSize="mt-2"
                                                label="View Product Page"
                                                onClick={() => {
                                                    if (props.closeFunc) {
                                                        props.closeFunc(product?.prodet?.id)
                                                    } else {
                                                        handleRedirectInternal(
                                                            history,
                                                            `productView/${product?.prodet?.id}`,
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* product search online */}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="googleSearch"
                                        href={`https://www.google.com/search?q=${product?.prodet?.title}`}
                                    >
                                        <u>Research item online</u>
                                        <span className="material-icons ml-2">arrow_forward</span>
                                    </a>
                                    {/* <div className="pvActBtn d-flex justify-content-around align-items-center"></div> */}
                                    {/* <div className="pvProductDesc mt-3 mb-3">
                                        {/* <div className="pvDescChild">
                                            <label>{t('auction')} id:</label>
                                            <h6>#{product.auctionid}</h6>
                                        </div>
                                        <div className="pvDescChild">
                                            <label>{t('Lot ID:')}</label>
                                            <h6>#{product.id}</h6>
                                        </div>
                                        {/* <div className="pvDescChild">
                                            <label>{t('auction_name')}:</label>
                                            <h6>{product.auctionlot_title}</h6>
                                        </div>

                                        <div className="pvDescChild">
                                            <label>{t('Auction ends')}:</label>
                                            <h6>{dateFormatFront(product.date_closed)}</h6>
                                        </div>

                                        {/* <div className="pvDescChild">
                                        <label>Product Owner:</label>
                                        <h6>Spencer C</h6>
                                    </div>

                                        <div className="pvDescChild">
                                            <label>{t('Available quantity')}:</label>
                                            <h6>{product.qty}</h6>
                                        </div>

                                        <div className="pvDescChild">
                                            <label>{t('Description')}:</label>
                                            <h6
                                                dangerouslySetInnerHTML={{
                                                    __html: product.desc_proc,
                                                }}
                                            ></h6>
                                        </div>
                                        <DynamicView
                                            product={product}
                                            arrayValue={props.arrayValue}
                                            updateLoading={updateLoading}
                                        />
                                    </div> */}
                                    {/* <div className="pvAccordian mt-2 mb-2 w-100">
                                        {accordianData.map((data, index) => (
                                            <Accordion
                                                square
                                                expanded={expanded === `panel${index}`}
                                                key={index}
                                                onChange={handleChange(`panel${index}`)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1d-content"
                                                    id={`panel${index}d-header`}
                                                >
                                                    <h6 className="accTitle">{data.title}</h6>
                                                </AccordionSummary>
                                                <AccordionDetails>{data.details}</AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div> */}
                                </div>
                                <div className="pvTabs">
                                    <AppBar position="static">
                                        <Tabs
                                            value={value}
                                            onChange={handleTabsChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab label="Pickup information" {...a11yProps(0)} />
                                            <Tab label="Payment details" {...a11yProps(1)} />
                                            <Tab label="Returns & Conditions" {...a11yProps(2)} />
                                            <Tab label="Disclaimer" {...a11yProps(3)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel className="tPanel" value={value} index={0}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product?.prodet?.inspectiontime,
                                            }}
                                        ></div>
                                    </TabPanel>
                                    <TabPanel className="tPanel" value={value} index={1}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product?.prodet?.buyerpremium,
                                            }}
                                        ></div>
                                    </TabPanel>
                                    <TabPanel className="tPanel" value={value} index={2}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product?.prodet?.extbidding,
                                            }}
                                        ></div>
                                    </TabPanel>
                                    <TabPanel className="tPanel" value={value} index={3}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product?.prodet?.payments,
                                            }}
                                        ></div>
                                    </TabPanel>
                                </div>

                                {/* <Comments product={product} /> */}
                            </div>
                        </div>
                    </>
                ) : null}
                <ImageZoom
                    toggleImage={toggleImage}
                    imageZoomModal={imageZoomModal}
                    imageIndex={imageIndex}
                    setImageZoomModal={setImageZoomModal}
                    images={images?.map((data) => {
                        return {
                            main:
                                data?.type == 1
                                    ? `${global.site_url}/uploads/product/${data?.file_name}`
                                    : data?.original,
                        }
                    })}
                />
                <CustomDialog
                    function={handleCloseDialog}
                    className="conditionModal"
                    title={
                        <div className="d-flex align-items-center justify-content-between">
                            Conditions
                        </div>
                    }
                    open={ctgDialog}
                >
                    {condition ? (
                        condition == 'Scratch & Dent – New Condition' ||
                        condition == 'Scratch & Dent – Fair Condition' ||
                        condition == 'Scratch & Dent – Good Condition' ||
                        condition == 'Scratch & Dent – Damaged' ? (
                            <div className="mb-4">
                                <p>
                                    Scratch & Dent marked appliances have a 14-day Money Back
                                    Guarantee. No Questions asked! All Scratch & Dent Appliances
                                    have been tested and inspected by our Appliance team and are
                                    guaranteed to be in the condition described. If you are not
                                    satisfied for any reason, you may return the item for a full
                                    refund up to 14 days after pickup. If you are not able to pick
                                    up your Scratch & Dent item within the 4-day pickup window, you
                                    will receive a Full Refund. The Full Refund, including Hammer
                                    Price, Buyers Premium and sales tax, will be issued to the
                                    credit card used at purchase. This guarantee is only for
                                    appliances marked Scatch & Dent. The standard terms and
                                    conditions apply to all other items. See complete terms and
                                    conditions{' '}
                                    <a className="cursorPointer" onClick={() => handleTerms()}>
                                        here.
                                    </a>{' '}
                                </p>{' '}
                            </div>
                        ) : (
                            <div className="mb-4">
                                <p>
                                    Item Condition Gradesare <span className="oB">OPENED BOX </span>
                                    ,<span className="dm">DAMAGED</span>, and{' '}
                                    <span className="Nw">APPEARS NEW</span>. (See complete{' '}
                                    <a onClick={() => handleRedirectInternal(history, `terms`)}>
                                        Terms and Conditions
                                    </a>
                                    ).
                                </p>
                                <ul>
                                    <li>
                                        <span className="oB">OPENED BOX</span> items were sold by a
                                        retailer, and potentially used before being returned by the
                                        customer. Opened Box items
                                        <u>may be damaged</u>or have an opened box with missing
                                        pieces, missing cables, missing batteries etc.
                                    </li>
                                    <li>
                                        <span className="dm">DAMAGED</span> items have visible
                                        damage or could be incomplete, missing parts and have not
                                        been tested for functionality.
                                    </li>
                                    <li>
                                        <span className="Nw">APPEARS NEW</span> items appear to be
                                        in a new condition or have not been used previously. DB may
                                        offer a refund for all Appears New items with discovered
                                        defects.
                                        <u>
                                            Customer MUST report defects to DB prior to leaving the
                                            Pickup Center with the item.
                                        </u>
                                    </li>
                                </ul>
                                <p>
                                    DB will include stock photos when available, Buyer accepts that
                                    DB taken photos and stock photos may be inaccurate. It is the
                                    responsibility of the Buyer to inspect all items before placing
                                    any bid on the Site.
                                </p>
                                <p>
                                    Buyers may inspect
                                    <span className="oB">OPENED BOX</span>,
                                    <span className="dm">DAMAGED</span>
                                    ,items only at the
                                    <b>Inspection Table</b>.
                                    <u>
                                        Buyers are not permitted to open or inspect items on the
                                        warehouse floor
                                    </u>
                                    .
                                </p>
                                <p>
                                    <span className="Nw">APPEARS NEW</span> items are not available
                                    to be inspected before the auction closes. Winners of
                                    <span className="Nw">APPEARS NEW</span> items are asked to
                                    inspect their items condition before they leave the Pickup
                                    Center.
                                </p>
                            </div>
                        )
                    ) : null}
                </CustomDialog>
            </div>
        </>
    )
}
export default ProductView
