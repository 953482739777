import React, { useEffect, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import './MegaMenu.css'
import { ListItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

function MegaMenu({
    anchorOrigin,
    transformOrigin,
    menuClass,
    title,
    popoverClass,
    frontAddon,
    fullWidth,
    menuItems,
    handleFunction,
    directChildren, //Allows to send HTML elements as props to the component leading to more customization.
    ...rest
}) {
    const [level1, setLevel1] = useState(null)
    const history = useHistory()
    const handleClick = (event) => {
        setLevel1(event.currentTarget)
    }

    const handleClose = () => {
        setLevel1(null)
    }
    const dropdownClick = (id, e) => {
        handleFunction(id, e)
        handleClose()
    }
    const open = Boolean(level1)
    const id = open ? 'megaMenu' : undefined

    const anchorOriginData = anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'center',
    }

    const transformOriginData = transformOrigin || {
        vertical: 'top',
        horizontal: 'center',
    }

    return (
        <div className={`megaMenu ${menuClass}`}>
            <Button className="menuTitle" aria-describedby={id} onClick={handleClick}>
                {frontAddon && (
                    <object
                        data="/assets/svg/location_white.svg"
                        type="image/svg+xml"
                        width="18px"
                        className="mr-1 menuObject"
                        color="green"
                    ></object>
                )}
                {title}
                <span className="material-icons">expand_more</span>
            </Button>

            {/* This will be rendered outside the div at the end of the body */}
            <Popover
                id={id}
                open={open}
                className={`level1Menu ${popoverClass} ${fullWidth ? 'fullWidth' : ''}`}
                anchorEl={level1}
                onClose={handleClose}
                anchorOrigin={anchorOriginData}
                transformOrigin={transformOriginData}
            >
                {directChildren ? (
                    <div onClick={handleClose}>{rest.children}</div>
                ) : (
                    <>
                        {menuItems ? (
                            <ul
                                className="menuList"
                                //  onMouseLeave={handleClose}
                            >
                                {menuItems?.map((data, index) => (
                                    <ListItem
                                        button
                                        key={`menuItem_${index}`}
                                        onClick={(e) => {
                                            dropdownClick(data.id, e)
                                        }}
                                    >
                                        {data.description}
                                    </ListItem>
                                ))}
                            </ul>
                        ) : (
                            <h6 className="noRecords">No Categories Found!</h6>
                        )}
                    </>
                )}
            </Popover>
        </div>
    )
}

export default MegaMenu
