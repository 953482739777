export const GET_STATIC_PAGE = 'GET_STATIC_PAGE'
export const GET_INVITE_DATA = 'GET_INVITE_DATA'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_COMPANY_OWNER_DATA = 'GET_COMPANY_OWNER_DATA'
export const GET_SKYFLOW_CARDS = 'GET_SKYFLOW_CARDS'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_USER_NOTIFICATION = 'GET_USER_NOTIFICATION'
export const GET_REFUND = 'GET_REFUND'
export const GET_CREDITS = 'GET_CREDITS'
export const GET_RECEIPT = 'GET_RECEIPT'
export const VIEW_RECEIPT = 'VIEW_RECEIPT'
