// for common data
export const COMMON_VALUES = 'COMMON_VALUES'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP'
export const LOADERS = 'LOADERS'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const TIMER_VALUE = 'TIMER_VALUE'
export const UPLOADED_IMAGE = 'UPLOADED_IMAGE'
export const BID_HISTORY_VALUE = 'BID_HISTORY_VALUE'
export const CLEAR_BID_HISTORY = 'CLEAR_BID_HISTORY'
export const PENDING_INVOICES = 'PENDING_INVOICES'
