import React, { useContext, useState, useEffect } from 'react'
import DashboardLayout from '../DashboardLayout'
import { Button } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import CustomDialog from '@/custom/components/organisms/Dialog'
import ProductContext from '@/custom/context/product/productContext'
import AuthContext from '@/custom/context/auth/authContext'

import { dateTimeFormatFunction } from '@/custom/common/components'

import SEO from '@/utils/SEO'

const SavedSearchBody = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const [toggleDialog, setToggleDialog] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [currentID, setCurrentID] = useState(0)
    const [record, setRecord] = useState([])
    const [limit, setLimit] = useState(15)

    const { isAuthenticated } = authContext

    const { getSavedSearch, saved_searches } = productContext

    useEffect(() => {
        // console.log(saved_searches, 'from response status')
        if (saved_searches) {
            /*if (responseStatus.from === 'saved_search' && responseStatus.data) {*/
            //console.log(responseStatus.from, 'from is working')
            console.log(saved_searches.records, 'data is working')
            setRecord(
                saved_searches.records?.savedSearchList?.length
                    ? saved_searches.records.savedSearchList
                    : [],
            )
            setTotalPage(saved_searches.records.savedSearchCount)
            // setRecord(responseStatus.data.records)
            // setTotalPage(responseStatus.data.totalRecords)
            // }
        }
    }, [saved_searches])

    const loadTable = (page = currentPage) => {
        getSavedSearch({})
    }

    const removeRecord = (id) => {
        removeSavedSearch({ id })
        setToggleDialog(false)
    }

    useEffect(() => {
        if (isAuthenticated) {
            loadTable()
        }
    }, [isAuthenticated])

    const onHandlePage = async (event, value) => {
        setCurrentPage(value)
        loadTable(value)
    }

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    // useEffect(() => {
    //     if (
    //         responseStatus &&
    //         responseStatus.status == 'success' &&
    //         responseStatus.from == 'saved_search_msg'
    //     ) {
    //         getSavedSearch({ page: 1, limit: limit })
    //     }
    // }, [responseStatus])

    return (
        <>
            <SEO title="My Saved Searches" url={window.location.href} />
            <DashboardLayout title={'My saved searches'}>
                <div className="dashboardInner mySavedSearches">
                    {record && record?.length ? (
                        <>
                            <h5 className="dashSubtitle">
                                {'You can find all your saved searches here'}
                                <br />
                                {'Saved search helps you find your preferred products easily'}
                            </h5>
                            <div className="table-responsive dpTable">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th scope="col">{'S no'}</th>
                                            <th scope="col">{'Title'}</th>
                                            <th scope="col">{'Date added'}</th>
                                            <th scope="col">{'Action'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {record.map((data, index) => (
                                            <tr key={`saved_${index}`}>
                                                <td>{index + 1}</td>
                                                <td>{data.title}</td>
                                                <td>{dateTimeFormatFunction(data.date_added)}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center ssActBtn">
                                                        <Button
                                                            id="open_in_new"
                                                            onClick={() =>
                                                                props.getSearchBarObjet(data.title)
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                open_in_new
                                                            </span>
                                                        </Button>
                                                        {/* <Button
                                                            id="savedSearch_delete"
                                                            onClick={() => {
                                                                setCurrentID(data.id)
                                                                setToggleDialog(true)
                                                            }}
                                                        >
                                                            <span className="material-icons">
                                                                delete
                                                            </span>
                                                        </Button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination
                                    count={Math.ceil(totalPage / limit)}
                                    page={currentPage}
                                    onChange={onHandlePage}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            </div>
                        </>
                    ) : (
                        <NoRecordsFound />
                    )}
                </div>

                <CustomDialog
                    title={'Confirm delete'}
                    open={toggleDialog}
                    function={changeDialogStatus}
                >
                    <h5>{'You are about to delete this saved search'}</h5>
                    <h5>{'Please confirm if you want to continue with this action'}</h5>
                    <div className="actionWrapper">
                        <Button id="no_cancel" onClick={() => setToggleDialog(false)}>
                            {'No cancel'}
                        </Button>
                        <PrimaryButton
                            id="yes_delete"
                            type="button"
                            onClick={() => removeRecord(currentID)}
                            label={'Yes delete'}
                        />
                    </div>
                </CustomDialog>
            </DashboardLayout>
        </>
    )
}

export default SavedSearchBody
