import React, { useContext, useState, useEffect, useRef } from 'react'
import moment from 'moment'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    addDaysToDate,
} from '@/custom/common/components'
import { FormikContext, useFormik } from 'formik'
import * as Yup from 'yup'
import AlertContext from '@/custom/context/alert/alertContext'
import { BarcodeDetector } from 'barcode-detector'
import { Button } from '@material-ui/core'

import Dialog from '@/custom/components/organisms/Dialog'

let randomVariable = Math.random()

const BarCodeScan = (props) => {
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const scanToggle = props.scanToggle
    const barcodeDetector = new BarcodeDetector()
    const [fileUploaded, setFileUploaded] = useState('')

    const fileValidationArray = Yup.object({
        file_path: Yup.array().when('content_type', {
            is: 'file',
            then: Yup.array().required('Required!'),
        }),
    })
    const fileFormik = useFormik({
        initialValues: {
            file_path: [],
        },
        validationSchema: fileValidationArray,
        onSubmit: (values) => {},
    })

    const handleUpload = (data) => {
        setFileUploaded(data)
    }

    const fileUpload = {
        formik: fileFormik,
        data: [
            {
                type: 'uploadDropZone',
                name: 'file_path',
                icon: 'description',
                titleText: 'Upload Image',
                class: `col-12`,
                folder: 'product',
                accept: 'image/*',
                multiple: false,
                getBlobUrl: handleUpload,
                webcam: true,
            },
        ],
    }

    const handleBarcode = async (imageUrl) => {
        try {
            console.log(imageUrl, 'imageUrl')

            let barCodeValue = barcodeDetector.detect(imageUrl).then((res) => {
                return res
            })
            let rawValue = ''
            Promise.resolve(barCodeValue).then((res) => {
                console.log(res, 'resres')
                if (res.length) {
                    rawValue = res[0].rawValue
                    props.setDecodedBarcode(rawValue)
                    props.changeScanDialogStatus()
                    fileFormik.resetForm()
                } else {
                    setAlert('No Barcode Found', 'error')
                }
            })

            console.log(barCodeValue, rawValue, 'barcodedtect')
        } catch (error) {
            console.error('Error detecting barcode:', error)
        }
    }

    const closeFunction = () => {
        fileFormik.resetForm()
        props.changeScanDialogStatus()
    }

    return (
        <Dialog
            title="Scan Label"
            open={scanToggle}
            className="scanDialog"
            function={props.changeScanDialogStatus}
            handleClose={() => {
                props.changeScanDialogStatus()
                fileFormik.handleReset()
            }}
        >
            <div className="scannerBox">
                <div className="row">{mapData(fileUpload)}</div>
                <div className="actionWrapper">
                    <Button id="cancel" onClick={() => closeFunction()}>
                        {'Cancel'}
                    </Button>
                    <PrimaryButton
                        onClick={() => handleBarcode(fileUploaded)}
                        id="confirm_bid_submit"
                        type="button"
                        disabled={fileFormik.isSubmitting}
                        label={' Scan an image File'}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default BarCodeScan
