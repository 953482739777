import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '../../../context/product/productContext'
import AuthContext from '../../../context/auth/authContext'

import './FavoriteCheckbox.css'
import { makeStyles, Tooltip } from '@material-ui/core'

const FavoriteCheckbox = ({
    watchlisted,
    project_id,
    fill,
    addLabel,
    removeLabel,
    className,
    trigger = null,
    follows = '',
    add_watchlist_message,
    remove_watchlist_message,
    icon_type,
}) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext

    const [checked, setChecked] = useState(parseInt(watchlisted) > 0 ? true : false)
    const { addWatchlist, removeWatchlist, addFollower, getFollowers } = productContext

    useEffect(() => {
        setChecked(parseInt(watchlisted) > 0 ? true : false)
        return () => {
            setChecked(false)
        }
    }, [project_id, watchlisted])

    const toggleFavourite = () => {
        if (checked) {
            removeWatchlist({ product_id: project_id }, null, remove_watchlist_message)
            setChecked(false)
        } else {
            if (trigger) trigger({ project_id })
            addWatchlist({ product_id: project_id })
            if (follows != '') {
                addFollower({ follows: follows, rating: 5 })
                setTimeout(() => {
                    getFollowers()
                }, 500)
            }
            setChecked(true)
        }
    }

    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: theme.palette.common.black,
        },
    }))

    function BootstrapTooltip(props) {
        console.log(props, 'asdas')
        const classes = useStylesBootstrap()

        return <Tooltip arrow classes={classes} {...props} />
    }

    return (
        <>
            {isAuthenticated && (
                <BootstrapTooltip title={checked ? 'Remove From Watch List' : 'Add to Watch List'}>
                    <div
                        className={`favoriteCheck d-flex justify-content-center align-items-center ${
                            checked ? 'checked' : ''
                        } ${className || ''}`}
                    >
                        <input
                            id={project_id}
                            type="checkbox"
                            checked={checked}
                            onChange={toggleFavourite}
                        />
                        {checked ? (
                            <label htmlFor={project_id}>
                                <span className="material-icons">
                                    {icon_type === 'visibility' ? 'visibility' : 'favorite'}
                                </span>
                                {removeLabel}
                            </label>
                        ) : (
                            <label htmlFor={project_id}>
                                {fill ? (
                                    <span className="material-icons ntChckd">favorite</span>
                                ) : (
                                    <span
                                        className={
                                            icon_type === 'visibility'
                                                ? 'material-icons-outlined'
                                                : 'material-icons'
                                        }
                                    >
                                        {icon_type === 'visibility'
                                            ? 'visibility'
                                            : 'favorite_border'}
                                    </span>
                                )}
                                {addLabel}
                            </label>
                        )}
                    </div>
                </BootstrapTooltip>
            )}
        </>
    )
}
export default FavoriteCheckbox
