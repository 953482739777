import React, { useContext, useEffect } from 'react'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import UserContext from '@/custom/context/user/userContext'
import { mapData } from '@/custom/common/components'
import { useFormik } from 'formik'
import AlertContext from '@/custom/context/alert/alertContext'
import '../Dashboard.css'

const ChangePasswordForm = (props) => {
    const userContext = useContext(UserContext)
    const { updatePassword, responseStatus: responseStatusUser, clearResponse } = userContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const formikChange = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationPassword,
        onSubmit: (values) => {
            updatePassword(values)
        },
    })
    const formData = {
        formik: formikChange,
        data: props.data,
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updatePassword') {
                setAlert(
                    responseStatusUser.data.statusText,
                    responseStatusUser.data.status ? 'success' : 'error',
                )
                console.log(responseStatusUser, 'responseStatusUser')
                if (responseStatusUser.data.status) {
                    formikChange.resetForm()
                }
            }
            clearResponse()
        }
    }, [responseStatusUser])

    return (
        <div className="accountCnt">
            <form onSubmit={formikChange.handleSubmit} autoComplete="nofill">
                <div className="accChild">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4> {'Change Password'}</h4>
                    </div>
                    <div className="row">{Object.values(mapData(formData))}</div>
                </div>
                <PrimaryButton id="change_password_change" type="submit" label={'change'} />
            </form>
        </div>
    )
}

export default ChangePasswordForm
