import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import AuthState from '@/custom/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from '@/custom/context/product/productState'
import AlertState from '@/custom/context/alert/alertState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from '@/custom/common/alert'
// import i18n from '@/product/common/language-i18n'
import withRoot from './withRoot'
import { create } from 'jss'
import rtl from 'jss-rtl'
import SEO from '@/utils/SEO'
import {
    MuiThemeProvider,
    StylesProvider,
    createMuiTheme,
    jssPreset,
    useTheme,
} from '@material-ui/core/styles'
import HeadAlert from './custom/components/molecules/HeadAlert'
import moment from 'moment'
// import { setIPAddress } from './product/common/api'
import publicIp from 'public-ip'
import UserState from './custom/context/user/userState'
// import { servertimeUpdate } from './custom/common/socket'
// import openSocket from 'socket.io-client'
import StripeCardState from '@/custom/context/stripe/card/cardState'
import StripeCustomerState from './custom/context/stripe/customer/customerState'
import BuyerState from './custom/context/buyer/buyerState'
// let socket = openSocket(`${process.env.REACT_APP_DOMAIN}`, { transports: ['websocket', 'polling'] })

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'US'
global.registerToken = true

const App = () => {
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
    // const customCommonContext = useContext(CustomCommonContext)

    const [currLang, setCurrLang] = useState()
    const theme = useTheme()
    const ltrTheme = createMuiTheme({ direction: 'ltr' })
    const rtlTheme = createMuiTheme({ direction: 'rtl' })
    useEffect(() => {
        // localStorage.removeItem('currLanguage')
        // localStorage.setItem('currLanguage', document.body.dir)
        // setCurrLang(`${localStorage.i18nextLng == 'for_english' ? 'ltr' : 'rtl'}`)
        // setCurrLang(`${localStorage.i18nextLng == 'for_english' ? 'ltr' : 'rtl'}`)
        // console.log(localStorage.currLanguage, localStorage.i18nextLng, currLang, 'i18n.language')

        // localStorage.i18nextLng == 'for_english'
        //     ? (theme.direction = 'ltr')
        //     : (theme.direction = 'rtl')

        setCurrLang(localStorage.currLanguage == 'for_english' ? rtlTheme : ltrTheme)

        // console.log(theme.direction, 'theme.direction')
    }, [localStorage.currLanguage])

    // useEffect(() => {
    //     socket.on('connect', () => {
    //         console.log('Socket Connected')
    //     })
    //     socket.on('sliservertime', (data) => {
    //         console.log(data, 'dataserver')
    //         if (moment(data.dTime).isValid()) {
    //             servertimeUpdate(new Date(data.dTime))
    //         }
    //     })
    //     socket.on('disconnect', (reason) => {
    //         console.log('Socket Disconnected', reason)
    //     })
    // }, [])

    //fetchData function===>To get the ip address
    // useEffect(() => {
    //     async function fetchData() {
    //         const ipaddress = await publicIp.v4()
    //         setIPAddress(ipaddress)
    //     }
    //     fetchData()
    // }, [])

    // useEffect(() => {
    //     localStorage.debug = '*'
    // }, [])

    return (
        <>
            <SEO url={window.location.href} />
            <CookiesProvider>
                <StylesProvider jss={jss}>
                    {/* <I18nextProvider i18n={i18n}> */}
                    <CustomCommonState>
                        {/* <StylesProvider jss={jss}>
                        <MuiThemeProvider
                            theme={createMuiTheme({
                                direction: currLang,
                            })}
                        > */}
                        <ProductCommonState>
                            <AuthState>
                                <BuyerState>
                                    <UserState>
                                        <StripeCardState>
                                            <StripeCustomerState>
                                                <AlertState>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <SnackbarProvider maxSnack={3}>
                                                            <div className="App">
                                                                <Alerts />
                                                                <HeadAlert />
                                                                <Router>
                                                                    <Routes />
                                                                </Router>
                                                            </div>
                                                        </SnackbarProvider>
                                                    </MuiPickersUtilsProvider>
                                                </AlertState>
                                            </StripeCustomerState>
                                        </StripeCardState>
                                    </UserState>
                                </BuyerState>
                            </AuthState>
                        </ProductCommonState>
                        {/* </MuiThemeProvider>
                    </StylesProvider> */}
                    </CustomCommonState>
                    {/* </I18nextProvider> */}
                </StylesProvider>
            </CookiesProvider>
        </>
    )
}

export default withRoot(App)
