import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall, apiCallSeller } from '../../common/api'
import { response } from '../common'

import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_HOME,
    GET_ALL_PRODUCTS,
    GET_LOST,
    GET_WON,
    GET_ALL_BID_HISTORY,
    GET_SIMILAR,
    GET_INVOICE_DETAIL,
    GET_WATCHLIST_PROD,
    GET_DASHBOARD,
    WATCH_COUNT,
    GET_WINNING,
    GET_SAVED_SEARCH,
    GET_PRODUCT_INFO,
    GET_EXPIRED,
    GET_OUTBID,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        responseStatus: null,
        search_allHomeproducts: {
            records: [],
        },
        search_allproducts: {
            records: [],
        },
        search_allbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        similar_products: {
            records: [],
        },
        expired_data: {
            records: [],
        },
        saved_searches: {
            records: [],
        },
        watch_data: {
            records: [],
        },
        watch_count: {
            records: [],
        },
        product_info: {
            records: [],
        },
        won_data: {
            records: [],
        },
        lost_data: {
            records: [],
        },
        invoice_detail: {
            records: [],
        },
        outbid_data: {
            records: [],
        },
        winning_data: {
            records: [],
            from: '',
        },
        dashboard_data: {
            records: [],
            from: '',
        },
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addWatchlist = async (formData, fromVariable, message) => {
        try {
            const from = fromVariable ? fromVariable : 'addWatchlist'
            console.log(formData, 'formData')
            const [res] = await Promise.all([
                apiCall('post', 'addToWatchlist', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'watch')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: message ? message : res?.data?.statusText,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getWatchlistCount = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getWatchlistCount'
        try {
            console.log(formData, 'formData')
            formData.typearr = [1, 2, 3, 4]
            const [res] = await Promise.all([
                apiCall('post', 'getWatchlistCnt', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'watch')
                dispatch({
                    type: WATCH_COUNT,
                    payload: {
                        records: res.data.responseData,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const removeWatchlist = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'removeWatchlist'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeFromWatchlist', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: message ? message : res?.data?.statusText,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllBidHistory = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getBidHistory', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status) {
                console.log(res.data, 'resdatabid')
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.responseData.bids.length
                            ? res.data.responseData.bids
                            : [],
                        from: fromVariable,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getWatchlist = async (formData, fromVariable, message) => {
        try {
            const from = fromVariable ? fromVariable : 'getWatchlist'
            formData.typearr = [1, 2, 3, 4]
            const [res] = await Promise.all([
                apiCall('post', 'getWatchlist', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_WATCHLIST_PROD,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getWonData = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getWon'
        try {
            const [res] = await Promise.all([apiCall('post', 'getWon', formData, '', 'mobileapi')])
            if (res.data.status) {
                dispatch({
                    type: GET_WON,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getLostData = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getLost'
        try {
            const [res] = await Promise.all([apiCall('post', 'getLost', formData, '', 'mobileapi')])
            if (res.data.status) {
                dispatch({
                    type: GET_LOST,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getOutbidData = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getOutbid'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getLosing', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_OUTBID,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getExpiredData = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getExpired'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getExpired', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_EXPIRED,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getWinningData = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getWinning'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getWinning', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_WINNING,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getDashboardData = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getDashboardData'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAllBids', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_DASHBOARD,
                    payload: {
                        records: res.data.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllHomeProducts = async (formData, fromVariable, custom_url = '') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'homeProducts', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_ALL_HOME,
                    payload: {
                        records: res.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSearchProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchProducts', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        records: res.data,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getProductInfo = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getProductInfo', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_PRODUCT_INFO,
                    payload: {
                        records: res.data.responseData,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSimilarProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'similarProducts', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_SIMILAR,
                    payload: {
                        records: res.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const notesUpdate = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'notesUpdate'

            const [res] = await Promise.all([
                apiCall('post', 'notes_update', formData, '', 'project', true),
            ])
            console.log(res.data, 'resdata')
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addSavedSearch = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'addSavedSearch'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addSavedSearch', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'res.data')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addLotNotes = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'addLotNotes'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addLotNotes', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'res.data')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        data: res?.data,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const removeLotNotes = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'removeLotNotes'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeLotNotes', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'res.data')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateLotNotes = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'updateLotNotes'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateLotNotes', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'res.data')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        data: res?.data,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getSavedSearch = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSavedSearch', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_SAVED_SEARCH,
                    payload: {
                        records: res.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getCheckoutInvoiceDetail = async (formData, fromVariable, message) => {
        const from = fromVariable ? fromVariable : 'getCheckoutInvoiceDetail'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCheckoutInvoiceDetail', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_INVOICE_DETAIL,
                    payload: {
                        records: res.data.responseData,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payWithCredits = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'payWithCredits'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'payWithFullUserCredits', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'res.data')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        data: res?.data,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payWithCard = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'payWithCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'payWithCardOrUserCredits', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                console.log(res.data, 'res.data')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'false',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        data: res?.data,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ProductContext.Provider
            value={{
                responseStatus: state.responseStatus,
                watch_data: state.watch_data,
                watch_count: state.watch_count,
                search_allbidhistory: state.search_allbidhistory,
                search_allHomeproducts: state.search_allHomeproducts,
                search_allproducts: state.search_allproducts,
                product_info: state.product_info,
                won_data: state.won_data,
                invoice_detail: state.invoice_detail,
                lost_data: state.lost_data,
                outbid_data: state.outbid_data,
                winning_data: state.winning_data,
                dashboard_data: state.dashboard_data,
                expired_data: state.expired_data,
                saved_searches: state.saved_searches,
                similar_products: state.similar_products,
                getSavedSearch,
                payWithCredits,
                payWithCard,
                getAllHomeProducts,
                addLotNotes,
                removeLotNotes,
                getCheckoutInvoiceDetail,
                updateLotNotes,
                addSavedSearch,
                getAllSimilarProducts,
                getWinningData,
                getDashboardData,
                removeWatchlist,
                getAllBidHistory,
                getOutbidData,
                getAllSearchProducts,
                getProductInfo,
                addWatchlist,
                getWatchlistCount,
                getExpiredData,
                getWatchlist,
                clearResponse,
                getWonData,
                getLostData,
                notesUpdate,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
