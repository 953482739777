import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import CartItem from '@/custom/components/molecules/ProductCard/CartItem'
import './Checkout.css'
import { Button, InputAdornment, ListItem, Tooltip, Divider, Popover } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import AuthContext from '@/custom/context/auth/authContext'
import AlertContext from '@/custom/context/alert/alertContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import StripeCardContext from '@/custom/context/stripe/card/cardContext'
import CurrencyContextFormat from '../../custom/common/currencyFormat'
import CardDetails from '@/custom/components/organisms/CardDetails'
import CheckBox from '@/custom/components/atoms/CheckBox'
import { mapData, handleRedirectInternal, calculatePercentage } from '@/custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {
    currencyFormat,
    dateFormatFrontDay,
    dateTimeFormatFunction,
    dateFormatFrontOnlyDate,
} from '@/custom/common/components'
import Loaders from '@/custom/components/molecules/Loaders'
import SEO from '@/utils/SEO'
import ProductContext from '@/custom/context/product/productContext'
import UserContext from '@/custom/context/user/userContext'

const today = new Date()
const tomorrow = new Date(today.getTime() + 1 * 24 * 3600000)
const twodays = new Date(today.getTime() + 2 * 24 * 3600000)
const threedays = new Date(today.getTime() + 3 * 24 * 3600000)
const oneweek = new Date(threedays.getTime() + 7 * 24 * 3600000)
const appointmentDate = [
    {
        value: dateFormatFrontOnlyDate(today),
        show: dateFormatFrontDay(today),
        label: 'Today',
    },
    {
        value: dateFormatFrontOnlyDate(tomorrow),
        show: dateFormatFrontDay(tomorrow),
        label: 'Tomorrow',
    },
    {
        value: dateFormatFrontOnlyDate(twodays),
        show: dateFormatFrontDay(twodays),
        label: 'In 2 days',
    },
]
//To calculate the processingfees
const getProcessingFees = (totalAmount, totalShipping, processingPercent) => {
    if (processingPercent && totalAmount) {
        let calculatedPercent = calculatePercentage(
            parseFloat(totalAmount) + parseFloat(totalShipping),
            processingPercent,
        )
        return calculatedPercent
    } else {
        return {
            percentAmount: 0,
        }
    }
}

const Checkout = (props) => {
    const productcontext = useContext(ProductContext)
    const {
        getCheckoutInvoiceDetail,
        invoice_detail,
        payWithCredits,
        payWithCard,
        responseStatus,
        clearResponse,
    } = productcontext
    const customCommonContext = useContext(CustomCommonContext)
    const userContext = useContext(UserContext)
    const [applyCredit, setApplyCredit] = useState(false)
    const [noCard, setNoCard] = useState(false)
    const { getUserProfileDetails, user_details } = userContext

    const { allLocations, configFeatures, allConfigurationVariables } = customCommonContext

    const [isLoading, setIsLoading] = useState(true)

    const [pendingPayment, setPendingPayment] = useState([])
    const [validationArray, setValidationArray] = useState('')
    const [user, setUser] = useState({})
    const [userOtherInfo, setUserOtherInfo] = useState({})
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const history = useHistory()
    const { isAuthenticated, loadUser } = authContext

    const [checkoutType, setCheckoutType] = useState(props.match.params.type)
    const [reload, setReload] = useState(false)
    let [termsLink, setTermsLink] = useState([])
    const [dateCalendar, setDateCalendar] = useState(false)
    let [changeCard, setChangeCard] = useState(false)
    const [loaderState, setLoading] = useState(true)
    const [selectedCard, setSelectedCard] = useState(false)
    const [address, setAddress] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const [seletcedAddress, setSelectedAddress] = useState('')
    const [cards, setCards] = useState([])
    const [banks, setBanks] = useState([])
    let [shippingQuote, setShippingQuote] = useState(null)
    let [changeAddress, setChangeAddress] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [deliveryMode, setDeliveryMode] = useState([])
    const [submited, setIsSubmited] = useState(false)

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    let deliveryInternal = [
        { id: 1, description: 'Local Pickup' },
        // { id: 2, description: 'Shipping' },
    ]

    const getCards = async () => {
        // setIsLoading(true)
        // setIsLoading(false)
    }

    const toggleFullScreenPopup = (popup, status, data) => {
        setManage({ popup, status, data })
    }

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    const setUserAddress = () => {
        setAddress(user.address_details.filter((inner) => inner.status === 'active'))
        toggleFullScreenPopup(false, 'new', null)
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (user.address_details) {
                setUserAddress()
            }
        }
    }, [isAuthenticated, user && user.address_details])

    // useEffect(() => {
    //     if (checkout_pending_payment) {
    //         if (checkout_pending_payment.length) {
    //             setPendingPayment(checkout_pending_payment)
    //         }
    //         console.log('checkout_pending_payment', checkout_pending_payment)
    //     }
    // }, [checkout_pending_payment])

    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(StripeCardContext)

    const { setAlert } = alertContext

    let [cartProducts, setCartProducts] = useState([])
    let [allAid, setAllAid] = useState([])
    let [allAmount, setAllAmount] = useState([])
    const [couponData, setCouponData] = useState([{ code: '1234' }, { code: '3456' }])
    const [shippingSelected, setShippingSelected] = useState('')
    let [cartValues, setCartValues] = useState({
        processing_percent: 0,
        total_shipping: 0,
        total_amount: 0,
        total_processed_amount: 0,
        per_total: 0,
        total_tax: 0,
        bidamount_total: 0,
    })

    console.log(`cartValues.final_total `, cartValues)

    let [cartLocation, setCartLocation] = useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    let [allAvailableTime, setAllAvailableTime] = useState([])
    const [showShipping, setShipping] = useState(false)
    console.log(props.match.params.cart_id, 'idddd')

    const [search, setSearch] = useState({
        invoice_id: props.match.params.type === 'invoice' ? props.match.params.cart_id : 0,
    })

    const [dataArray, setDataArray] = useState([])

    const addressUpdate = (id, noAlert = false) => {
        const payload = {
            cart_id: props.match.params.cart_id,
            invoice_address_id: id,
        }
        // setRefresh(!refresh)
    }
    const cartValuesRef = useRef(cartValues)

    useEffect(() => {
        cartValuesRef.current = cartValues
    })

    useEffect(() => {
        if (cartLocation) {
            const weeklyHours =
                cartLocation.weeklyHours && cartLocation.weeklyHours !== 'Closed'
                    ? cartLocation.weeklyHours.split('-')
                    : null

            const saturdayHours =
                cartLocation.saturdayHours && cartLocation.saturdayHours !== 'Closed'
                    ? cartLocation.saturdayHours.split('-')
                    : null

            const sundayHours =
                cartLocation.sundayHours && cartLocation.sundayHours !== 'Closed'
                    ? cartLocation.sundayHours.split('-')
                    : null

            const timeFormat = 'h:m a'
            let weeklyHoursStart = weeklyHours
                ? moment(weeklyHours[0], [timeFormat]).format('HH:mm')
                : 0
            let weeklyHoursEnd = weeklyHours
                ? moment(weeklyHours[1], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursStart = saturdayHours
                ? moment(saturdayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursEnd = saturdayHours
                ? moment(saturdayHours[1], [timeFormat]).format('HH:mm')
                : 0

            let sundayHoursStart = sundayHours
                ? moment(sundayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let sundayHoursEnd = sundayHours
                ? moment(sundayHours[1], [timeFormat]).format('HH:mm')
                : 0

            // 1 - Monday 2 - Tuesday
            setDataArray([
                {
                    date: 0,
                    minTime: sundayHoursStart,
                    maxTime: sundayHoursEnd,
                },
                {
                    date: 1,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 2,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 3,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 4,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 5,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 6,
                    minTime: saturdayHoursStart,
                    maxTime: saturdayHoursEnd,
                },
            ])
        } else {
            setDataArray([
                {
                    date: 0,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 1,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 2,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 3,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 4,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 5,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 6,
                    minTime: 8,
                    maxTime: 18,
                },
            ])
        }
    }, [cartLocation])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getCheckoutInvoiceDetail(search)
            getUserProfileDetails({})
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (user_details && user_details?.records) {
            console.log(user_details, 'user_details')
            setUser(user_details.records.userData)
            setUserOtherInfo(user_details.records?.userOtherInfo)
        }
    }, [user_details])

    useEffect(() => {
        if (
            invoice_detail &&
            invoice_detail.records &&
            Object.keys(invoice_detail.records).length
        ) {
            if (
                parseFloat(invoice_detail.records.final_total) <=
                parseFloat(invoice_detail.records.discountoff)
            ) {
                setApplyCredit(true)
                setNoCard(true)
            } else {
                setNoCard(false)
                if (invoice_detail.records.discountoff != '0.00') {
                    setApplyCredit(true)
                } else {
                    setApplyCredit(false)
                }
            }
            setCartProducts(invoice_detail.records.checkoutReceiptList)
            let allData = invoice_detail.records.checkoutReceiptList
            let aid = []
            let allAmt = []
            if (allData.length) {
                allData.map((data) => {
                    aid.push(data.auctionid)
                    allAmt.push(data.amount)
                })
            }
            setAllAid(aid)
            setAllAmount(allAmt)
            setCartValues(invoice_detail.records)
            setIsLoading(false)
            console.log(invoice_detail.records, 'invoice_detail.records')
        }
    }, [invoice_detail])

    const [addAddress, setAddAddress] = useState(false)

    const toggleModal = () => {
        setAddAddress(!addAddress)
    }

    const validation = Yup.object({
        bill_first_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required'),
        bill_last_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required'),
        bill_address1: Yup.string().required('Required'),
        bill_city: Yup.string().required('Required'),
        bill_state: Yup.string().required('Required'),
        bill_zipcode: Yup.string()
            .min(4, 'Invalid zip code')
            .max(8, 'Invalid zip code')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            invoice_id: search.invoice_id,
            cart_id: search.cart_id,
            bill_first_name: cartValues.bill_first_name,
            bill_last_name: cartValues.bill_last_name,
            bill_address1: cartValues.bill_address1,
            bill_city: cartValues.bill_city,
            bill_state: cartValues.bill_state,
            bill_zipcode: cartValues.bill_zipcode,
        },
        validateOnBlur: false,
        validationSchema: validation,
        onSubmit: (values) => {
            toggleModal()
        },
    })

    const billingAddressInfo = [
        {
            label: 'First name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-12',
            name: 'bill_first_name',
            formik: formik,
        },
        {
            label: 'Last name',
            placeholder: 'Enter your last name',
            class: 'col-12',
            type: 'text',
            name: 'bill_last_name',
            formik: formik,
        },
        {
            label: 'Address',
            placeholder: 'Enter your address',
            class: 'col-12',
            type: 'text',
            name: 'bill_address1',
            formik: formik,
        },
        {
            label: 'City',
            placeholder: 'Enter your city',
            class: 'col-12',
            type: 'text',
            name: 'bill_city',
            formik: formik,
        },
        {
            label: 'State',
            placeholder: 'Enter your state',
            class: 'col-12',
            type: 'select',
            options: [],
            name: 'bill_state',
            formik: formik,
        },
        {
            label: 'Zip code',
            placeholder: 'Enter your Zip code',
            class: 'col-6',
            type: 'number',
            name: 'bill_zipcode',
            formik: formik,
        },
    ]

    const validationLocalPayment = {
        // appointmentID: Yup.string(),
        // no_of_cards: Yup.string().when(['payment_method'], {
        //     is: (a) => a === 'card',
        //     then: Yup.string().required('Required!'),
        // }),
        // pickupDay: Yup.string().when(['appointmentID'], {
        //     is: (a) => !a,
        //     then: Yup.string().required('Required!'),
        // }),
        // pickupTime: Yup.string().when(['appointmentID'], {
        //     is: (a) => !a,
        //     then: Yup.string().required('Required!'),
        // }),
        // bank_account_holder_name: Yup.string('Must be string').when(
        //     ['payment_method', 'no_of_cards'],
        //     {
        //         is: (a, b) => a === 'bank' && b === 'single',
        //         then: Yup.string().required('Required!'),
        //     },
        // ),
        // bank_account_number: Yup.number('Number required')
        //     .positive('Positive only allowed')
        //     .when(['payment_method', 'no_of_cards'], {
        //         is: (a, b) => a === 'bank' && b === 'single',
        //         then: Yup.number('Number required').required('Required!'),
        //     }),
        // bank_account_holder_type: Yup.string().when(['payment_method', 'no_of_cards'], {
        //     is: (a, b) => a === 'bank' && b === 'single',
        //     then: Yup.string().required('Required!'),
        // }),
        // bank_routing_number: Yup.number()
        //     .positive('Positive only allowed')
        //     .when(['payment_method', 'no_of_cards'], {
        //         is: (a, b) => a === 'bank' && b === 'single',
        //         then: Yup.number().required('Required!'),
        //     }),

        // amount: Yup.number()
        //     .min(1, 'Amount should be greater than or equal to one')
        //     .required('Required!'),

        payment_method: Yup.string().when(['coupon', 'credits'], {
            is: (a, b) => a === false && b === false,
            then: Yup.string().required('Required!'),
        }),
        partial: Yup.boolean(),
        amount: Yup.number().when('partial', {
            is: true,
            then: Yup.number()
                .max(
                    cartValues ? cartValues.total_due_amount : 0,
                    `Max. Amount ${
                        cartValues ? currencyFormat(cartValues.total_due_amount) : currencyFormat(0)
                    }`,
                )
                .min(
                    cartValues ? (cartValues.total_due_amount / 100) * 25 : 0,
                    `Min. Amount ${
                        cartValues
                            ? currencyFormat((cartValues.total_due_amount / 100) * 25)
                            : currencyFormat(0)
                    }`,
                )
                .required('Required!'),
        }),
        code: Yup.string().when(['coupon'], {
            is: true,
            then: Yup.string().required('Required!'),
        }),
        card_cvv: Yup.string()
            .min(3, 'Invalid CVV')
            .max(3, 'Invalid CVV')
            .when(['payment_method'], {
                is: (a, b) => a === 'card',
                then: Yup.string().required('Required!'),
            }),
        // termCheck: Yup.boolean().when('partial', {
        //     is: true,
        //     then: Yup.boolean().oneOf([true], 'Please accept terms and condition'),
        // }),
    }

    const validationShippingPayment = {
        payment_method: Yup.string().required('Required!'),
        // address: Yup.string().required('Address Required!'),
        card_cvv: Yup.string()
            .min(3, 'Invalid CVV')
            .max(3, 'Invalid CVV')
            .when(['payment_method'], {
                is: (a, b) => a === 'card',
                then: Yup.string().required('Required!'),
            }),
        // amount: Yup.number()
        //     .min(1, 'Amount should be greater than or equal to one')
        //     .required('Required!'),
    }

    const formikPayment = useFormik({
        initialValues: {
            client_secret: 'required',
            card_profileid: user?.card_profileid,
            source_id: user?.card_paymentid,
            tamount: cartValues.new_final_total_cfee,
            inv: cartValues.inv_num,
            tship: cartValues.shipcost_total,
            tac: 0,
            amount: cartValues.discountoff,
            invoiceno: cartValues.inv_num,
            buyerid: cartValues.user_id,
            foramt: '',
            ssapi_auid: allAid.length ? allAid.toString() : [],
            ssapi_tamt: allAmount.length ? allAmount.toString() : [],
            persalescen: cartValues.persalescen,
            totalInvoice: cartValues.total_invoice,
            salesAmt: cartValues.salestax_total,
            shipAmt: cartValues.total_shipping,
            shipOrg: cartValues.shipcost_total,
            shipCmb: cartValues.combined_shipp,
            buyerPremiumTotal: cartValues.buypremium_total,
            plus_checknew: 0,
            payWithCardFlag: 0,
            applycreditFlag: applyCredit ? 1 : 0,
            invoice_id: search.invoice_id,
            payment_method: 'card',
        },
        validateOnBlur: false,
        validationSchema: Yup.object(validationArray),
        onSubmit: async (values) => {
            values.card_profileid = user?.card_profileid
            values.source_id = user?.card_paymentid
            values.tamount = cartValues.new_final_total_cfee
            values.inv = cartValues.inv_num
            values.tship = cartValues.shipcost_total
            values.amount = cartValues.discountoff
            values.invoiceno = cartValues.inv_num
            values.buyerid = cartValues.user_id
            values.ssapi_auid = allAid.length ? allAid.toString() : []
            values.ssapi_tamt = allAmount.length ? allAmount.toString() : []
            values.persalescen = cartValues.persalescen
            values.totalInvoice = cartValues.total_invoice
            values.salesAmt = cartValues.salestax_total
            values.shipAmt = cartValues.total_shipping
            values.shipOrg = cartValues.shipcost_total
            values.shipCmb = cartValues.combined_shipp
            values.buyerPremiumTotal = cartValues.buypremium_total
            values.applycreditFlag = applyCredit ? 1 : 0
            values.invoice_id = search.invoice_id
            console.log('kkkkkkk', values)
            setIsLoading(true)
            if (selectedCard.id) {
                values.source_id = selectedCard.id
                if (parseFloat(cartValues.final_total) <= parseFloat(cartValues.discountoff)) {
                    values.payWithCardFlag = 1
                    delete values.client_secret
                    delete values.source_id
                    delete values.card_profileid

                    payWithCredits(values)
                } else {
                    values.payWithCardFlag = 0
                    payWithCard(values)
                }
                console.log(values, 'Paymentwithcredits')
            } else {
                if (parseFloat(cartValues.final_total) <= parseFloat(cartValues.discountoff)) {
                    values.payWithCardFlag = 1
                    delete values.client_secret
                    delete values.source_id
                    delete values.card_profileid
                    payWithCredits(values)
                } else {
                    setAlert('Please select card', 'error')
                    setIsLoading(false)
                }
            }
        },
    })

    // const cvvForm = {
    //     formik: formikPayment,
    //     data: [
    //         {
    //             label: 'CVV',
    //             type: 'text',
    //             placeholder: 'Enter your CVV',
    //             class: 'col-12 col-sm-3 mt-2 mw-100',
    //             name: 'card_cvv',
    //             autoFocus: true,
    //         },
    //     ],
    // }

    const requestShipping = (reInitiate, shippingMethod) => {
        //setIsLoading(true)
        let shippingSelectedValue = shippingMethod ? shippingMethod : shippingSelected
        const payload = {
            cart_id: props.match.params.cart_id,
            re_initiate: reInitiate,
        }
        if (shippingSelectedValue) {
            if (shippingSelectedValue === 'arta') {
                if (formikPayment.values.add_insurance) {
                    payload.shipping_changes = {
                        custom_projects_arta_insurance: 'arta_transit_insurance',
                    }
                } else {
                    payload.shipping_changes = {
                        custom_projects_arta_insurance: 'no_arta_insurance',
                    }
                }
            } else if (shippingSelectedValue === 'shipEngine') {
                if (formikPayment.values.add_insurance) {
                    payload.shipping_changes = {
                        custom_projects_engine_insurance: 'shipsurance',
                    }
                } else {
                    payload.shipping_changes = {
                        custom_projects_engine_insurance: 'none',
                    }
                }
            } else if (shippingSelectedValue === 'shipStation') {
                // if (formikPayment.values.add_insurance) {
                //     payload.shipping_changes = {
                //         custom_projects_engine_insurance: 'shipsurance',
                //     }
                // } else {
                //     payload.shipping_changes = {
                //         custom_projects_engine_insurance: 'none',
                //     }
                // }
            }
        }
    }

    useEffect(() => {
        if (shippingSelected) {
            requestShipping(false)
        }
    }, [shippingSelected])

    let paymentOptions = [{ value: 'card', show: 'Card' }]

    let checkout_offline = false
    let checkout_online = false
    let checkout_wallet = false
    let checkout_partial = false

    // if (checkout_offline) {
    //     paymentOptions.push({ value: 'offline', show: 'Offline' })
    // }

    // paymentOptions.push({ value: 'bank', show: 'Bank' })
    // paymentOptions.push({ value: 'wallet', show: 'wallet' })

    const modeOfPayment = [
        {
            label: 'Select mode of payment',
            placeholder: 'Select mode of payment',
            type: 'select',
            class: 'col-12',
            options: paymentOptions,
            name: 'payment_method',
            disabled: true,
            formik: formikPayment,
        },
    ]
    //check for shipping or local pickup

    //update delivery mode
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from == 'payWithCard' || responseStatus.from == 'payWithCredits') {
                setIsLoading(false)
                const message = responseStatus.status
                    ? responseStatus.data.statusText
                    : responseStatus.message
                setAlert(message, responseStatus.status ? 'success' : 'error')
                if (responseStatus.status) {
                    handleRedirectInternal(history, 'invoices/partial')
                }
                clearResponse()
            }
        }
    }, [responseStatus])

    function intervals(startString, endString) {
        const intervalMinutes = 180
        var start = moment(startString, 'YYYY-MM-DD HH:mm')
        var end = moment(endString, 'YYYY-MM-DD HH:mm')
        start.minutes(Math.ceil(start.minutes() / intervalMinutes) * intervalMinutes)
        var result = []
        var current = moment(start)
        while (current < end) {
            result.push({
                value: moment(current).format('HH:mm'),
                show: `${current.format('h:mm a')}`,
            })
            current.add(intervalMinutes, 'minutes')
        }
        return result
    }

    useEffect(() => {
        const selectedDate = moment(formikPayment.values.pickupDay, 'MM-DD-YYYY').format()
        let weekDate = parseInt(moment(selectedDate).format('d'))
        let selectedWeekDay = dataArray.filter((x) => x.date === weekDate)
        if (weekDate !== null) {
            if (selectedWeekDay.length > 0) {
                if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
                    let dateSelected = moment(formikPayment.values.pickupDay, 'MM-DD-YYYY').format(
                        'YYYY-MM-DD',
                    )
                    let timeMinimum = moment(selectedWeekDay[0].minTime, 'HH:mm').format('HH:mm')
                    let timeMaximum = moment(selectedWeekDay[0].maxTime, 'HH:mm').format('HH:mm')
                    timeMinimum = moment(dateSelected + ' ' + timeMinimum).format()
                    timeMaximum = moment(dateSelected + ' ' + timeMaximum).format()
                    const changeData = intervals(timeMinimum, timeMaximum)
                    setAllAvailableTime(changeData)
                } else {
                    setAllAvailableTime([{ value: '', show: 'Closed no slots available' }])
                }
            } else {
                setAllAvailableTime([{ value: '', show: 'Closed no slots available' }])
            }
        } else {
            setAllAvailableTime([{ value: '', show: `Select a time` }])
        }
    }, [formikPayment.values.pickupDay])

    const confirmAppointment = (data) => {
        formikPayment.setFieldValue('appointmentID', data.id)
        formikPayment.setFieldValue('appointmentTime', data.appointmentTime)
    }
    const removeAppointment = () => {
        formikPayment.setFieldValue('appointmentID', '')
        formikPayment.setFieldValue('appointmentTime', '')
    }
    const handleLink = () => {
        if (props.match.params.type === 'buynow') {
            handleRedirectInternal(history, 'buynowcart')
        } else {
            handleRedirectInternal(history, 'cart')
        }
    }
    const handleSelect = (object) => {
        setSelectedCard(object)
        console.log('handleSelect ', object)
    }
    const getStripeCards = () => {
        getAllStripeCards({
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.card_profileid : '',
            object: 'card',
        })
        toggleFullScreenCardPopup(false, 'new', null)
    }
    useEffect(() => {
        if (isAuthenticated) {
            if (user && user.stripe_id) {
                // getStripeCards()
                getCards()
            }
        }
    }, [isAuthenticated, user])

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setManageBank({ popup, status, data })
    }

    useEffect(() => {
        if (address.length > 0) {
            formikPayment.values.address = address.filter((inner) => inner.is_primary === 1)[0]?.id
            addressUpdate(formikPayment.values.address, true)
        }
    }, [address])

    useEffect(() => {
        if (responseStatusCard) {
            console.log(responseStatusCard, 'responseStatusCardfgtygy')
            if (
                responseStatusCard.from === 'card_source_create' ||
                responseStatusCard.from === 'card_source_update' ||
                responseStatusCard.from === 'card_source_delete'
            ) {
                if (responseStatusCard.status === 'success') {
                    // setAlert(responseStatusCard.message, 'success')
                    getStripeCards()
                } else {
                    // setAlert(responseStatusCard.message, 'error')
                }
            }
            setIsLoading(false)
        }
        return () => {
            clearResponseCard()
        }
    }, [responseStatusCard])

    useEffect(() => {
        if (
            get_all_card_details &&
            get_all_card_details.records.length
            // search_singlecart?.cartvalues?.total_amount <= configVariables?.stripe_ach_limit
        ) {
            if (formikPayment.values.cards === '') handleSelect(get_all_card_details.records[0])
            setCards(get_all_card_details.records)
            formikPayment.setFieldValue('payment_ways', 'both')
        }

        return () => {
            setCards([])
        }
    }, [get_all_card_details])
    useEffect(() => {}, [formik.values.payment_method])

    const addressChnageHandler = (payload) => {
        setSelectedAddress(payload)
    }
    return (
        <>
            <SEO title="Checkout" url={window.location.href} />
            <div className="checkout customContainer">
                {isLoading ? (
                    <Loaders name="checkout" isLoading={isLoading} loop={1} />
                ) : (
                    <>
                        {formikPayment.values.delivery_mode == 1 &&
                            availAppoints.length > 0 &&
                            !formikPayment.values.appointmentID && (
                                <>
                                    {availAppoints.map((data, index) => (
                                        <>
                                            <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                                <span className="material-icons">{'Restore'}</span>
                                                <div>
                                                    <h2>
                                                        {'You have an appointment schedule for'}{' '}
                                                        {dateTimeFormatFunction(
                                                            data.appointmentTime,
                                                        )}
                                                        .
                                                    </h2>
                                                    <h4>
                                                        {
                                                            'You can add items to the same appointment'
                                                        }
                                                    </h4>
                                                </div>
                                                <PrimaryButton
                                                    id="confirmAppointment"
                                                    label={'Continue'}
                                                    onClick={() => confirmAppointment(data)}
                                                    btnSize="small"
                                                />
                                            </div>
                                        </>
                                    ))}
                                </>
                            )}

                        <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs mb-2">
                            <Link onClick={() => history.goBack()}>{'Back'}</Link>
                            <Typography color="textPrimary">{'Checkout'}</Typography>
                        </Breadcrumbs>
                        <form onSubmit={formikPayment.handleSubmit} autoComplete="nofill">
                            <div className="checkoutCnt d-flex justify-content-between">
                                <div className="checkoutLt">
                                    <div className="review mt-4">
                                        <div
                                            className="d-flex align-items-center justify-content-start"
                                            style={{ gap: '10px' }}
                                        >
                                            <div className="d-flex align-items-center mb-3">
                                                <h1 className="checkoutHead m-0">
                                                    {'Review your order :'}
                                                </h1>
                                                <span>
                                                    {cartProducts.length} {'Items'}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <h1 className="checkoutHead m-0">
                                                    {'Receipt Number :'}
                                                </h1>
                                                <span>{cartValues.inv_num}</span>
                                            </div>
                                        </div>
                                        {cartProducts.length ? (
                                            <div className="cartProducts">
                                                <div>
                                                    {cartProducts.map((data, index) => (
                                                        <CartItem
                                                            key={index}
                                                            data={data}
                                                            from={'checkout'}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* <div className="pickupAddress">
                                    <h1 className="checkoutHead m-0">{'Address for pickup'}</h1>
                                    <h6>
                                        {cartLocation && (
                                            <>
                                                {cartLocation.name} {cartLocation.address}{' '}
                                                {cartLocation.city} {cartLocation.state}{' '}
                                                {cartLocation.zipCode}
                                            </>
                                        )}
                                    </h6>
                                    <h5>
                                        {'Please bring along your emirates id for verification'}
                                    </h5>
                                </div> */}
                                    {formikPayment.values.delivery_mode == 2 ? (
                                        <div className="billingInfo">
                                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                                <h1 className="checkoutHead m-0 mt-2">
                                                    Shipping Address
                                                </h1>
                                                <div className="errorMsg">
                                                    {formikPayment.touched.address &&
                                                        formikPayment.errors.address}
                                                </div>
                                                {address.length
                                                    ? !changeAddress && (
                                                          <Button
                                                              id="add_addresss"
                                                              className="checkoutEditBtn"
                                                              onClick={() =>
                                                                  setChangeAddress(!changeAddress)
                                                              }
                                                          >
                                                              Change
                                                          </Button>
                                                      )
                                                    : null}
                                            </div>
                                            <div className="savedAddress">
                                                <Button
                                                    id="checkoutPage_new_address"
                                                    className="savedAddressCard addNew"
                                                    onClick={() =>
                                                        toggleFullScreenPopup(true, 'new', null)
                                                    }
                                                >
                                                    <span className="material-icons mr-1">add</span>{' '}
                                                    Add new address
                                                </Button>

                                                {address &&
                                                    address.map((data, index) =>
                                                        changeAddress ? (
                                                            <div className="" key={'cart_' + index}>
                                                                <label
                                                                    className="w-100"
                                                                    htmlFor={`schedule_${index}`}
                                                                >
                                                                    <ListItem
                                                                        button
                                                                        onClick={() => {
                                                                            setChangeAddress(false)
                                                                            formikPayment.setFieldValue(
                                                                                'address',
                                                                                data.id,
                                                                            )
                                                                            setRefresh(!refresh)
                                                                        }}
                                                                        className={`p-0 ${
                                                                            parseInt(
                                                                                data.id,
                                                                                10,
                                                                            ) ===
                                                                            parseInt(
                                                                                formikPayment.values
                                                                                    .address,
                                                                                10,
                                                                            )
                                                                                ? 'checked'
                                                                                : 'unchecked'
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className="savedAddressCard"
                                                                            key={index}
                                                                        >
                                                                            <div>
                                                                                {data.is_primary ==
                                                                                    1 && (
                                                                                    <div className="dfltCard">
                                                                                        Default
                                                                                    </div>
                                                                                )}
                                                                                <h6 className="addrLabel">
                                                                                    Name
                                                                                </h6>
                                                                                <h4>
                                                                                    {data.first_name +
                                                                                        ' ' +
                                                                                        data.last_name}
                                                                                </h4>
                                                                                <h6 className="addrLabel mt-3">
                                                                                    Address
                                                                                </h6>
                                                                                <address>
                                                                                    {data.address1}
                                                                                    <br />
                                                                                    {data.address2}
                                                                                    <div>
                                                                                        <span>
                                                                                            {
                                                                                                data.city
                                                                                            }
                                                                                            ,
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                data.state
                                                                                            }
                                                                                            ,
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                data.zip
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </address>
                                                                            </div>
                                                                        </div>
                                                                    </ListItem>
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            parseInt(data.id, 10) ===
                                                                parseInt(
                                                                    formikPayment.values.address,
                                                                    10,
                                                                ) && (
                                                                <div className="savedAddressCard">
                                                                    <h6 className="addrLabel">
                                                                        Name
                                                                    </h6>
                                                                    {data.is_primary == 1 && (
                                                                        <div className="dfltCard">
                                                                            Default
                                                                        </div>
                                                                    )}
                                                                    <h4>
                                                                        {data.first_name +
                                                                            ' ' +
                                                                            data.last_name}
                                                                    </h4>
                                                                    <h6 className="addrLabel mt-3">
                                                                        Address
                                                                    </h6>

                                                                    <address>
                                                                        {data.address1} <br />{' '}
                                                                        {data.address2}
                                                                        <div>
                                                                            <span>
                                                                                {data.city},
                                                                            </span>{' '}
                                                                            <span>
                                                                                {data.state},
                                                                            </span>
                                                                            <span>{data.zip}</span>
                                                                        </div>
                                                                    </address>
                                                                </div>
                                                            )
                                                        ),
                                                    )}
                                            </div>
                                            {/* {changeAddress && (
                                           <>
                                               <Button
                                                   onClick={() => toggleFullScreenPopup(true, 'new', null)}
                                                   className="checkoutAddBtn"
                                               >
                                                   <span className="material-icons">add</span>
                                                   Add Shipping Address
                                               </Button>
                                           </>
                                       )} */}
                                        </div>
                                    ) : null}
                                    {/* {formikPayment.values.delivery_mode == 1 ? (
                                        <>
                                            {formikPayment.values.appointmentID ? (
                                                <>
                                                    <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                                        <span className="material-icons">
                                                            {'Restore'}
                                                        </span>
                                                        <div>
                                                            <h2>
                                                                You have an selected your previous
                                                                appointment scheduled at
                                                                {dateTimeFormatFunction(
                                                                    formikPayment.values
                                                                        .appointmentTime,
                                                                )}
                                                                .
                                                            </h2>
                                                            <h4>
                                                                if you want to remove the previous
                                                                appointment
                                                            </h4>
                                                        </div>
                                                        <PrimaryButton
                                                            id="removeAppointment"
                                                            label={'remove'}
                                                            onClick={() => removeAppointment()}
                                                            btnSize="small"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="schedule">
                                                    <h1 className="checkoutHead">
                                                        {'Select a pickup date' + ':'}
                                                    </h1>
                                                    <div className="chCnt d-flex justify-content-start align-items-center">
                                                        {appointmentDate.map((data, index) => (
                                                            <div
                                                                className="selectDate d-flex justify-content-center align-items-center"
                                                                key={index}
                                                            >
                                                                <input
                                                                    name="pickupDay"
                                                                    value={data.value}
                                                                    onChange={
                                                                        formikPayment.handleChange
                                                                    }
                                                                    type="radio"
                                                                    id={`schedule_${index}`}
                                                                    hidden
                                                                ></input>
                                                                <label
                                                                    htmlFor={`schedule_${index}`}
                                                                >
                                                                    <ListItem button>
                                                                        <p>{data.show}</p>
                                                                        <span>{data.label}</span>
                                                                    </ListItem>
                                                                </label>
                                                            </div>
                                                        ))}
                                                        <div className="selectDate d-flex justify-content-center align-items-center">
                                                            <input
                                                                name="pickupDay"
                                                                checked={
                                                                    formikPayment?.values
                                                                        ?.pickupDay >
                                                                        dateFormatFrontOnlyDate(
                                                                            threedays,
                                                                        ) &&
                                                                    formikPayment?.values
                                                                        ?.pickupDay != ''
                                                                        ? true
                                                                        : false
                                                                }
                                                                type="radio"
                                                                id={`schedule_${appointmentDate.length}`}
                                                                hidden
                                                            ></input>
                                                            <label>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        setDateCalendar(
                                                                            !dateCalendar,
                                                                        )
                                                                    }
                                                                >
                                                                    <p>
                                                                        {formikPayment?.values
                                                                            ?.pickupDay >
                                                                        dateFormatFrontOnlyDate(
                                                                            threedays,
                                                                        )
                                                                            ? formikPayment.values
                                                                                  .pickupDay
                                                                            : dateFormatFrontOnlyDate(
                                                                                  threedays,
                                                                              )}
                                                                    </p>
                                                                    <span>Custom date</span>
                                                                </ListItem>
                                                            </label>

                                                            {mapData([
                                                                {
                                                                    label: 'Custom date',
                                                                    placeholder: 'Appointment',
                                                                    disabled: false,
                                                                    disablePast: true,
                                                                    open: dateCalendar,
                                                                    onClose: () =>
                                                                        setDateCalendar(false),
                                                                    class:
                                                                        'col-md-4 col-sm-6 col-12 d-none',
                                                                    type: 'dateWithCustomPicker',
                                                                    name: 'pickupDay',
                                                                    minDate: dateFormatFrontOnlyDate(
                                                                        threedays,
                                                                    ),
                                                                    minDateMessage: `${'Date should be greater than the current date'} - ${dateTimeFormatFunction(
                                                                        threedays,
                                                                    )}`,
                                                                    maxDate: dateFormatFrontOnlyDate(
                                                                        oneweek,
                                                                    ),
                                                                    maxDateMessage: `${'Date should not be more than 7 days'} - ${dateTimeFormatFunction(
                                                                        oneweek,
                                                                    )}`,
                                                                    formik: formikPayment,
                                                                },
                                                            ])}
                                                        </div>
                                                    </div>

                                                    <p className="scheduleError">
                                                        {formikPayment.errors.pickupDay &&
                                                            formikPayment.touched.pickupDay &&
                                                            formikPayment.errors.pickupDay}
                                                    </p>

                                                    {formikPayment.values.pickupDay && (
                                                        <>
                                                            <h1 className="checkoutHead">
                                                                {'Select a pickup time' + ':'}
                                                            </h1>

                                                            <div className="chCnt timeSlots d-flex justify-content-start align-items-center flex-wrap">
                                                                {allAvailableTime
                                                                    .filter((availableTimeUnit) =>
                                                                        formikPayment.values
                                                                            .pickupDay <=
                                                                        moment().format(
                                                                            'MM-DD-YYYY',
                                                                        )
                                                                            ? moment(
                                                                                  availableTimeUnit.value,
                                                                                  'HH:mm',
                                                                              )
                                                                                  .format('HH:mm')
                                                                                  .valueOf() >=
                                                                              moment()
                                                                                  .format('HH:mm')
                                                                                  .valueOf()
                                                                            : availableTimeUnit.value,
                                                                    )
                                                                    .map((data, index) => (
                                                                        <div
                                                                            className="selectDate d-flex justify-content-center align-items-center"
                                                                            key={index}
                                                                        >
                                                                            <input
                                                                                name="pickupTime"
                                                                                value={data.value}
                                                                                onChange={
                                                                                    formikPayment.handleChange
                                                                                }
                                                                                type="radio"
                                                                                id={`scheduleTime_${index}`}
                                                                                hidden
                                                                            ></input>
                                                                            <label
                                                                                htmlFor={`scheduleTime_${index}`}
                                                                            >
                                                                                <ListItem button>
                                                                                    <p>
                                                                                        {data.show}
                                                                                    </p>
                                                                                </ListItem>
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                            <p className="scheduleError">
                                                                {formikPayment.errors.pickupTime &&
                                                                    formikPayment.touched
                                                                        .pickupTime &&
                                                                    formikPayment.errors.pickupTime}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ) : null} */}
                                </div>

                                <div className="checkoutRt">
                                    <div className="orderSummary">
                                        <div>
                                            <h3>Receipt Summary</h3>
                                            <p>
                                                <label>
                                                    Bid Amount ({cartProducts.length} Items):
                                                </label>
                                                <span>{cartValues.bidamount_total}</span>
                                            </p>
                                            <p>
                                                <label>Buyers Premium:</label>
                                                <span>
                                                    {currencyFormat(cartValues.buypremium_total)}
                                                </span>
                                            </p>
                                            <p>
                                                <label>Total: </label>
                                                <span>
                                                    {currencyFormat(
                                                        cartValues.subTotalWithoutDiscount,
                                                    )}
                                                </span>
                                            </p>
                                            <p>
                                                <label>Coupons / Credits:</label>
                                                <span>
                                                    {applyCredit
                                                        ? parseFloat(cartValues.final_total) <=
                                                          parseFloat(cartValues.discountoff)
                                                            ? `-${currencyFormat(
                                                                  cartValues.total_invoice,
                                                              )}`
                                                            : `-${currencyFormat(
                                                                  cartValues.discountoff,
                                                              )}`
                                                        : '-$ 0.00'}
                                                </span>
                                            </p>
                                            <p>
                                                <label>Sub-Total</label>
                                                <span>
                                                    {applyCredit
                                                        ? parseFloat(cartValues.final_total) <=
                                                          parseFloat(cartValues.discountoff)
                                                            ? '$0.00'
                                                            : currencyFormat(
                                                                  cartValues.subTotalWithDiscount,
                                                              )
                                                        : currencyFormat(
                                                              cartValues.subTotalWithoutDiscount,
                                                          )}
                                                </span>
                                            </p>
                                            <p>
                                                <label>Sales Tax</label>
                                                <span>
                                                    {applyCredit
                                                        ? currencyFormat(
                                                              cartValues.salesTaxWithCredit,
                                                          )
                                                        : currencyFormat(cartValues.salestax_total)}
                                                </span>
                                            </p>
                                            <p>
                                                <label>Total Due</label>
                                                <span>
                                                    {applyCredit
                                                        ? parseFloat(cartValues.final_total) <=
                                                          parseFloat(cartValues.discountoff)
                                                            ? '$0.00'
                                                            : currencyFormat(
                                                                  cartValues.finalAmountWithCredit,
                                                              )
                                                        : currencyFormat(cartValues.final_total)}
                                                </span>
                                            </p>

                                            {/* <div className="row">{mapData(modeOfPayment)}</div> */}
                                            <div className="billingInfo">
                                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                                    {!changeCard && (
                                                        <>
                                                            {/* <h1 className="checkoutHead m-0 mt-2">
                                                            PAYMENT METHOD
                                                        </h1> */}

                                                            {noCard ? (
                                                                !applyCredit ? (
                                                                    <>
                                                                        <h4 className="checkoutHead m-0 mt-2">
                                                                            Card Details
                                                                        </h4>
                                                                        <Button
                                                                            id="checkoutPage_show"
                                                                            onClick={() =>
                                                                                setChangeCard(
                                                                                    !changeCard,
                                                                                )
                                                                            }
                                                                        >
                                                                            Show
                                                                        </Button>
                                                                    </>
                                                                ) : null
                                                            ) : (
                                                                <>
                                                                    <h4 className="checkoutHead m-0 mt-2">
                                                                        Card Details
                                                                    </h4>
                                                                    <Button
                                                                        id="checkoutPage_show"
                                                                        onClick={() =>
                                                                            setChangeCard(
                                                                                !changeCard,
                                                                            )
                                                                        }
                                                                    >
                                                                        Show
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {noCard ? (
                                                    !applyCredit ? (
                                                        <>
                                                            {changeCard && (
                                                                <>
                                                                    {formikPayment.values
                                                                        .payment_method ===
                                                                        'card' && (
                                                                        <>
                                                                            <div className="chCnt d-flex justify-content-start align-items-center flex-wrap">
                                                                                {cards &&
                                                                                    cards.map(
                                                                                        (
                                                                                            data,
                                                                                            index,
                                                                                        ) => (
                                                                                            // eslint-disable-next-line react/jsx-key
                                                                                            <div className="checkoutSavedCard w-100  d-flex justify-content-start align-items-center">
                                                                                                <input
                                                                                                    name="cards"
                                                                                                    value={
                                                                                                        data.id
                                                                                                    }
                                                                                                    onChange={() => {
                                                                                                        setChangeCard(
                                                                                                            false,
                                                                                                        )
                                                                                                        handleSelect(
                                                                                                            data,
                                                                                                        )
                                                                                                    }}
                                                                                                    type="radio"
                                                                                                    id={`card_${index}`}
                                                                                                    hidden
                                                                                                ></input>
                                                                                                <label
                                                                                                    htmlFor={`card_${index}`}
                                                                                                    className="w-100 m-0"
                                                                                                >
                                                                                                    <ListItem
                                                                                                        className="CardList"
                                                                                                        button
                                                                                                    >
                                                                                                        {data.id ===
                                                                                                        formikPayment
                                                                                                            .values
                                                                                                            .cards ? (
                                                                                                            <span className="material-icons">
                                                                                                                radio_button_checked
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span className="material-icons">
                                                                                                                radio_button_unchecked
                                                                                                            </span>
                                                                                                        )}

                                                                                                        <div
                                                                                                            className="billingSavedCard "
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="cardDetails">
                                                                                                                <h6>
                                                                                                                    {
                                                                                                                        data.brand
                                                                                                                    }{' '}
                                                                                                                    ending
                                                                                                                    in{' '}
                                                                                                                    {
                                                                                                                        data.last4
                                                                                                                    }
                                                                                                                </h6>
                                                                                                                <h6>
                                                                                                                    EXP.{' '}
                                                                                                                    {
                                                                                                                        data.exp_month
                                                                                                                    }

                                                                                                                    /
                                                                                                                    {
                                                                                                                        data.exp_year
                                                                                                                    }
                                                                                                                </h6>
                                                                                                            </div>
                                                                                                            <div className="actionBtn">
                                                                                                                <Button
                                                                                                                    id="checkoutPage_update"
                                                                                                                    onClick={() =>
                                                                                                                        toggleFullScreenCardPopup(
                                                                                                                            true,
                                                                                                                            'update',
                                                                                                                            data,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    Edit
                                                                                                                </Button>
                                                                                                                <Button
                                                                                                                    id="checkoutPage_delete"
                                                                                                                    onClick={() =>
                                                                                                                        toggleFullScreenCardPopup(
                                                                                                                            false,
                                                                                                                            'delete',
                                                                                                                            data,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    Delete
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </ListItem>
                                                                                                </label>
                                                                                            </div>
                                                                                        ),
                                                                                    )}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    <>
                                                                        {formikPayment.values
                                                                            .payment_method ===
                                                                            'bank' && (
                                                                            <div className="">
                                                                                {banks &&
                                                                                    banks.map(
                                                                                        (
                                                                                            data,
                                                                                            index,
                                                                                        ) => (
                                                                                            // eslint-disable-next-line react/jsx-key
                                                                                            <div className="selectAddress  d-flex justify-content-start align-items-center">
                                                                                                <input
                                                                                                    name="cards"
                                                                                                    value={
                                                                                                        data.id
                                                                                                    }
                                                                                                    onChange={() => {
                                                                                                        setChangeCard(
                                                                                                            false,
                                                                                                        )
                                                                                                        handleSelect(
                                                                                                            data,
                                                                                                        )
                                                                                                    }}
                                                                                                    type="radio"
                                                                                                    id={`bank_${index}`}
                                                                                                    hidden
                                                                                                ></input>
                                                                                                <label
                                                                                                    className="w-100"
                                                                                                    htmlFor={`bank_${index}`}
                                                                                                >
                                                                                                    <ListItem
                                                                                                        button
                                                                                                        className="CardList"
                                                                                                    >
                                                                                                        {data.id ===
                                                                                                        formikPayment
                                                                                                            .values
                                                                                                            .cards ? (
                                                                                                            <span className="material-icons">
                                                                                                                radio_button_checked
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span className="material-icons">
                                                                                                                radio_button_unchecked
                                                                                                            </span>
                                                                                                        )}
                                                                                                        <div
                                                                                                            className="savedAddressCard bank"
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="cardDetails">
                                                                                                                <h6>
                                                                                                                    {
                                                                                                                        data.bank_name
                                                                                                                    }
                                                                                                                </h6>
                                                                                                                <h6>
                                                                                                                    {
                                                                                                                        data.last4
                                                                                                                    }

                                                                                                                    /
                                                                                                                    {
                                                                                                                        data.routing_number
                                                                                                                    }
                                                                                                                </h6>
                                                                                                            </div>
                                                                                                            {data.status ===
                                                                                                            'new' ? (
                                                                                                                <Button
                                                                                                                    id="checkoutPage_verify"
                                                                                                                    onClick={() => {
                                                                                                                        toggleFullScreenBankPopup(
                                                                                                                            true,
                                                                                                                            'verify',
                                                                                                                            data,
                                                                                                                        )
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {' '}
                                                                                                                    Verify
                                                                                                                </Button>
                                                                                                            ) : null}
                                                                                                            <Button
                                                                                                                id="checkoutPage_update"
                                                                                                                onClick={() =>
                                                                                                                    toggleFullScreenBankPopup(
                                                                                                                        true,
                                                                                                                        'update',
                                                                                                                        data,
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                Edit
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                id="checkoutPage_deleteData"
                                                                                                                onClick={() =>
                                                                                                                    toggleFullScreenBankPopup(
                                                                                                                        true,
                                                                                                                        'delete',
                                                                                                                        data,
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                Delete
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </ListItem>
                                                                                                </label>
                                                                                            </div>
                                                                                        ),
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                    {/* <Divider /> */}

                                                                    <>
                                                                        {formikPayment.values
                                                                            .payment_method ===
                                                                            'card' && (
                                                                            <Button
                                                                                id="checkoutPage_add_card"
                                                                                onClick={() => {
                                                                                    toggleFullScreenCardPopup(
                                                                                        true,
                                                                                        'new',
                                                                                        null,
                                                                                    )
                                                                                }}
                                                                                className="checkoutAddBtn "
                                                                            >
                                                                                <span className="material-icons">
                                                                                    add
                                                                                </span>
                                                                                Add New Card
                                                                            </Button>
                                                                        )}
                                                                        {formikPayment.values
                                                                            .payment_method ===
                                                                            'bank' && (
                                                                            <Button
                                                                                id="checkoutPage_add_bank"
                                                                                onClick={() => {
                                                                                    toggleFullScreenBankPopup(
                                                                                        true,
                                                                                        'new',
                                                                                        null,
                                                                                    )
                                                                                }}
                                                                                className="checkoutAddBtn"
                                                                            >
                                                                                <span className="material-icons">
                                                                                    add
                                                                                </span>
                                                                                Add New Bank
                                                                            </Button>
                                                                        )}
                                                                    </>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null
                                                ) : (
                                                    <>
                                                        {changeCard && (
                                                            <>
                                                                {formikPayment.values
                                                                    .payment_method === 'card' && (
                                                                    <>
                                                                        <div className="chCnt d-flex justify-content-start align-items-center flex-wrap">
                                                                            {cards &&
                                                                                cards.map(
                                                                                    (
                                                                                        data,
                                                                                        index,
                                                                                    ) => (
                                                                                        // eslint-disable-next-line react/jsx-key
                                                                                        <div className="checkoutSavedCard w-100  d-flex justify-content-start align-items-center">
                                                                                            <input
                                                                                                name="cards"
                                                                                                value={
                                                                                                    data.id
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    setChangeCard(
                                                                                                        false,
                                                                                                    )
                                                                                                    handleSelect(
                                                                                                        data,
                                                                                                    )
                                                                                                }}
                                                                                                type="radio"
                                                                                                id={`card_${index}`}
                                                                                                hidden
                                                                                            ></input>
                                                                                            <label
                                                                                                htmlFor={`card_${index}`}
                                                                                                className="w-100 m-0"
                                                                                            >
                                                                                                <ListItem
                                                                                                    className="CardList"
                                                                                                    button
                                                                                                >
                                                                                                    {data.id ===
                                                                                                    formikPayment
                                                                                                        .values
                                                                                                        .cards ? (
                                                                                                        <span className="material-icons">
                                                                                                            radio_button_checked
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span className="material-icons">
                                                                                                            radio_button_unchecked
                                                                                                        </span>
                                                                                                    )}

                                                                                                    <div
                                                                                                        className="billingSavedCard "
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="cardDetails">
                                                                                                            <h6>
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }{' '}
                                                                                                                ending
                                                                                                                in{' '}
                                                                                                                {
                                                                                                                    data.last4
                                                                                                                }
                                                                                                            </h6>
                                                                                                            <h6>
                                                                                                                EXP.{' '}
                                                                                                                {
                                                                                                                    data.exp_month
                                                                                                                }

                                                                                                                /
                                                                                                                {
                                                                                                                    data.exp_year
                                                                                                                }
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                        <div className="actionBtn">
                                                                                                            <Button
                                                                                                                id="checkoutPage_update"
                                                                                                                onClick={() =>
                                                                                                                    toggleFullScreenCardPopup(
                                                                                                                        true,
                                                                                                                        'update',
                                                                                                                        data,
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                Edit
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                id="checkoutPage_delete"
                                                                                                                onClick={() =>
                                                                                                                    toggleFullScreenCardPopup(
                                                                                                                        false,
                                                                                                                        'delete',
                                                                                                                        data,
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                Delete
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ListItem>
                                                                                            </label>
                                                                                        </div>
                                                                                    ),
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <>
                                                                    {formikPayment.values
                                                                        .payment_method ===
                                                                        'bank' && (
                                                                        <div className="">
                                                                            {banks &&
                                                                                banks.map(
                                                                                    (
                                                                                        data,
                                                                                        index,
                                                                                    ) => (
                                                                                        // eslint-disable-next-line react/jsx-key
                                                                                        <div className="selectAddress  d-flex justify-content-start align-items-center">
                                                                                            <input
                                                                                                name="cards"
                                                                                                value={
                                                                                                    data.id
                                                                                                }
                                                                                                onChange={() => {
                                                                                                    setChangeCard(
                                                                                                        false,
                                                                                                    )
                                                                                                    handleSelect(
                                                                                                        data,
                                                                                                    )
                                                                                                }}
                                                                                                type="radio"
                                                                                                id={`bank_${index}`}
                                                                                                hidden
                                                                                            ></input>
                                                                                            <label
                                                                                                className="w-100"
                                                                                                htmlFor={`bank_${index}`}
                                                                                            >
                                                                                                <ListItem
                                                                                                    button
                                                                                                    className="CardList"
                                                                                                >
                                                                                                    {data.id ===
                                                                                                    formikPayment
                                                                                                        .values
                                                                                                        .cards ? (
                                                                                                        <span className="material-icons">
                                                                                                            radio_button_checked
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span className="material-icons">
                                                                                                            radio_button_unchecked
                                                                                                        </span>
                                                                                                    )}
                                                                                                    <div
                                                                                                        className="savedAddressCard bank"
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="cardDetails">
                                                                                                            <h6>
                                                                                                                {
                                                                                                                    data.bank_name
                                                                                                                }
                                                                                                            </h6>
                                                                                                            <h6>
                                                                                                                {
                                                                                                                    data.last4
                                                                                                                }

                                                                                                                /
                                                                                                                {
                                                                                                                    data.routing_number
                                                                                                                }
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                        {data.status ===
                                                                                                        'new' ? (
                                                                                                            <Button
                                                                                                                id="checkoutPage_verify"
                                                                                                                onClick={() => {
                                                                                                                    toggleFullScreenBankPopup(
                                                                                                                        true,
                                                                                                                        'verify',
                                                                                                                        data,
                                                                                                                    )
                                                                                                                }}
                                                                                                            >
                                                                                                                {' '}
                                                                                                                Verify
                                                                                                            </Button>
                                                                                                        ) : null}
                                                                                                        <Button
                                                                                                            id="checkoutPage_update"
                                                                                                            onClick={() =>
                                                                                                                toggleFullScreenBankPopup(
                                                                                                                    true,
                                                                                                                    'update',
                                                                                                                    data,
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            Edit
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            id="checkoutPage_deleteData"
                                                                                                            onClick={() =>
                                                                                                                toggleFullScreenBankPopup(
                                                                                                                    true,
                                                                                                                    'delete',
                                                                                                                    data,
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            Delete
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </ListItem>
                                                                                            </label>
                                                                                        </div>
                                                                                    ),
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                                <Divider />

                                                                <>
                                                                    {formikPayment.values
                                                                        .payment_method ===
                                                                        'card' && (
                                                                        <Button
                                                                            id="checkoutPage_add_card"
                                                                            onClick={() => {
                                                                                toggleFullScreenCardPopup(
                                                                                    true,
                                                                                    'new',
                                                                                    null,
                                                                                )
                                                                            }}
                                                                            className="checkoutAddBtn "
                                                                        >
                                                                            <span className="material-icons">
                                                                                add
                                                                            </span>
                                                                            Add New Card
                                                                        </Button>
                                                                    )}
                                                                    {formikPayment.values
                                                                        .payment_method ===
                                                                        'bank' && (
                                                                        <Button
                                                                            id="checkoutPage_add_bank"
                                                                            onClick={() => {
                                                                                toggleFullScreenBankPopup(
                                                                                    true,
                                                                                    'new',
                                                                                    null,
                                                                                )
                                                                            }}
                                                                            className="checkoutAddBtn"
                                                                        >
                                                                            <span className="material-icons">
                                                                                add
                                                                            </span>
                                                                            Add New Bank
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                                {!changeCard && selectedCard && (
                                                    <>
                                                        {selectedCard.object === 'bank_account' ? (
                                                            <>
                                                                <ListItem
                                                                    button
                                                                    className="CardList"
                                                                >
                                                                    <div className="savedAddressCard">
                                                                        <div className="cardDetails">
                                                                            <h6>
                                                                                {
                                                                                    selectedCard.bank_name
                                                                                }
                                                                            </h6>
                                                                            <h6>
                                                                                {selectedCard.last4}
                                                                                /
                                                                                {
                                                                                    selectedCard.routing_number
                                                                                }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </ListItem>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ListItem
                                                                    button
                                                                    className="CardList"
                                                                >
                                                                    <div className="savedAddressCard">
                                                                        <div className="cardDetails">
                                                                            <h6>
                                                                                {selectedCard.brand}{' '}
                                                                                ending in{' '}
                                                                                {selectedCard.last4}
                                                                            </h6>
                                                                            <h6>
                                                                                EXP.{' '}
                                                                                {
                                                                                    selectedCard.exp_month
                                                                                }
                                                                                /
                                                                                {
                                                                                    selectedCard.exp_year
                                                                                }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </ListItem>
                                                                {/* <div className="row">
                                                                    {mapData(cvvForm)}
                                                                </div> */}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            {cartValues?.discountoff &&
                                            cartValues.discountoff !== '0.00' ? (
                                                <>
                                                    <p className="w-100">
                                                        <label>Account Credit</label>
                                                        <span>
                                                            {currencyFormat(
                                                                userOtherInfo.deposit_amount,
                                                            )}
                                                        </span>
                                                    </p>
                                                    <p className="creditApply">
                                                        <label>Apply Credit</label>
                                                        <CheckBox
                                                            name="fields"
                                                            label={''}
                                                            checked={applyCredit}
                                                            onChange={(prev) =>
                                                                setApplyCredit(!applyCredit)
                                                            }
                                                        />
                                                    </p>
                                                </>
                                            ) : null}

                                            <div onClick={formikPayment.handleSubmit}>
                                                {pendingPayment.length ? (
                                                    <PrimaryButton
                                                        id="checkout_pay_make"
                                                        disabled={true}
                                                        label={'Pay Now'}
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        id="checkout_pay_submit"
                                                        type="submit"
                                                        label={'Pay Now'}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <CardDetails
                            allCards={cards}
                            data={manageCard}
                            function={toggleFullScreenCardPopup}
                            userDetails={user}
                        />

                        {/* <div className="PaymentModal">
                <Popup
                    open={addAddress}
                    size="md"
                    handleClose={() => toggleModal()}
                    modaltitle="Change billing address"
                >
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {Object.values(mapData(billingAddressInfo))}
                        <PrimaryButton btnSize="large" type="submit" label={'Save'} />
                    </form>
                </Popup>
            </div> */}
                    </>
                )}
            </div>
        </>
    )
}

export default Checkout
