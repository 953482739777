import openSocket from 'socket.io-client'
import moment from 'moment'
import { InputOutlined } from '@material-ui/icons'

let socket = openSocket(`${process.env.REACT_APP_DOMAIN}`, { transports: ['websocket', 'polling'] })

let socketServerTime = new Date()

socket.on('connect', () => {
    console.log('Socket Connected')
})

socket.on('sliservertime', (data) => {
    console.log(data, 'dtimee')
    if (moment(data.dTime).isValid()) {
        socketServerTime = data.dTime
    }
})

socket.on('message', (data) => {
    console.log(data, 'dataserver')
})

function bidAddtime(cb) {
    socket.on('bidAddtime', (data) => {
        cb(data)
    })
}

// function cancelBidEmitted(cb) {
//     socket.on('cancelBidEmitted', (data) => {
//         cb(data)
//     })
// }

function servertimeUpdate(cb) {
    socketServerTime = cb
}

// function realClosedUpdates(cb) {
//     socket.on('realclosedupdates', (data) => {
//         cb(data)
//     })
// }

// function refreshbuyer(cb) {
//     socket.on('refreshbuyer', (data) => {
//         cb(data)
//     })
// }
// function refreshbuyerOff() {
//     socket.off('refreshbuyer')
// }
// function bid_extended(cb) {
//     socket.on('bid_extended', (data) => {
//         cb(data)
//     })
// }
// function bid_extendedOff() {
//     socket.off('bid_extended')
// }

socket.on('disconnect', (reason) => {
    console.log('Socket Disconnected', reason)
})

function reInitializeSocket() {
    socket.close()
    socket = openSocket(`${process.env.REACT_APP_DOMAIN}`, { transports: ['websocket', 'polling'] })
}

export {
    bidAddtime,
    // realClosedUpdates,
    // refreshbuyer,
    socket,
    reInitializeSocket,
    // refreshbuyerOff,
    // bid_extended,
    // bid_extendedOff,
    // servertime,
    socketServerTime,
    // cancelBidEmitted,
    servertimeUpdate,
    // serverTime,
}
