import React, { useRef, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    const textFieldRef = useRef(null)

    const handleChange = (e) => {
        if (props.maxLength) {
            let value = e.target.value
            if (value.length <= props.maxLength) {
                props.onChange(e)
            }
        } else if (props.restrictTwoDigits) {
            const regex = /^\d*\.?\d{0,2}$/
            if (regex.test(e.target.value)) {
                props.onChange(e)
            }
        } else {
            props.onChange(e)
        }
    }

    useEffect(() => {
        if (props.onWheel) {
            const handleWheel = (e) => e.preventDefault()
            textFieldRef.current.addEventListener('wheel', handleWheel)
        }
        // return () => {
        //     if (props.onWheel) {
        //         textFieldRef.current.removeEventListener('wheel', handleWheel)
        //     }
        // }
    }, [props.onWheel])
    return (
        <div
            className={`customInput ${props.error ? 'error-input' : ''}`}
            style={props.inputStyle ? props.inputStyle : null}
        >
            {console.log(props.name, props.maxLength, props.inputProps, 'props.inputProps')}
            {props.upperLabel ? (
                <>
                    <label htmlFor={props.id}>{props.label}</label>
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        ref={textFieldRef}
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        InputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            endAdornment: props.endAdornment,
                            maxLength: props.maxLength,
                            shrink: shrink,
                        }}
                        id={props.id}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        onKeyDown={props.onKeyDown}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />
                </>
            ) : (
                <Tooltip
                    title={props.tooltiptitle}
                    arrow={props.tooltiptitle ? true : false}
                    placement="top"
                    disableHoverListener={
                        props.disableHoverListener ? props.disableHoverListener : true
                    }
                    disableFocusListener={props.tooltiptitle ? false : true}
                >
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        ref={textFieldRef}
                        name={props.name}
                        onChange={(e) => handleChange(e)}
                        onBlur={props.onBlur}
                        maxLength={props.maxLength}
                        InputProps={{
                            inputProps: props.inputProps,
                            maxLength: props.maxLength,
                            startAdornment: props.startAdornment,
                            endAdornment: props.endAdornment,
                            shrink: shrink,
                            'aria-label': props.label,
                        }}
                        InputLabelProps={{
                            id: props.id,
                            name: props.label,
                            'aria-label': props.label,
                            'aria-labelledby': props.id,
                        }}
                        htmlFor={props.id}
                        id={props.id}
                        label={props.label}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        onKeyDown={props.onKeyDown}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />
                </Tooltip>
            )}
        </div>
    )
}

export default CustomInput
