import React, { useContext, useEffect, useState } from 'react'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import CheckBox from '@/custom/components/atoms/CheckBox'
import CommonContext from '@/custom/context/common/commonContext'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import UserContext from '@/custom/context/user/userContext'
import AuthContext from '@/custom/context/auth/authContext'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import SEO from '@/utils/SEO'
import { SITE_NAME } from '@/utils/'
import AlertContext from '@/custom/context/alert/alertContext'

function Notifications() {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const userContext = useContext(UserContext)

    const [reload, setReload] = useState(false)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const { user, loadUser } = authContext
    const [notify, setNotify] = useState([])
    const [selected, setSelected] = useState([])
    const {
        updatePreference,
        responseStatus: responseStatusUser,
        getUserNotification,
        user_notification,
        clearResponse,
    } = userContext
    //update Notification settings
    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
        },
        onSubmit: (values) => {
            updatePreference({
                email: values.email_prefer.toString(),
                sms: values.sms_prefer.toString(),
            })
        },
    })

    useEffect(() => {
        getUserNotification({})
    }, [])

    useEffect(() => {
        if (user_notification && user_notification?.records) {
            console.log(user_notification, 'user_notification')
            setNotify(user_notification.records.notificationList)
            setSelected(user_notification.records.userNotification)
        }
    }, [user_notification])

    useEffect(() => {
        if (selected && Object.keys(selected).length) {
            console.log(selected, 'selected')
            formikPreference.setFieldValue('email_prefer', selected.email.split(','))
            formikPreference.setFieldValue('sms_prefer', selected.sms.split(','))
        }
    }, [selected])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updatePreference') {
                console.log(responseStatusUser, 'responseStatusUser')
                setAlert(
                    responseStatusUser.data.statusText,
                    responseStatusUser.data.status ? 'success' : 'false',
                )
                if (responseStatusUser.data.status) {
                    getUserNotification({})
                }
            }
            clearResponse()
        }
    }, [responseStatusUser])

    return (
        <>
            <SEO title="Notifications" url={window.location.href} />
            <DashboardLayout title={'My notifications'}>
                <div className="dashboardInner notifications">
                    <div className="auctionStatus">
                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/profile">
                                {'Profile'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/change">
                                {'Change password'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/account/notifications">
                                {'My notifications'}
                            </NavLink>
                        </ListItem>
                    </div>
                    <h5 className="dashSubtitle">
                        {SITE_NAME} will send emails based on a wide variety of events that may
                        occur as seen below. Some notifications such as Payment information will be
                        sent regardless of your email preference settings.
                    </h5>
                    <div className="table-responsive dpTable">
                        <form onSubmit={formikPreference.handleSubmit} autoComplete="nofill">
                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">{'Notification'}</th>
                                        <th scope="col">{'Email'}</th>
                                        <th scope="col">{'SMS'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notify.map((data, index) => (
                                        <>
                                            <tr key={data.id}>
                                                <td
                                                    hidden={
                                                        data.email == 0 && data.sms == 0
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {data.notifications}
                                                </td>
                                                <td hidden={data.email == 0 ? true : false}>
                                                    <CheckBox
                                                        name={'email_prefer'}
                                                        label={''}
                                                        checked={
                                                            formikPreference.values[
                                                                'email_prefer'
                                                            ].indexOf(data.id.toString()) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        value={data.id.toString()}
                                                        onChange={formikPreference.handleChange}
                                                    />
                                                </td>
                                                <td
                                                    hidden={
                                                        data.notifications == 'Trending now'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <CheckBox
                                                        name={'sms_prefer'}
                                                        label={''}
                                                        checked={
                                                            formikPreference.values[
                                                                'sms_prefer'
                                                            ].indexOf(data.id.toString()) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        value={data.id.toString()}
                                                        onChange={formikPreference.handleChange}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                            <PrimaryButton id="notify_submit" type="submit" label={'Save'} />
                        </form>
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export default Notifications
