import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    TIMER_VALUE,
    UPLOADED_IMAGE,
    CLEAR_BID_HISTORY,
    BID_HISTORY_VALUE,
    CLEAR_SEARCH,
    CLEAR_RESPONSE,
    SWITCH_LANGUAGE,
    PENDING_INVOICES,
    LOADERS,
    SET_MEMBERSHIP,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allStates: [],
        allCategory: [],
        allBusinessCategory: [],
        allOrganization: [],
        allVatCategory: [],
        allLocations: [],
        allTimeZone: [],
        allEventType: [],
        allCondition: [],
        uploadedFiles: [],
        allUISettings: [],
        allSubCategories: [],
        alldata_all_dropdown: [],
        allfaq: [],
        allNotifications: [],
        allConfigurationVariables: {},
        allMaintanenceData: {},
        configFeatures: {},
        configVariables: {},
        allBidIncrements: [],
        responseStatus: null,
        languages: [],
        pharases: {},
        searchValue: null,
        noPendingInvoices: false,
        bidHistoryValue: 0,
        currentLanguage: 'for_english',
        allBiddingPlan: [],
        membership: null,
        allHomeBanner: [],
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategorytypes,
                    allSubCategories: res.data.data.responseData.tableValues.allsubCategoryTypes,
                    allOrganization: res.data.data.responseData.tableValues.allorganization,
                    allBusinessCategory:
                        res.data.data.responseData.tableValues.allbusiness_category,
                    allVatCategory: res.data.data.responseData.tableValues.allvat_registration,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allTimeZone: res.data.data.responseData.tableValues.alltime_zone,
                    allEventType: res.data.data.responseData.tableValues.allevent_type,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allMaintanenceData: res.data.data.responseData.maintanenceData,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allCondition: res.data.data.responseData.tableValues.allconditiontypes,
                    allUISettings: res.data.data.responseData.tableValues.allui_settings,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    alldata_all_dropdown: res.data.data.responseData.tableValues
                        .alldata_all_dropdown
                        ? res.data.data.responseData.tableValues.alldata_all_dropdown.filter(
                              (value) => value.active != 2,
                          )
                        : [],
                    configFeatures: res.data.data.responseData.features,
                    configVariables: res.data.data.responseData.variables,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                    allBiddingPlan: res.data.data.responseData.tableValues.allbidding_plan,
                    allHomeBanner: res.data.data.responseData.tableValues.allhome_banner?.filter(
                        (value) => value.active === 1,
                    ),
                    allfaq: res.data.data.responseData.tableValues.allfaq,
                },
            })

            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_english')
        }
    }

    const getStates = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getStates', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'login')
            console.log(res.data, res.data.status && res.data.userToken, 'what coming')
            if (res.data.status) {
                dispatch({
                    type: COMMON_VALUES,
                    payload: {
                        allStates: res.data.state_list,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    const getLocations = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getLocations', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'login')
            console.log(res.data, res.data.status && res.data.userToken, 'what coming')
            if (res.data.status) {
                dispatch({
                    type: COMMON_VALUES,
                    payload: {
                        allLocations: res.data.locations,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    const uploadImage = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'uploadTaxExempt', formData, '', 'mobileapi'),
            ])
            console.log(res.data, 'resdata')
            if (res.data.status) {
                dispatch({
                    type: UPLOADED_IMAGE,
                    payload: {
                        files: res.data.taxData,
                        from: fromVariable,
                    },
                })
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'error',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        from: fromVariable,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    const removeTaxExempt = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeTaxExempt', formData, '', 'mobileapi'),
            ])
            console.log(res.data, 'remove')
            if (res.data.status) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status ? 'success' : 'error',
                        message: res?.data?.statusText ? res?.data?.statusText : '',
                        from: fromVariable,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    const getCategories = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCategories', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: COMMON_VALUES,
                    payload: {
                        allCategory: res.data.categories,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    const switchLanguage = (data) =>
        dispatch({
            type: SWITCH_LANGUAGE,
            payload: data,
        })

    const loaderSet = (isLoad) => {
        dispatch({
            type: LOADERS,
            payload: isLoad,
        })
    }

    const setSearchValue = async (data) => {
        dispatch({
            type: TIMER_VALUE,
            payload: data,
        })
    }

    const setPendingInvoices = async (data) => {
        dispatch({
            type: PENDING_INVOICES,
            payload: data,
        })
    }

    const setBidHistoryValue = async (data) => {
        dispatch({
            type: BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearBidHistoryValue = () =>
        dispatch({
            type: CLEAR_BID_HISTORY,
        })

    const clearSearchValue = () =>
        dispatch({
            type: CLEAR_SEARCH,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const setCurrentMembership = (payload) =>
        dispatch({
            type: SET_MEMBERSHIP,
            payload: {
                membership: payload,
            },
        })

    const resetDropZone = (fromUpload) => {
        dispatch({
            type: UPLOADED_IMAGE,
            payload: {
                files: [],
                from: fromUpload,
            },
        })
    }

    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                noPendingInvoices: state.noPendingInvoices,
                bidHistoryValue: state.bidHistoryValue,
                allBusinessCategory: state.allBusinessCategory,
                allOrganization: state.allOrganization,
                allVatCategory: state.allVatCategory,
                allNotifications: state.allNotifications,
                allSubCategories: state.allSubCategories,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allStates: state.allStates,
                allfaq: state.allfaq,
                allTimeZone: state.allTimeZone,
                allEventType: state.allEventType,
                allUISettings: state.allUISettings,
                allBidIncrements: state.allBidIncrements,
                alldata_all_dropdown: state.alldata_all_dropdown,
                allConfigurationVariables: state.allConfigurationVariables,
                allMaintanenceData: state.allMaintanenceData,
                allBiddingPlan: state.allBiddingPlan,
                membership: state.membership,
                allHomeBanner: state.allHomeBanner,
                configFeatures: state.configFeatures,
                uploadedFiles: state.uploadedFiles,
                configVariables: state.configVariables,
                languages: state.languages,
                searchValue: state.searchValue,
                currentLanguage: state.currentLanguage,
                responseStatus: state.responseStatus,
                getStates,
                getCategories,
                uploadImage,
                setSearchValue,
                setPendingInvoices,
                switchLanguage,
                getLocations,
                setBidHistoryValue,
                clearBidHistoryValue,
                resetDropZone,
                getGlobalVariable,
                loaderSet,
                clearSearchValue,
                removeTaxExempt,
                clearResponse,
                setCurrentMembership,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
