import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { apiCall, setAuthToken } from '../../common/api'
import { response } from '../common'
import {
    USER_LOADED,
    LOGOUT,
    RESPONSE_STATUS,
    COUNT_LOADED,
    COUNT_TEMP_LOADED,
    AUTHENTICATED,
    CLEAR_RESPONSE,
    CALL_END,
} from './authTypes'

const AuthState = (props) => {
    const initialState = {
        token: sessionStorage.getItem('token') || localStorage.getItem('token'),
        isAuthenticated: null,
        isAdmin: null,
        loading: true,
        cartCount: {},
        cartTempCount: {},
        user: null,
        responseStatus: null,
        callEnd: null,
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Register User
    const register = async (formData, noAlert, fromVariable = 'register') => {
        try {
            const [res] = await Promise.all([apiCall('post', 'signup', formData, '', 'mobileapi')])
            resp.commonResponse(res.data, fromVariable, noAlert)
            // if (!formData?.registerToken && global.registerToken) {
            //     if (global.session) {
            //         sessionStorage.setItem('token', res.data.data.responseData.token)
            //     } else {
            //         localStorage.setItem('token', res.data.data.responseData.token)
            //     }
            //     loadUser()
            // }
        } catch (err) {
            resp.commonErrorResponse('register')
        }
    }

    // Check Email User
    const checkValidation = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkEmailAndPhoneAlreadyExists', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'checkValidation', noAlert)
        } catch (err) {
            resp.commonErrorResponse('checkValidation')
        }
    }

    const checkEmailAlreadyExists = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkEmailAlreadyExists', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'checkEmailAlreadyExists', noAlert)
        } catch (err) {
            resp.commonErrorResponse('checkEmailAlreadyExists')
        }
    }

    const checkUserNameAlreadyExists = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkUserNameAlreadyExists', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'checkUserNameAlreadyExists', noAlert)
        } catch (err) {
            resp.commonErrorResponse('checkUserNameAlreadyExists')
        }
    }

    const checkPhoneAlreadyExists = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkPhoneAlreadyExists', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'checkPhoneAlreadyExists', noAlert)
        } catch (err) {
            resp.commonErrorResponse('checkPhoneAlreadyExists')
        }
    }

    // Check Coupon code for user
    const checkCouponValidation = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkCouponCodeForRegistration', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'checkCouponValidation', noAlert)
        } catch (err) {
            resp.commonErrorResponse('checkCouponValidation')
        }
    }

    const applyCoupon = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'applyCoupon', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'applyCoupon', noAlert)
        } catch (err) {
            resp.commonErrorResponse('applyCoupon')
        }
    }

    // Send Email and Sms verify code
    const sendEmailAndSmsVerify = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendEmailAndSmsVerificationCode', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'sendEmailAndSmsVerify', noAlert)
        } catch (err) {
            resp.commonErrorResponse('sendEmailAndSmsVerify')
        }
    }

    const sendSmsVerificationCode = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendSmsVerificationCode', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'sendSmsVerificationCode', noAlert)
        } catch (err) {
            resp.commonErrorResponse('sendSmsVerificationCode')
        }
    }

    // ReSend Email verify code
    const resendEmailVerify = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resendEmailCode', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'resendEmailVerify', noAlert)
        } catch (err) {
            resp.commonErrorResponse('resendEmailVerify')
        }
    }

    // ReSend Sms verify code
    const resendSmsVerify = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resendSMSCode', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'resendSmsVerify', noAlert)
        } catch (err) {
            resp.commonErrorResponse('resendSmsVerify')
        }
    }

    // Verify Email and Sms Verify code
    const verifyEmailAndSmsCode = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyEmailAndSmsCode', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'verifyEmailAndSmsCode', noAlert)
        } catch (err) {
            resp.commonErrorResponse('verifyEmailAndSmsCode')
        }
    }

    const verifySmsCode = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifySmsCode', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'verifySmsCode', noAlert)
        } catch (err) {
            resp.commonErrorResponse('verifySmsCode')
        }
    }

    // Login User
    const login = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'signin', formData, '', 'mobileapi')])
            resp.commonResponse(res.data, 'login')
            console.log(res.data, res.data.status && res.data.userToken, 'what coming')
            if (res.data.status && res.data.userToken) {
                console.log(res.data.userData, 'what coming123')
                if (global.session) {
                    sessionStorage.setItem('token', res.data.userToken)
                } else {
                    localStorage.setItem('token', res.data.userToken)
                    localStorage.setItem('userData', JSON.stringify(res.data.userData))
                }
                loadUser(res.data.userData)
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    // Switch User
    const switchUser = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'switchuser', formData, '', 'user')])
            resp.commonResponse(res.data, 'switchuser')
            if (res.data.status === 'success') {
                if (global.session) {
                    sessionStorage.setItem('token', res.data.data.token)
                } else {
                    localStorage.setItem('token', res.data.data.token)
                }
                // loadUser()
            }
        } catch (err) {
            resp.commonErrorResponse('switchuser')
        }
    }

    // Load User
    const loadUser = async (data) => {
        console.log(localStorage.token, data, 'loadUserdata')
        if (localStorage.token && !global.session) {
            setAuthToken(localStorage.token)
        } else if (sessionStorage.token) {
            setAuthToken(sessionStorage.token)
        }
        if (data) {
            await dispatch({
                type: USER_LOADED,
                payload: {
                    data: data,
                },
            })
        } else if (localStorage.userData) {
            await dispatch({
                type: USER_LOADED,
                payload: {
                    data: JSON.parse(localStorage.getItem('userData')),
                },
            })
        } else {
            if (localStorage.token) {
                await dispatch({
                    type: AUTHENTICATED,
                    payload: {
                        data: data,
                    },
                })
            }
        }
        // const [res] = await Promise.all([apiCall('post', 'validateUser', {}, '', 'user')])
        // if (res?.data?.status === 'success') {
        //     if (res.data?.data?.responseData?.status === 'deactivate') {
        //         await dispatch({
        //             type: LOGOUT,
        //         })
        //     } else {
        //         await dispatch({
        //             type: USER_LOADED,
        //             payload: {
        //                 data: res.data.data.responseData,
        //             },
        //         })
        //     }
        // } else if (res.data.status === 'error') {
        //     await dispatch({
        //         type: LOGOUT,
        //     })
        // } else {
        //     await dispatch({
        //         type: LOGOUT,
        //     })
        // }
        // await dispatch({
        //     type: CALL_END,
        //     payload: res.data,
        // })
    }

    // Load Count
    const loadPendingCount = async (formData, data) => {
        const [res] = await Promise.all([apiCall('post', 'pendingCount', formData, '', 'cart')])
        if (res.data.status === 'success') {
            dispatch({
                type: COUNT_LOADED,
                payload: {
                    [data]: res.data.data.responseData,
                },
            })
        }
    }

    const loadPendingTempCount = async (formData, data) => {
        const [res] = await Promise.all([
            apiCall('post', 'pendingCountTemp', formData, '', 'carttemp'),
        ])
        if (res.data.status === 'success') {
            dispatch({
                type: COUNT_TEMP_LOADED,
                payload: {
                    [data]: res.data.data.responseData,
                },
            })
        }
    }

    // Log out
    const logout = () =>
        dispatch({
            type: LOGOUT,
        })

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                cartCount: state.cartCount,
                isAuthenticated: state.isAuthenticated,
                isAdmin: state.isAdmin,
                loading: state.loading,
                cartTempCount: state.cartTempCount,
                user: state.user,
                responseStatus: state.responseStatus,
                callEnd: state.callEnd,
                register,
                checkValidation,
                applyCoupon,
                checkEmailAlreadyExists,
                checkCouponValidation,
                checkUserNameAlreadyExists,
                checkPhoneAlreadyExists,
                sendEmailAndSmsVerify,
                sendSmsVerificationCode,
                resendEmailVerify,
                verifyEmailAndSmsCode,
                verifySmsCode,
                resendSmsVerify,
                login,
                switchUser,
                logout,
                loadUser,
                loadPendingTempCount,
                loadPendingCount,
                clearResponse,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
