import React, { useReducer } from 'react'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_STATIC_PAGE,
    GET_INVITE_DATA,
    CLEAR_RESPONSE,
    GET_USER_DETAILS,
    RESPONSE_STATUS,
    GET_CREDITS,
    VIEW_RECEIPT,
    GET_USER_NOTIFICATION,
    GET_RECEIPT,
    GET_COMPANY_OWNER_DATA,
    GET_REFUND,
    GET_SKYFLOW_CARDS,
} from './userTypes'

const UserState = (props) => {
    const initialState = {
        responseStatus: null,
        static_page: {},
        user_notification: null,
        single_invoice: {
            record: null,
        },
        invite_data: {
            record: null,
        },
        refund_data: {
            record: null,
        },
        receipt_data: {
            record: null,
        },
        credit_data: {
            record: null,
        },
        company_owner_data: {
            record: null,
        },
        user_details: {},
        skyflow_cards: null,
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const updateProfile = async (formData, noAlert, from) => {
        from = from ? from : 'updateProfile'
        try {
            formData.quick_enable = formData.quick_enable ? 1 : 0
            const [res] = await Promise.all([
                apiCall('post', 'updateUserProfile', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
            if (res.data.status) {
                let userDet = JSON.parse(localStorage.getItem('userData'))
                userDet = {
                    ...userDet,
                    quick_enable: formData.quick_enable,
                }
                localStorage.setItem('userData', JSON.stringify(userDet))
            }
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateStripe = async (formData, noAlert, from) => {
        from = from ? from : 'updateStripe'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateStripeDetail', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateUsername = async (formData, noAlert, from) => {
        from = from ? from : 'updateUsername'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateUsername', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updatePassword = async (formData, noAlert, from) => {
        from = from ? from : 'updatePassword'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updatePassword', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateMobilenumber = async (formData, noAlert, from) => {
        from = from ? from : 'updateMobilenumber'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateMobilenumber', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getUserProfileDetails = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getUserProfileDetails', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_USER_DETAILS,
                    payload: {
                        records: res.data,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getReceipt = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getReceipt', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'getReceipt'
            if (res.data.status === true) {
                dispatch({
                    type: GET_RECEIPT,
                    payload: {
                        records: res.data,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleInvoice = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'viewReceipt', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'viewReceipt'
            if (res.data.status === true) {
                dispatch({
                    type: VIEW_RECEIPT,
                    payload: {
                        records: res.data,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getRefund = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getRefund', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'getRefund'
            if (res.data.status === true) {
                dispatch({
                    type: GET_REFUND,
                    payload: {
                        records: res.data,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getCreditData = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCreditAndCoupon', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'getCreditData'
            if (res.data.status === true) {
                dispatch({
                    type: GET_CREDITS,
                    payload: {
                        records: res.data,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getUserNotification = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getUserNotification', formData, '', 'mobileapi'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === true) {
                dispatch({
                    type: GET_USER_NOTIFICATION,
                    payload: {
                        records: res.data,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updatePreference = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'updatePreference'
            const [res] = await Promise.all([
                apiCall('post', 'updateUserNotification', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('updatePreference')
        }
    }

    const forgotPassword = async (formData, noAlert = false) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'forgotPassword', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'forgotPassword', noAlert)
        } catch (err) {
            resp.commonErrorResponse('forgotPassword')
        }
    }

    const sendPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('sendPhoneVerifyCode')
        }
    }

    const verifyPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'verifyPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('verifyPhoneVerifyCode')
        }
    }

    const approveBidLimitRequest = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'approveBidLimitRequest', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'approveBidLimitRequest')
        } catch (err) {
            resp.commonErrorResponse('approveBidLimitRequest')
        }
    }

    const resetPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changePassword', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: 'resetPassword',
                },
            })
        } catch (err) {
            resp.commonErrorResponse('resetPassword')
        }
    }

    const changeUserStatus = async (formData, noAlert, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'changeStatus'
            const [res] = await Promise.all([apiCall('post', 'changeStatus', formData, '', 'user')])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const resetUserProfile = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resetUserProfile', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'resetUserProfile')
        } catch (err) {
            resp.commonErrorResponse('resetUserProfile')
        }
    }

    const sendEmailVerifyLink = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendEmailVerifyLink', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendEmailVerifyLink')
        } catch (err) {
            resp.commonErrorResponse('sendEmailVerifyLink')
        }
    }

    const verifyEmail = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'verifyEmail', formData, '', 'user')])
            resp.commonResponse(res.data, 'verifyEmail')
        } catch (err) {
            resp.commonErrorResponse('verifyEmail')
        }
    }
    const verifyAddress = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'isValidAddress', formData, '', 'tax/avalara'),
            ])
            resp.commonResponse(res.data, 'isValidAddress')
        } catch (err) {
            resp.commonErrorResponse('isValidAddress')
        }
    }
    const getStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getStaticPageContent', formData, '', 'mobileapi'),
            ])
            if (res.data.status) {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.statusText,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const submitContactUs = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'saveContactMessage', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, 'submitContactUs')
        } catch (err) {
            resp.commonErrorResponse('submitContactUs')
        }
    }

    const userAddress = async (formData) => {
        const from = 'userAddress'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'user')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const sendEmailVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendEmailVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendEmailVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('sendEmailVerifyCode')
        }
    }

    const verifyEmailVerifyCode = async (formData, noToken) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyEmailVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'verifyEmailVerifyCode')
            if (res.data.status === 'success' && res.data.data.token) {
                if (!noToken) {
                    if (global.session) {
                        sessionStorage.setItem('token', res.data.data.token)
                    } else {
                        localStorage.setItem('token', res.data.data.token)
                    }
                }

                // loadUser()
            }
        } catch (err) {
            resp.commonErrorResponse('verifyEmailVerifyCode')
        }
    }

    const getInviteDetails = async (formData) => {
        const from = 'getInviteDetails'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData)])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_INVITE_DATA,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addCompanyOwner = async (formData, noAlert, from, documents) => {
        from = from ? from : 'addCompanyOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addCompanyOwner', formData, '', 'user'),
            ])

            if (res.data.status != 'error') {
                res.data.data.responseData.documents = documents
            }
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateCompanyOwner = async (formData, noAlert, from) => {
        from = from ? from : 'updateCompanyOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateCompanyOwner', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const removeCompanyOwner = async (formData, noAlert, from) => {
        from = from ? from : 'removeCompanyOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeCompanyOwner', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getCompanyOwners = async (formData, noAlert, from) => {
        from = from ? from : 'getCompanyOwners'
        try {
            const [res] = await Promise.all([apiCall('post', 'getCompanyOwners', formData)])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_COMPANY_OWNER_DATA,
                    payload: {
                        record: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : {},
                    },
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addSkyflowCredit = async (formData, noAlert = false, from = 'skyflow_add') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addSkyflowCredit', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, from, noAlert)
        }
    }

    const getSkyflowCredit = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('get', 'getSkyflowCredit', formData, '', 'user'),
            ])
            console.log(res)
            if (res.data.status === 'success') {
                console.log('Heree')
                dispatch({
                    type: GET_SKYFLOW_CARDS,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            dispatch({
                type: GET_SKYFLOW_CARDS,
                payload: 'Unable to fetch cards',
            })
        }
    }

    const deleteSkyflowCredit = async (formData, noAlert = false, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteSkyflowCredit', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'skyflow_delete', noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, 'skyflow_delete', noAlert)
        }
    }

    const updateSkyflowCredit = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateSkyflowCredit', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'skyflow_update')
        } catch (err) {
            resp.commonErrorResponse(from, 'skyflow_update')
        }
    }

    const defaultSkyflowCard = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'defaultSkyflowCard', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'skyflow_update')
        } catch (err) {
            resp.commonErrorResponse(from, 'skyflow_update')
        }
    }

    const runKycCheck = async (formData, noAlert, from) => {
        from = from ? from : 'runKycCheck'
        try {
            const [res] = await Promise.all([apiCall('post', 'runKycCheck', formData, '', 'user')])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateUserAccountVerified = async (formData, noAlert, from) => {
        from = from ? from : 'updateUserAccountVerified'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateUserAccountVerified', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const checkLogedUserAlreadySeenTermsAndConditions = async (formData, noAlert, from) => {
        from = from ? from : 'checkLogedTerms'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'checkLogedUserAlreadySeenTermsAndConditions',
                    formData,
                    '',
                    'mobileapi',
                ),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    from: from,
                    data: res.data,
                },
            })
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const createUpdateUserSeenTermsAndConditions = async (formData, noAlert, from) => {
        from = from ? from : 'createUpdateUserSeenTermsAndConditions'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'createUpdateUserSeenTermsAndConditions',
                    formData,
                    '',
                    'mobileapi',
                ),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const createUpdateUserSeenNewTerms = async (formData, noAlert, from) => {
        from = from ? from : 'createUpdateUserSeenNewTerms'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createUpdateUserSeenNewTerms', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const createUpdateUserSeenLocation = async (formData, noAlert, from) => {
        from = from ? from : 'createUpdateUserSeenLocation'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createUpdateUserSeenLocation', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const createUpdateUserSeenPickupLocation = async (formData, noAlert, from) => {
        from = from ? from : 'createUpdateUserSeenPickupLocation'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createUpdateUserSeenPickupLocation', formData, '', 'mobileapi'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const checkLogedUserAlreadySeenPickupLocation = async (formData, noAlert, from) => {
        from = from ? from : 'checkLogedPickupLoc'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'checkLogedUserAlreadySeenPickupLocation',
                    formData,
                    '',
                    'mobileapi',
                ),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
            // resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const checkLogedUserAlreadySeenLocation = async (formData, noAlert, from) => {
        from = from ? from : 'checkLogedLoc'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkLogedUserAlreadySeenLocation', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
            // resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const checkUserCardDetailsAfterSignin = async (formData, noAlert, from) => {
        from = from ? from : 'checkCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkUserCardDetailsAfterSignin', formData, '', 'mobileapi'),
            ])
            dispatch({
                type: RESPONSE_STATUS,
                payload: {
                    data: res.data,
                    from: from,
                },
            })
            // resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <UserContext.Provider
            value={{
                responseStatus: state.responseStatus,
                static_page: state.static_page,
                invite_data: state.invite_data,
                company_owner_data: state.company_owner_data,
                skyflow_cards: state.skyflow_cards,
                refund_data: state.refund_data,
                user_notification: state.user_notification,
                user_details: state.user_details,
                credit_data: state.credit_data,
                receipt_data: state.receipt_data,
                single_invoice: state.single_invoice,
                getRefund,
                clearResponse,
                getSingleInvoice,
                sendPhoneVerifyCode,
                changeUserStatus,
                verifyPhoneVerifyCode,
                updateStripe,
                updateProfile,
                getReceipt,
                getUserProfileDetails,
                updatePreference,
                forgotPassword,
                resetPassword,
                resetUserProfile,
                updatePassword,
                updateMobilenumber,
                getUserNotification,
                sendEmailVerifyLink,
                verifyEmail,
                checkLogedUserAlreadySeenPickupLocation,
                approveBidLimitRequest,
                verifyAddress,
                getStaticPage,
                submitContactUs,
                userAddress,
                sendEmailVerifyCode,
                verifyEmailVerifyCode,
                getInviteDetails,
                addCompanyOwner,
                getCompanyOwners,
                updateCompanyOwner,
                removeCompanyOwner,
                updateUsername,
                addSkyflowCredit,
                getSkyflowCredit,
                deleteSkyflowCredit,
                updateSkyflowCredit,
                getCreditData,
                defaultSkyflowCard,
                runKycCheck,
                checkLogedUserAlreadySeenTermsAndConditions,
                checkLogedUserAlreadySeenLocation,
                checkUserCardDetailsAfterSignin,
                createUpdateUserSeenPickupLocation,
                createUpdateUserSeenTermsAndConditions,
                createUpdateUserSeenNewTerms,
                createUpdateUserSeenLocation,
                updateUserAccountVerified,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState
