import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Button, Fade, ListItem, MenuItem, Typography } from '@material-ui/core'
import CustomSelect from '@/custom/components/atoms/Inputs/CustomSelect'
import '../Dashboard.css'
import SEO from '@/utils/SEO'
import { useTranslation } from 'react-i18next'

const Commoncomp = (props) => {
    const formikSearch = props.formikSearch
    const sortShow = props.sortShow
    const pageOptions = props.pageOptions
    const { t } = useTranslation()

    const scrollBottom = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    return (
        <>
            {/* <SEO title="My Bids gh" url={window.location.href} /> */}
            <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-center">
                {props.path && (
                    <Breadcrumbs aria-label="breadcrumb" className="customBreadcrumbs">
                        {console.log(location.pathname, 'lo')}
                        {location.pathname == '/expired_auction' ? (
                            <Link to="/expired_auction">{'Expired Auction'}</Link>
                        ) : location.pathname == '/watchlist/watchlist' ? (
                            <Link to="/watchlist/watchlist">{location.pathname.split('/')[1]}</Link>
                        ) : (
                            <Link to="/auctions/all">{location.pathname.split('/')[1]}</Link>
                        )}

                        <Typography color="textPrimary">
                            {props.renderHeading(location.pathname.split('/')[2])}
                        </Typography>
                    </Breadcrumbs>
                )}
                <div className="searchMiscFilters mb-3 d-flex justify-content-end align-items-end">
                    {props.viewSwitch && (
                        <div className="gridListToggle">
                            <Button
                                id="myauctions_grid"
                                className={props.auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => props.setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                id="myauctions_list"
                                className={props.auctionView === 'List' ? 'active' : ''}
                                onClick={() => props.setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                    )}
                    {props.resultPerPage && (
                        <CustomSelect
                            label={t('Results')}
                            name="resultsPerPage"
                            selectType="noBorder"
                            size="small"
                            value={formikSearch.values.limit}
                            onChange={(event, editor) => {
                                formikSearch.setFieldValue('limit', event.target.value)
                                formikSearch.setFieldValue('page', 1)
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    )}
                    {props.sortBy && (
                        <CustomSelect
                            label={'Sort by'}
                            value={formikSearch.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formikSearch.setFieldValue('orderby', parseInt(event.target.value))
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value} key={optindex}>
                                    {opt.show}
                                </option>
                            ))}
                        </CustomSelect>
                    )}
                    {props.scrollBottom ? (
                        <div className="scrollTop ml-2" onClick={scrollBottom}>
                            Scroll <span className="material-icons ml-2">arrow_downward</span>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default Commoncomp
