import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { LOGO, SITE_NAME } from '@/utils'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Button,
    ClickAwayListener,
    Divider,
    Grow,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Popper,
    SwipeableDrawer,
    Typography,
    withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AuthContext from '@/custom/context/auth/authContext'
import UserContext from '@/custom/context/user/userContext'
import { handleRedirectInternal, useCustomMediaQuery } from '@/custom/common/components'
import './Header.css'
import BidHistory from '@/custom/components/organisms/BidHistory'
import BarCodeScan from '@/custom/components/organisms/BarCodeScan'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'
import MegaMenu from '@/custom/components/organisms/MegaMenu'
import Dialog from '@/custom/components/organisms/Dialog'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import ProductContext from '@/custom/context/product/productContext'
import { Category } from '@material-ui/icons'
import AlertContext from '@/custom/context/alert/alertContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import StripeCardContext from '@/custom/context/stripe/card/cardContext'

const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const {
        allLocations,
        allCategory,
        searchValue,
        setSearchValue,
        noPendingInvoices,
        setPendingInvoices,
    } = useContext(CustomCommonContext)
    const { getWatchlistCount, watch_count } = useContext(ProductContext)
    const { get_all_card_details, getAllStripeCards } = useContext(StripeCardContext)
    const { isAuthenticated, logout, user } = authContext
    const {
        checkLogedUserAlreadySeenTermsAndConditions,
        checkLogedUserAlreadySeenLocation,
        createUpdateUserSeenTermsAndConditions,
        checkUserCardDetailsAfterSignin,
        createUpdateUserSeenNewTerms,
        createUpdateUserSeenLocation,
        static_page,
        getStaticPage,
        responseStatus,
        clearResponse,
    } = useContext(UserContext)

    const [state, setState] = useState({
        right: false,
        top: false,
    })

    const urlParams = useLocation()
    const history = useHistory()
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [triggered, setTriggered] = useState(false)
    const [locationPopup, setLocationPopup] = useState(false)
    const [termsPopup, setTermsPopup] = useState(false)
    const [cardDetails, setCardDetails] = useState(false)
    const [policyPopup, setPolicyPopup] = useState(false)
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const [scanToggle, setScanToggle] = useState(false)
    const [helpToggle, setHelpToggle] = useState(false)
    const [category, setCategory] = useState('')
    const [location, setLocation] = useState('')
    const [expanded, setExpanded] = useState('panel2')
    const [initialValues, setInitialValues] = useState({})
    const [isActive, setIsActive] = useState(true)
    const [pathname, setPathname] = useState()

    // const handleActiveClass = (path) => {
    //     console.log(path, pathname, 'PathName')
    //     handleRedirectInternal(history, path)
    //     if (path === pathname) {
    //         setIsActive(true)
    //     } else if (path === pathname) {
    //         console.log('pickup_instructions')
    //         handleRedirectInternal(history, 'pickup_instructions')
    //     }
    // }

    // useEffect(() => {
    //     const pathname = urlParams.pathname
    //     setPathname(pathname)
    // }, [handleActiveClass])

    const formik = useFormik({
        initialValues: {
            user: '',
            limit: 12,
            page: 1,
            orderby: '',
            order: '',
            filters: initialValues,
            market_status: 'close',
            having: {
                future_active: {
                    value: 0,
                    type: 'in',
                    field: 'future_active',
                },
            },
        },
        // onSubmit: (values) => {},
    })

    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})
    const [isStreaming, setIsStreaming] = useState(false)
    const [source, setSource] = useState('')
    const videoRef = useRef(null)
    const canvasRef = useRef(null)
    let [viewStatic, setviewStatic] = useState([])

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleLocation = (event) => {
        setSearchValue({
            filters: {
                location: {
                    value: event,
                },
            },
        })
        setLocation(event)
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    const handleCategory = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setSearchValue({
            filters: {
                cid: {
                    value: event,
                },
            },
        })
        setCategory(event)
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    const onLogOut = () => {
        setPendingInvoices(false)
        setTriggered(false)
        logout()
        localStorage.removeItem('updated')
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    useEffect(() => {
        if (watch_count && watch_count.records) {
            console.log(watch_count.records, 'etytyty')
            setCount(watch_count.records.watchlistCount)
        }
    }, [watch_count])

    const handlePath = (data) => {
        window.open(data, '_blank')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        console.log(anchor, open, 'toggleState')
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const getLotIdObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                lot_id: {
                    value: value,
                    type: 'in',
                    field: 'it.lot_id',
                },
            },
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            filters: {
                searchbar: {
                    value: value,
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        console.log(e.target.value, 'e.target.value')
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchValue(getSearchBarObjet(searchText))
        setState({ ...state, top: false })
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    const handleScanProduct = () => {
        setScanToggle(true)
    }

    const changeScanDialogStatus = () => {
        setScanToggle(!scanToggle)
    }

    const setDecodedBarcode = (data) => {
        setSearchText(data)
        setSearchValue(getSearchBarObjet(data))

        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            getWatchlistCount({})
        }
        setCardDetails(1)
    }, [isAuthenticated])

    useEffect(() => {
        console.log(urlParams.pathname, triggered, 'pathname')
        if (
            urlParams.pathname !== '/payments/saved_cards' &&
            cardDetails &&
            isAuthenticated &&
            triggered
        ) {
            console.log(cardDetails, 'carfff')
            if (cardDetails == 2) {
                setCardDetails(1)
                setTriggered(false)
                setPendingInvoices(true)
                if (
                    urlParams.pathname !== '/invoices/partial' &&
                    !urlParams.pathname.includes('/checkout')
                ) {
                    handleRedirectInternal(history, 'payments/saved_cards')
                }
            } else {
                setTriggered(false)
                setPendingInvoices(false)
            }
        }
    }, [urlParams, cardDetails, isAuthenticated, triggered])

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         let auctionFilter = {
    //             table: 'buynow',
    //             field: 'id',
    //             extraField: '',
    //             filters: {
    //                 buynow_autype: {
    //                     value: '',
    //                     type: 'in',
    //                     field: 'b.buynow_autype',
    //                 },
    //                 paid: {
    //                     value: '0',
    //                     type: 'in',
    //                     field: 'b.paid',
    //                 },
    //                 partial: {
    //                     value: '0',
    //                     type: 'in',
    //                     field: 'b.partial',
    //                 },
    //                 active: {
    //                     value: '2',
    //                     type: 'notin',
    //                     field: 'b.active',
    //                 },
    //             },
    //         }
    //         let buyNowFilter = {
    //             table: 'cart_temp',
    //             field: 'cart_id',
    //             extraField: '',
    //             filters: {
    //                 paid: {
    //                     value: '0',
    //                     type: 'in',
    //                     field: 'crt.paid',
    //                 },
    //             },
    //         }

    //         loadPendingCount(buyNowFilter, 'buynowCount')

    //         loadPendingCount(auctionFilter, 'auctionCount')
    //     }
    // }, [isAuthenticated, history.location.pathname])

    // useEffect(() => {
    //     if (cartCount?.auctionCount) {
    //         setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
    //     }
    //     if (cartCount?.buynowCount) {
    //         setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
    //     }
    // }, [cartCount])

    const handleLink = () => {
        handleHelpDialog()
        handleRedirectInternal(history, 'forgot_password')
    }

    const dummyMenuData = [
        {
            title: 'Home & Living',
            sublinks: [
                {
                    label: 'Home & decor',
                    url: '/',
                },
                {
                    label: 'Furniture',
                    url: '/',
                },
                {
                    label: 'Bed & bath',
                    url: '/',
                },
                {
                    label: 'Appliances',
                    url: '/',
                },
                {
                    label: 'Kitchen',
                    url: '/',
                },
                {
                    label: 'Lawn, garden & patio',
                    url: '/',
                },
                {
                    label: 'Cleaning supplies',
                    url: '/',
                },
            ],
        },
        {
            title: 'Personal & lifestyle',
            sublinks: [
                {
                    label: 'Apparel',
                    url: '/',
                },
                {
                    label: 'Health & beauty',
                    url: '/',
                },
                {
                    label: 'Food',
                    url: '/',
                },
                {
                    label: 'Pets',
                    url: '/',
                },
                {
                    label: 'Baby',
                    url: '/',
                },
                {
                    label: 'Toys',
                    url: '/',
                },
            ],
        },

        {
            title: 'Outdoor & activities',
            sublinks: [
                {
                    label: 'Sports & outdoors',
                    url: '/',
                },
                {
                    label: 'Automotive',
                    url: '/',
                },
                {
                    label: 'Tools & improvement',
                    url: '/',
                },
            ],
        },
        {
            title: 'Work & study',
            sublinks: [
                {
                    label: 'Electronics',
                    url: '/',
                },
                {
                    label: 'Office & school supply',
                    url: '/',
                },
                {
                    label: 'Craft',
                    url: '/',
                },
            ],
        },
    ]

    const handleHelpDialog = () => {
        setHelpToggle(!helpToggle)
    }

    const handlePolicyPopup = () => {
        setPolicyPopup(!policyPopup)
        if (!locationSeen.location) {
            setLocationPopup(true)
        } else if (!locationSeen.terms) {
            setTermsPopup(true)
        }
    }

    const handleLocationPopup = () => {
        setLocationPopup(!locationPopup)
        if (!locationSeen.terms) {
            setTermsPopup(true)
        }
    }

    const handleTerms = () => {
        setTermsPopup(!termsPopup)
    }

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0]
                const newUrl = URL.createObjectURL(file)
                setSource(newUrl)
            }
        }
    }

    const handleRemoveImage = () => {
        setSource('')
    }

    const handleQr = () => {
        localStorage.setItem('qrPopup', true)
        handleClose(history, 'account/profile')
    }

    useEffect(() => {
        console.log(isAuthenticated, localStorage?.updated, 'logged in')
        if (isAuthenticated && !localStorage?.updated) {
            getStaticPage({ page_id: 4 })
            checkLogedUserAlreadySeenLocation({})
            checkLogedUserAlreadySeenTermsAndConditions({})
            localStorage.setItem('updated', 1)
        }
    }, [isAuthenticated, localStorage?.updated])

    useEffect(() => {
        if (isAuthenticated && user && user?.stripe_customer_id) {
            getAllStripeCards({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_customer_id,
                object: 'card',
            })
            checkUserCardDetailsAfterSignin({})
        }
        // else if (isAuthenticated && !user?.stripe_customer_id) {
        //     handleRedirectInternal(history, 'payments/saved_cards')
        // }
    }, [isAuthenticated, user, urlParams.pathname])

    // useEffect(() => {
    //     if (get_all_card_details) {
    //         if (!get_all_card_details.records.length) {
    //             handleRedirectInternal(history, 'payments/saved_cards')
    //         }
    //     }
    // }, [get_all_card_details])

    const [locationSeen, setLocationSeen] = useState({})

    useEffect(() => {
        setviewStatic(static_page)
    }, [static_page])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from == 'checkLogedLoc') {
                console.log(responseStatus.data, 'locate')
                setLocationSeen({
                    location: responseStatus.data.location.statusCode == 1 ? true : false,
                    newTerms: responseStatus.data.newterms.statusCode == 1 ? true : false,
                })
                if (!(responseStatus.data.newterms.statusCode == 1)) {
                    setPolicyPopup(true)
                } else if (!(responseStatus.data.location.statusCode == 1)) {
                    setLocationPopup(true)
                }
            }
            if (responseStatus.from == 'checkLogedTerms') {
                console.log(responseStatus.data, 'terms')
                setLocationSeen({
                    ...locationSeen,
                    terms: responseStatus.data.statusCode == 1 ? true : false,
                })
                if (
                    locationSeen.location &&
                    location.newTerms &&
                    !(responseStatus.data.statusCode == 1)
                ) {
                    setTermsPopup(true)
                }
                clearResponse()
            }
            if (responseStatus.from == 'createUpdateUserSeenNewTerms') {
                console.log(responseStatus, 'responseStatusresponseStatus')
                setAlert(
                    responseStatus.data.statusText,
                    responseStatus.data.status ? 'success' : 'error',
                )
                setPolicyPopup(false)
                if (responseStatus.status) {
                    if (!locationSeen.location) {
                        setLocationPopup(true)
                    } else if (!locationSeen.terms) {
                        setTermsPopup(true)
                    }
                }
                clearResponse()
            }
            if (responseStatus.from == 'createUpdateUserSeenLocation') {
                setAlert(
                    responseStatus.data.statusText,
                    responseStatus.data.status ? 'success' : 'error',
                )
                setLocationPopup(false)
                if (responseStatus.status) {
                    if (!locationSeen.terms) {
                        setTermsPopup(true)
                    }
                }
                clearResponse()
            }
            if (responseStatus.from == 'createUpdateUserSeenTermsAndConditions') {
                setAlert(
                    responseStatus.data.statusText,
                    responseStatus.data.status ? 'success' : 'error',
                )
                setTermsPopup(false)
                clearResponse()
            }
            if (responseStatus?.from == 'checkCard') {
                console.log(responseStatus?.data, 'responseStatusresponseStatus')
                if (responseStatus?.data?.status == true) {
                    console.log('is this rendering')
                    setPendingInvoices(false)
                    setCardDetails(1)
                    setTriggered(true)
                } else {
                    console.log('whyyy this rendering')
                    setCardDetails(responseStatus?.data?.statusFlag)
                    setTriggered(true)
                    // if (responseStatus?.data?.statusFlag !== 2) {
                    //     setAlert(responseStatus?.data?.statusText, 'error')
                    // }
                }
                clearResponse()
            }
        }
    }, [responseStatus])

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true })
            videoRef.current.srcObject = stream
            setIsStreaming(true)
        } catch (error) {
            console.error('Error accessing camera:', error)
        }
    }

    const stopCamera = () => {
        const stream = videoRef.current.srcObject
        const tracks = stream.getTracks()

        tracks.forEach((track) => track.stop())

        videoRef.current.srcObject = null
        setIsStreaming(false)
    }

    const takePhoto = () => {
        const video = videoRef.current
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        // Set the canvas size to match the video stream
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        // Draw the current frame from the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Convert the canvas content to a data URL representing the image
        const photoDataUrl = canvas.toDataURL('image/png')

        // Log the data URL or perform further actions with it
        console.log(photoDataUrl)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handlePastAuction = (type, path) => {
        handleRedirectInternal(history, path)
    }

    const sortCategoryAlphabetical = (a, b) => {
        var nameA = a.name.toUpperCase() // ignore upper and lowercase
        var nameB = b.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        // names must be equal
        return 0
    }

    const homeAndDecor = [
        'Appliances',
        'Bed & Bath',
        'Cleaning Supplies',
        'Furniture',
        'Home & Decor',
        'Kitchen',
        'Lawn, Garden & Patio',
    ]

    const apparel = ['Apparel', 'Baby', 'Entertainment', 'Food', 'Health & Beauty', 'Pets', 'Toys']

    const sportsAndOutdoors = [
        'Automotive',
        'Miscellaneous',
        'Pallets',
        'Sports & Outdoors',
        'Tools & Home Improvement',
    ]

    const electronics = ['Craft', 'Electronics', 'Office & School Supply']

    const filterCategories = (categoryArray) => {
        return allCategory
            .filter((data) => categoryArray.includes(data.name))
            .sort(sortCategoryAlphabetical)
    }

    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader noPrint">
                        <div className="customContainer h-100">
                            <div className="d-flex justify-content-between align-items-center h-100">
                                <div className="headLt">
                                    {urlParams.pathname == '/' ? (
                                        <Link to="/search" className="ASlogo" id="large_logo">
                                            <img
                                                src={LOGO}
                                                alt={SITE_NAME}
                                                width="200"
                                                height="40"
                                            />
                                        </Link>
                                    ) : (
                                        <Link to="/" className="ASlogo" id="large_logo">
                                            <img
                                                src={LOGO}
                                                alt={SITE_NAME}
                                                width="200"
                                                height="40"
                                            />
                                        </Link>
                                    )}
                                </div>
                                <div className="headRt deskNav">
                                    <div className="menuRtOtr d-flex justify-content-end align-items-center">
                                        <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                            {/* <div className="headerAllCateGrp">
                                                <Button
                                                    ref={anchorRef}
                                                    aria-controls={
                                                        open ? 'menu-list-grow' : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onClick={handleToggle}
                                                >
                                                    All categories
                                                    <span className="material-symbols-rounded ml-2">
                                                        expand_more
                                                    </span>
                                                </Button>
                                            </div> */}
                                            <div className="inputSch">
                                                <input
                                                    className="form-control"
                                                    type="search"
                                                    placeholder={'Search for active items'}
                                                    aria-label="Search"
                                                    value={searchText}
                                                    onChange={handleSearchTextChange}
                                                    onKeyDown={handleSearchKeyDown}
                                                />
                                                <Button
                                                    id="header_search"
                                                    className="searchBtn"
                                                    onClick={handleSearchButtonClick}
                                                >
                                                    <object
                                                        type="image/svg+xml"
                                                        width="22px"
                                                        height="22px"
                                                        data="../assets/svg/search.svg"
                                                    ></object>
                                                </Button>
                                            </div>
                                            {/* <span
                                                className="material-symbols-rounded cameraIco"
                                                onClick={handleScanProduct}
                                            >
                                                photo_camera
                                            </span> */}
                                            <span
                                                className="material-icons-outlined cameraIco"
                                                onClick={handleScanProduct}
                                            >
                                                camera_alt
                                            </span>
                                        </div>
                                        <ul className="headerMenuList">
                                            <li>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="#"
                                                    onClick={handleHelpDialog}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                    }}
                                                >
                                                    Need help?
                                                    <span
                                                        style={{
                                                            fontSize: '22px',
                                                        }}
                                                        className="material-icons"
                                                    >
                                                        help
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                {isAuthenticated ? (
                                                    <>
                                                        {/* <li className="headCart">
                                                        <Button
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'watchlist/watchlist',
                                                                )
                                                            }
                                                        >
                                                            <Badge color="primary">
                                                                <span className="material-icons">
                                                                    favorite
                                                                </span>
                                                            </Badge>
                                                        </Button>
                                                    </li> */}
                                                        {/* <Button
                                                            aria-controls="simple-cart"
                                                            aria-haspopup="true"
                                                            className="watLst"
                                                            onClick={handleCart}
                                                            id="header_cart"
                                                        >
                                                            <Badge
                                                                badgeContent={
                                                                    count.buynowCount +
                                                                    count.auctionCount
                                                                }
                                                                color="primary"
                                                            >
                                                                <span className="material-icons-outlined">
                                                                    shopping_cart
                                                                </span>
                                                            </Badge>
                                                        </Button> */}
                                                        <Popover
                                                            anchorEl={anchorcart}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            id="simple-cart"
                                                            keepMounted
                                                            class={`headerDropMenu ${
                                                                document.body.dir === 'rtl' && 'rtl'
                                                            }`}
                                                            open={Boolean(anchorcart)}
                                                            onClose={handleClose}
                                                        >
                                                            <ul
                                                                className={`headerDropMenu ${
                                                                    document.body.dir === 'rtl' &&
                                                                    'rtl'
                                                                }`}
                                                            >
                                                                {/* <ListItem
                                                                        button
                                                                        onClick={() =>
                                                                            handleClose(
                                                                                history,
                                                                                'buynowcart',
                                                                            )
                                                                        }
                                                                        id="header_buynowcart"
                                                                    >
                                                                        <Badge
                                                                            badgeContent={
                                                                                count.buynowCount
                                                                            }
                                                                            color="primary"
                                                                        >
                                                                            <span className="material-icons">
                                                                                shopping_bag
                                                                            </span>
                                                                        </Badge>
                                                                        {'Buy now cart'}
                                                                    </ListItem> */}
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(history, 'cart')
                                                                    }
                                                                    id="header_auctioncart"
                                                                >
                                                                    <Badge
                                                                        badgeContent={count}
                                                                        color="primary"
                                                                    >
                                                                        <span className="material-icons">
                                                                            gavel
                                                                        </span>
                                                                    </Badge>

                                                                    {'Auction cart'}
                                                                </ListItem>
                                                            </ul>
                                                        </Popover>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </li>
                                            {isAuthenticated && (
                                                <li
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'watchlist/watchlist',
                                                        )
                                                    }
                                                    className="cursorPointer"
                                                >
                                                    <Badge badgeContent={count} color="primary">
                                                        <span className="material-icons">
                                                            favorite_border
                                                        </span>
                                                    </Badge>
                                                    {/* <Button
                                                        className="watLst"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                'watchlist/watchlist',
                                                            )
                                                        }
                                                    >
                                                        
                                                    </Button> */}
                                                </li>
                                            )}
                                        </ul>
                                        <ul className="d-flex justify-content-start align-items-center">
                                            {isAuthenticated ? (
                                                <>
                                                    {/* <li className="headCart">
                                                        <Button
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'cart',
                                                                )
                                                            }
                                                        >
                                                            asdas
                                                        </Button>
                                                    </li> */}

                                                    <>
                                                        <li>
                                                            <Button
                                                                id="header_user_detail"
                                                                aria-controls="simple-menu"
                                                                aria-haspopup="true"
                                                                className="dropLst"
                                                                onClick={handleMenu}
                                                            >
                                                                <span className="material-icons mr-2">
                                                                    person_outline
                                                                </span>
                                                                {`My Account`}
                                                                <span className="material-icons ml-2">
                                                                    expand_more
                                                                </span>
                                                            </Button>

                                                            <Popover
                                                                id={'simple-menu'}
                                                                open={Boolean(anchormenu)}
                                                                anchorEl={anchormenu}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <ul
                                                                    className={`headerDropMenu ${
                                                                        document.body.dir ===
                                                                            'rtl' && 'rtl'
                                                                    }`}
                                                                >
                                                                    <ListItem id="header_username">
                                                                        <h6 className="text-primary pt-3">
                                                                            {`Welcome ${
                                                                                user?.first_name ||
                                                                                'user'
                                                                            }`}
                                                                        </h6>
                                                                    </ListItem>
                                                                    <hr />
                                                                    {!noPendingInvoices ? (
                                                                        <>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'account/profile',
                                                                                    )
                                                                                }
                                                                                id="header_profile"
                                                                                className="pt-3"
                                                                            >
                                                                                {/* <span className="material-icons">
                                                                            person
                                                                        </span> */}
                                                                                {'My Profile'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleQr()
                                                                                }
                                                                                className="pb-3"
                                                                                id="header_qrcode"
                                                                            >
                                                                                {'Checkout QR Code'}
                                                                            </ListItem>
                                                                            <hr />
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'auctions/all',
                                                                                    )
                                                                                }
                                                                                className="pt-3"
                                                                                id="header_mybids"
                                                                            >
                                                                                {/* <span className="material-icons">
                                                                            gavel
                                                                        </span> */}
                                                                                {'Bids'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'watchlist/watchlist',
                                                                                    )
                                                                                }
                                                                                id="header_watchlist"
                                                                            >
                                                                                {'Watchlist'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'payments/saved_cards',
                                                                                    )
                                                                                }
                                                                                id="header_cards"
                                                                            >
                                                                                {'Cards'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'invoices/all',
                                                                                    )
                                                                                }
                                                                                id="header_invoices"
                                                                            >
                                                                                {'Receipts'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'account/notifications',
                                                                                    )
                                                                                }
                                                                                id="header_notifications"
                                                                                className="pb-3"
                                                                            >
                                                                                {'Notifications'}
                                                                            </ListItem>
                                                                            <hr />
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'user/credits',
                                                                                    )
                                                                                }
                                                                                id="header_credits"
                                                                            >
                                                                                {'Credits'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'saved_search',
                                                                                    )
                                                                                }
                                                                                id="header_savedsearch"
                                                                                className="pb-3"
                                                                            >
                                                                                {'Saved Search'}
                                                                            </ListItem>
                                                                            <hr />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'payments/saved_cards',
                                                                                    )
                                                                                }
                                                                                id="header_cards"
                                                                            >
                                                                                {'Cards'}
                                                                            </ListItem>
                                                                            <ListItem
                                                                                button
                                                                                onClick={() =>
                                                                                    handleClose(
                                                                                        history,
                                                                                        'invoices/partial',
                                                                                    )
                                                                                }
                                                                                id="header_invoices"
                                                                            >
                                                                                {'Receipts'}
                                                                            </ListItem>
                                                                        </>
                                                                    )}
                                                                    <ListItem
                                                                        button
                                                                        onClick={() => onLogOut()}
                                                                        id="header_logout"
                                                                        className="pt-3"
                                                                    >
                                                                        {/* <span className="material-icons">
                                                                            power_settings_new
                                                                        </span> */}
                                                                        <span className="text-danger">
                                                                            {'Logout'}
                                                                        </span>
                                                                    </ListItem>
                                                                </ul>
                                                            </Popover>
                                                        </li>
                                                    </>
                                                </>
                                            ) : (
                                                <>
                                                    <li>
                                                        <NavLink
                                                            to="/login"
                                                            ActiveclassName="active"
                                                            id="header_login"
                                                        >
                                                            Login
                                                        </NavLink>
                                                        /
                                                        <NavLink
                                                            to="/registration"
                                                            ActiveclassName="active"
                                                            id="header_register"
                                                        >
                                                            Register
                                                        </NavLink>
                                                    </li>
                                                    {/* <li>
                                                <NavLink
                                                    to="/registration"
                                                    activeClassName="active"
                                                    id="register"
                                                >
                                                    {t('register')}
                                                </NavLink>
                                            </li> */}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="customContainer headerBottom">
                        <div className="headerSubMenuList">
                            <MegaMenu
                                frontAddon={true}
                                title="All locations"
                                fullWidth={false}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={true}
                                popoverClass={'popperMenu'}
                                directChildren={true}
                            >
                                <div className="customMegamenu allLoc">
                                    <ul className="m-0">
                                        {allLocations.length &&
                                            allLocations.map((data, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        handleLocation(
                                                            data.city_state_request_params,
                                                        )
                                                    }}
                                                >
                                                    <h6>{`${data.city_state_display}`}</h6>
                                                </li>
                                            ))}
                                        {/*  */}
                                        {/* {data?.sublinks.map((d, i) => (
                                            <li>
                                                <a href={d.url}>{d.label}</a>
                                            </li>
                                        ))} */}
                                    </ul>
                                </div>
                            </MegaMenu>
                            <MegaMenu
                                title="Categories"
                                fullWidth={false}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={true}
                                popoverClass={'popperMenu'}
                                directChildren={true}
                            >
                                <div className="customMegamenu">
                                    {/* {allCategory.length &&
                                        allCategory.map((data, index) => (
                                            <li
                                                onClick={() => {
                                                    handleCategory(data.id)
                                                }}
                                            >
                                                <h6>{`${data.name}`}</h6>
                                            </li>
                                        ))} */}

                                    <div>
                                        <h5>Home & Decor</h5>
                                        <ul>
                                            {filterCategories(homeAndDecor).map((data, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleCategory(data.id)}
                                                >
                                                    <h6>{data.name}</h6>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        <h5>Personal & lifestyle</h5>
                                        <ul>
                                            {filterCategories(apparel).map((data, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleCategory(data.id)}
                                                >
                                                    <h6>{data.name}</h6>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <div>
                                            <h5> Outdoor & Activities</h5>
                                            <ul>
                                                {filterCategories(sportsAndOutdoors).map(
                                                    (data, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleCategory(data.id)}
                                                        >
                                                            <h6>{data.name}</h6>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </div>

                                        <div>
                                            <h5>Work & study</h5>
                                            <ul>
                                                {filterCategories(electronics).map(
                                                    (data, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleCategory(data.id)}
                                                        >
                                                            <h6>{data.name}</h6>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* {alldata_all_dropdown.length &&
                                        alldata_all_dropdown
                                            .filter(
                                                (item) =>
                                                    item.active == 1 && item.variable == 'category',
                                            )
                                            .map((data, index) => (
                                                <ul className="m-0">
                                                    <li
                                                        onClick={() => {
                                                            handleCategory(data.id)
                                                        }}
                                                    >
                                                        <h6>{data.description}</h6>
                                                    </li>
                                                </ul>
                                            ))} */}
                                    {/* {data?.sublinks.map((d, i) => (<li><a href={d.url}>{d.label}</a></li>))} */}
                                </div>
                            </MegaMenu>
                            {isAuthenticated ? (
                                <ListItem button>
                                    <ListItemText
                                        className={
                                            history?.location?.pathname === '/pastAuctions'
                                                ? 'active'
                                                : ''
                                        }
                                        primary="Past Auctions"
                                        onClick={() =>
                                            handleRedirectInternal(history, 'pastAuctions')
                                        }
                                    />
                                </ListItem>
                            ) : null}
                            <ListItem button>
                                <ListItemText
                                    className={
                                        history?.location?.pathname === '/how_it_works'
                                            ? 'active'
                                            : ''
                                    }
                                    primary="How it Works"
                                    onClick={() => handleRedirectInternal(history, 'how_it_works')}
                                />
                            </ListItem>
                            <ListItem button>
                                <ListItemText
                                    className={
                                        history?.location?.pathname === '/pickup_instructions'
                                            ? 'active'
                                            : ''
                                    }
                                    primary="Pickup Instructions"
                                    onClick={() =>
                                        handleRedirectInternal(history, 'pickup_instructions')
                                    }
                                />
                            </ListItem>
                        </div>
                        <div className="rttENd">
                            <Link to="/locationHours">
                                <u>Store locations & hours</u>
                            </Link>
                        </div>
                    </div>
                    <hr style={{ margin: '0' }} />
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={LOGO} alt={SITE_NAME} width="130" height="25" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    <Button
                                        id="header_user_search"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>
                                {isAuthenticated && (
                                    <li className="headCart">
                                        <Link to="/cart" className="p-0">
                                            <Badge color="primary">
                                                <span className="material-icons">
                                                    shopping_cart
                                                </span>
                                            </Badge>
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        id="header_menu"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <div className="customContainer headerBottom">
                        <div className="headerSubMenuList">
                            <MegaMenu
                                frontAddon={true}
                                title="All locations"
                                fullWidth={false}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={true}
                                popoverClass={'popperMenu'}
                                directChildren={true}
                            >
                                <div className="customMegamenu allLoc">
                                    <ul className="m-0">
                                        {allLocations.length &&
                                            allLocations.map((data, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        handleLocation(
                                                            data.city_state_request_params,
                                                        )
                                                    }}
                                                >
                                                    <h6>{`${data.city_state_display}`}</h6>
                                                </li>
                                            ))}
                                        {/*  */}
                                        {/* {data?.sublinks.map((d, i) => (
                                            <li>
                                                <a href={d.url}>{d.label}</a>
                                            </li>
                                        ))} */}
                                    </ul>
                                </div>
                            </MegaMenu>
                            <MegaMenu
                                title="Categories"
                                fullWidth={false}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={true}
                                popoverClass={'popperMenu'}
                                directChildren={true}
                            >
                                <div className="customMegamenu">
                                    {/* {allCategory.length &&
                                        allCategory.map((data, index) => (
                                            <li
                                                onClick={() => {
                                                    handleCategory(data.id)
                                                }}
                                            >
                                                <h6>{`${data.name}`}</h6>
                                            </li>
                                        ))} */}

                                    <div>
                                        <h5>Home & Decor</h5>
                                        <ul>
                                            {filterCategories(homeAndDecor).map((data, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleCategory(data.id)}
                                                >
                                                    <h6>{data.name}</h6>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        <h5>Personal & lifestyle</h5>
                                        <ul>
                                            {filterCategories(apparel).map((data, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleCategory(data.id)}
                                                >
                                                    <h6>{data.name}</h6>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <div>
                                            <h5> Outdoor & Activities</h5>
                                            <ul>
                                                {filterCategories(sportsAndOutdoors).map(
                                                    (data, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleCategory(data.id)}
                                                        >
                                                            <h6>{data.name}</h6>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </div>

                                        <div>
                                            <h5>Work & study</h5>
                                            <ul>
                                                {filterCategories(electronics).map(
                                                    (data, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleCategory(data.id)}
                                                        >
                                                            <h6>{data.name}</h6>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* {alldata_all_dropdown.length &&
                                        alldata_all_dropdown
                                            .filter(
                                                (item) =>
                                                    item.active == 1 && item.variable == 'category',
                                            )
                                            .map((data, index) => (
                                                <ul className="m-0">
                                                    <li
                                                        onClick={() => {
                                                            handleCategory(data.id)
                                                        }}
                                                    >
                                                        <h6>{data.description}</h6>
                                                    </li>
                                                </ul>
                                            ))} */}
                                    {/* {data?.sublinks.map((d, i) => (<li><a href={d.url}>{d.label}</a></li>))} */}
                                </div>
                            </MegaMenu>
                            {isAuthenticated ? (
                                <ListItem button>
                                    <ListItemText
                                        className={
                                            history?.location?.pathname === '/pastAuctions'
                                                ? 'active'
                                                : ''
                                        }
                                        primary="Past Auctions"
                                        onClick={() =>
                                            handleRedirectInternal(history, 'pastAuctions')
                                        }
                                    />
                                </ListItem>
                            ) : null}
                        </div>
                    </div>
                    <hr style={{ margin: '0' }} />
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="search"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button
                                    id="header_searchButton"
                                    className="btn"
                                    onClick={handleSearchButtonClick}
                                >
                                    <object
                                        type="image/svg+xml"
                                        width="22px"
                                        height="22px"
                                        style={{ pointerEvents: 'none' }}
                                        data="../assets/svg/search.svg"
                                    ></object>
                                    {/* <span className="material-icons">search</span> */}
                                </Button>
                                <Button className="btn" onClick={handleScanProduct}>
                                    <span className="material-icons">camera_alt</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img src={LOGO} alt={SITE_NAME} height="60" />
                                    </Link>
                                    <Button
                                        id="header_clear"
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                {isAuthenticated && (
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to={'/account/profile'}
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">person</span>
                                            {'My Account'}
                                        </NavLink>
                                    </ListItem>
                                )}
                                {isAuthenticated ? (
                                    <>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auctions/all"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">gavel</span>
                                                {'My bids'}
                                            </NavLink>
                                        </ListItem>
                                        {/* <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/auction"
                                                onClose={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">live_tv</span>
                                                {'Live auctions'}
                                            </NavLink>
                                        </ListItem> */}
                                        {/* <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/search"
                                                onClose={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">category</span>
                                                {'Products'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/purchases/buynow"
                                            >
                                                <span className="material-icons">
                                                    shopping_basket
                                                </span>
                                                {'My purchases'}
                                            </NavLink>
                                        </ListItem> */}

                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/payments/saved_cards"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">credit_card</span>
                                                {'My Payments'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/expired_auction"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">gavel</span>
                                                {'Expired Auction'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/invoices/all"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">receipt</span>
                                                {'My Receipts'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/user/credits"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">local_offer</span>
                                                {' My Credits/Coupon'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/return/all"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">
                                                    assignment_return
                                                </span>
                                                {'Refunds'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/saved_search"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">bookmark</span>
                                                {'My saved searches'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/watchlist/watchlist"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">favorite</span>
                                                {'Favorites'}
                                            </NavLink>
                                        </ListItem>
                                    </>
                                ) : (
                                    <>
                                        <ListItem button>
                                            <NavLink
                                                to="/login"
                                                ActiveclassName="active"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">login</span>
                                                {'Login'}
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button>
                                            <NavLink
                                                to="/registration"
                                                ActiveclassName="active"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <span className="material-icons">
                                                    app_registration
                                                </span>
                                                {'register'}
                                            </NavLink>
                                        </ListItem>
                                    </>
                                )}
                                {/* <ul
                                    className="navRespLinks"
                                    onClick={(toggleDrawer('right', false), window.scrollTo(0, 0))}
                                >
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/auction">
                                            <span className="material-icons">live_tv</span>
                                            {'Live auctions'}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/search">
                                            <span className="material-icons">category</span>
                                            {'Products'}
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/account/profile'}
                                                >
                                                    <span className="material-icons">person</span>
                                                    {'Profile'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {'My bids'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {'My purchases'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    {'Favorites'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/template">
                                                    <span className="material-icons">
                                                        table_chart
                                                    </span>
                                                    Templates
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/wallet">
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {'My wallet'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    {'My transactions'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoices/all"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {'My invoices'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    {'My saved searches'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/contact">
                                                    <span className="material-icons">
                                                        contact_support
                                                    </span>
                                                    {'Contact'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/about">
                                                    <span className="material-icons">info</span>
                                                    {'About'}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink to="/login" ActiveclassName="active">
                                                    <span className="material-icons">login</span>
                                                    {'Login'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    to="/registration"
                                                    activeClassName="active"
                                                >
                                                    <span className="material-icons">
                                                        app_registration
                                                    </span>
                                                    {t('register')}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    <ListItem button>
                                        <a href="tel:048897585">
                                            <span className="material-icons">call</span>
                                            {'Within uae'}:
                                            <span className="w-100 hnContact">04 889 7585</span>
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="tel: +97148897585">
                                            <span className="material-icons">call</span>
                                            {'International'}:
                                            <span className="w-100 hnContact">+971 4 889 7585</span>
                                        </a>
                                    </ListItem>
                                    {isAuthenticated && (
                                        <ListItem
                                            className="mt-3"
                                            button
                                            onClick={() => onLogOut()}
                                        >
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            {'Logout'}
                                        </ListItem>
                                    )}
                                </ul> */}
                                <ul className="navRespLinks mt-0">
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/how_it_works"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">
                                                workspace_premium
                                            </span>
                                            {'How it Works'}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/pickup_instructions"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">hail</span>
                                            {'Pickup Instructions'}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/contact"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">contact_support</span>
                                            {'Contact'}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/about"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">info</span>
                                            {'About'}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/locationHours"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">store</span>
                                            {'Store locations & hours'}
                                        </NavLink>
                                    </ListItem>
                                    {/* <ListItem button>
                                        <a href="tel:048897585">
                                            <span className="material-icons">call</span>{' '}
                                            {'Within uae'}:
                                            <span className="w-100 hnContact">04 889 7585</span>
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="tel: +97148897585">
                                            <span className="material-icons">call</span>
                                            {'International'}:
                                            <span className="w-100 hnContact">+971 4 889 7585</span>
                                        </a>
                                    </ListItem> */}
                                </ul>
                                {/* <Divider></Divider> */}

                                {isAuthenticated && (
                                    <ListItem className="" button onClick={() => onLogOut()}>
                                        <span className="material-icons">power_settings_new</span>
                                        {'Logout'}
                                    </ListItem>
                                )}
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />

            {/* Photo Scan */}
            <Dialog
                title="Scan Label"
                open={scanToggle}
                className="scanDialog"
                function={changeScanDialogStatus}
                handleClose={changeScanDialogStatus}
            >
                <div className="scannerBox">
                    {!isStreaming ? null : (
                        <div className="videoCamera">
                            <video ref={videoRef} autoPlay playsInline muted={!isStreaming} />
                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                        </div>
                    )}
                    <div className="cameraPermission mb-2">
                        {!source ? (
                            <>
                                {!isStreaming ? (
                                    <PrimaryButton onClick={startCamera}>
                                        <span className="material-icons-outlined mr-2">camera</span>
                                        Request Camera Permission
                                    </PrimaryButton>
                                ) : (
                                    <>
                                        <PrimaryButton onClick={takePhoto}>
                                            Take Photo
                                        </PrimaryButton>
                                        <PrimaryButton onClick={stopCamera}>
                                            Stop Camera
                                        </PrimaryButton>
                                    </>
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className="uploadgalleryImg mt-2">
                        {source ? (
                            <div className="position-relative uploImg">
                                <img src={source} alt={'snap'} className="scanedImg" />
                                <span
                                    className="material-icons-outlined mr-2 cancelIco"
                                    onClick={handleRemoveImage}
                                >
                                    cancel
                                </span>
                            </div>
                        ) : null}
                        <PrimaryButton btnSize="">
                            <input
                                accept="image/*"
                                className="scanInput"
                                id="icon-button-file"
                                type="file"
                                capture="environment"
                                onChange={(e) => handleCapture(e.target)}
                            />
                            <span className="material-icons-outlined mr-2">file_upload</span>
                            Scan an image File
                        </PrimaryButton>
                    </div>
                </div>
            </Dialog>
            <BarCodeScan
                scanToggle={scanToggle}
                changeScanDialogStatus={changeScanDialogStatus}
                setDecodedBarcode={setDecodedBarcode}
            />

            {/* Help Dialog */}
            <Dialog
                title="HOW CAN WE HELP YOU?"
                open={helpToggle}
                className="scanDialog"
                function={handleHelpDialog}
                handleClose={handleHelpDialog}
            >
                <div>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="font-weight-bold">
                                What are your hours of operation?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accTimeCnt">
                            <p className="mb-1">
                                Tuesday - Friday,<span> 10:00 am - 6:00pm</span>
                            </p>
                            <p className="mb-2">
                                Saturday,<span> 9:00 am - 12:00 pm (Noon)</span>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="font-weight-bold">
                                What are your location addresses?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="flex-wrap">
                            {/* <a
                                href="https://www.google.co.in/maps/place/6563+Warren+Dr+NW,+Norcross,+GA+30093,+USA/@33.9084239,-84.2243881,17z/data=!4m13!1m7!3m6!1s0x88f5a6dddbfd3d1f:0xbfd7b5ba869c9f78!2s6563+Warren+Dr+NW,+Norcross,+GA+30093,+USA!3b1!8m2!3d33.9084195!4d-84.2221994!3m4!1s0x88f5a6dddbfd3d1f:0xbfd7b5ba869c9f78!8m2!3d33.9084195!4d-84.2221994?hl=en&authuser=0"
                                target="blank"
                            >
                                {' '}
                                <p>Norcross – 6563 Warren Drive, Norcross, GA 30093</p>
                            </a> */}
                            <a
                                href="https://www.google.com/maps/place/5200+Panola+Industrial+Blvd,+Decatur,+GA+30035,+USA/data=!4m2!3m1!1s0x88f5ac7d0699c591:0xa990921d110554d9?sa=X&ved=2ahUKEwjg0JuMzY77AhXqw3MBHdDZAH8Q8gF6BAgZEAE"
                                target="blank"
                            >
                                {' '}
                                <p>Decatur - 5200 Panola Industrial Blvd, Decatur, GA 30035</p>
                            </a>
                            <a
                                href="https://www.google.com/maps/place/1770+Homer+Corn+Dr,+Smyrna,+GA+30080,+USA/@33.9098041,-84.5300008,17z/data=!3m1!4b1!4m6!3m5!1s0x88f516ce2b6a1ebd:0xaefa4f22af4a026a!8m2!3d33.9098041!4d-84.5300008!16s%2Fg%2F11c14y4m0k?entry=ttu"
                                target="blank"
                            >
                                {' '}
                                <p>Smyrna - 1770 Homer Corn Rd, Smyrna, GA 30080</p>
                            </a>
                            {/* {allLocations.length &&
                                allLocations
                                    .filter((item) => item.active == 1)
                                    .map((data, index) => (
                                        <Link
                                            onClick={() =>
                                                handlePath(
                                                    `https://www.google.co.in/maps/place/?q=place_id:${
                                                        data.place_id || ''
                                                    }`,
                                                )
                                            }
                                            className="mb-3 d-block"
                                        >
                                            {data.name} – {data.full_address}
                                        </Link>
                                    ))} */}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className="lplp font-weight-bold">Contact Us</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="mb-3">
                                Email -<a href="mailto:">Info@DealBadger.com</a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className="lplp font-weight-bold">
                                I Forgot My Password
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="mb-3">
                                Forgot your password? Just visit the
                                <Button
                                    onClick={() => handleLink()}
                                    className="mx-1"
                                    style={{ color: 'var(--primColor)' }}
                                >
                                    Forgot Password
                                </Button>
                                page to reset.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Dialog>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleAwayClose}>
                                <ul
                                    className="menuList"
                                    // autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {/* {alldata_all_dropdown.length &&
                                        alldata_all_dropdown
                                            .filter(
                                                (item) =>
                                                    item.variable == 'category' && item.active == 1,
                                            )
                                            .map((data) => (
                                                <MenuItem onClick={() => handleAwayClose(data.id)}>
                                                    {data.description}
                                                </MenuItem>
                                            ))} */}
                                </ul>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <Dialog
                title="New Location"
                open={locationPopup}
                className="scanDialog"
                function={handleLocationPopup}
                disableBackdropClick={true}
                handleClose={handleLocationPopup}
            >
                <div>
                    <p className="text-center">
                        DealBadger is excited to announce the opening of our Decatur location! This
                        means more deals and less driving.
                    </p>
                    <p className="text-center">
                        Please pay close attention to the Pickup Center location of the items you
                        bid on. DB will not transfer items between Pickup Centers. DB cannot hold
                        items past the 4-day pickup window (Even if customers go to the wrong Pickup
                        Center).
                    </p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.1066282793363!2d-84.17755439999999!3d33.706189699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5ac7d0699c591%3A0xa990921d110554d9!2s5200%20Panola%20Industrial%20Blvd%2C%20Decatur%2C%20GA%2030035%2C%20USA!5e0!3m2!1sen!2sin!4v1667631605591!5m2!1sen!2sin"
                        width="100%"
                        height="200"
                        loading="lazy"
                    ></iframe>
                    <b>Happy Bidding!</b>
                    <PrimaryButton
                        className="mb-2"
                        onClick={() =>
                            createUpdateUserSeenLocation({
                                flag: 1,
                            })
                        }
                    >
                        Accept & Don`t Show Again
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() =>
                            createUpdateUserSeenLocation({
                                flag: 0,
                            })
                        }
                    >
                        Accept & Close
                    </PrimaryButton>
                </div>
            </Dialog>
            <Dialog
                title=""
                open={termsPopup}
                disableBackdropClick={true}
                className="scanDialog"
                function={handleTerms}
                handleClose={handleTerms}
            >
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: viewStatic?.html,
                        }}
                    ></div>
                    <PrimaryButton
                        className="mb-2"
                        onClick={() =>
                            createUpdateUserSeenTermsAndConditions({
                                flag: 1,
                            })
                        }
                    >
                        Accept & Don`t Show Again
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() =>
                            createUpdateUserSeenTermsAndConditions({
                                flag: 0,
                            })
                        }
                    >
                        Accept & Close
                    </PrimaryButton>
                </div>
            </Dialog>

            <Dialog
                title="Retrun Policy"
                open={policyPopup}
                className="scanDialog"
                disableBackdropClick={true}
                function={handlePolicyPopup}
                handleClose={handlePolicyPopup}
            >
                <div className="modal-header">
                    <div className="logo-signin-wrap">
                        <img className="img-responsive" src="/assets/images/logo.png" alt="" />
                        <div className="reg-sign-in-txt">NEW RETURN POLICY</div>
                    </div>
                </div>

                <div className="modal-body newTermsModalContent padboth2" id="newTermsModalContent">
                    <p>Dear DealBadger Shopper,</p>
                    <p>
                        We are excited to announce our <b>NEW RETURN POLICY</b>, effective
                        immediately on all auctions posted starting today. These changes are
                        designed to enhance your satisfaction with DealBadger, delivering a more
                        stress-free buying experience.
                    </p>
                    <p>Key Changes:</p>
                    <div>
                        <ol>
                            <li>
                                <b>All purchased lots are eligible for refunds for any reason</b>,
                                including failure to pick up the product in your designated window.
                            </li>
                            <li>
                                {`When a Buyer requests a refund before leaving the Pickup Center with
                                the item, a partial refund will be processed as a "Buyer's Return".
                                Also, when DB retrieves the item after the Pickup window and
                                reprocesses the item for auction, a partial refund will be processed
                                as a "Buyer's Return". A partial refund consists of the Winning Bid
                                amount. When issuing a partial refund, the Buyer's Premium will not
                                be included and will be applied as a restocking fee.`}
                            </li>
                            <li>
                                {`A full refund, including the Winning Bid amount and Buyer's Premium,
                                will continue to be processed for "Lost Items", "Misidentified
                                Items", "Scratch & Dent" and "Not Appears New", with approval from
                                our Customer Care associates.`}
                            </li>
                            <li>
                                {`The Buyer's Premium will now be 17% with a`}{' '}
                                <b>minimum of $3/lot</b>.
                            </li>
                        </ol>
                    </div>
                    <p>
                        Before continuing to browse, bid, or make a purchase, please review the
                        detailed{' '}
                        <a href="/terms" target="_blank">
                            <span>Terms and Conditions</span>
                        </a>
                        .
                    </p>
                    <p>
                        {`By clicking "I acknowledge" below, you will confirm you have read,
                        understood, and agree to the updated terms and conditions.`}
                    </p>
                </div>

                <PrimaryButton
                    className="mb-3"
                    onClick={() =>
                        createUpdateUserSeenNewTerms({
                            flag: 1,
                        })
                    }
                >
                    I acknowledge & Don`t Show Again
                </PrimaryButton>
                <PrimaryButton
                    onClick={() =>
                        createUpdateUserSeenNewTerms({
                            flag: 0,
                        })
                    }
                >
                    I acknowledge & Close
                </PrimaryButton>
            </Dialog>
        </>
    )
}
export default Header
