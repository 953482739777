import React, { useState, useContext, useEffect } from 'react'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import AuthContext from '@/custom/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import { currencyFormat, dateFormatFront } from '@/custom/common/components'
import AlertContext from '@/custom/context/alert/alertContext'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import SEO from '@/utils/SEO'
import UserContext from '@/custom/context/user/userContext'

const MyReturn = () => {
    const [toggleDialog, setToggleDialog] = useState(false)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const { user, loadUser, isAuthenticated } = authContext
    const { getRefund, refund_data } = userContext

    let [viewProduct, setViewProduct] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 12,
        },
    })

    useEffect(() => {
        getRefund(formik.values)
    }, [formik.values])

    useEffect(() => {
        if (refund_data && refund_data?.records) {
            console.log(refund_data.records, 'refund_data')
            setViewProduct(refund_data.records.responseData.refundList)
        }
    }, [refund_data])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    return (
        <DashboardLayout title={'My Refunds'}>
            <div className="dashboardInner myWallet">
                <SEO title="My Refunds" url={window.location.href} />

                {/* <h5>Find refunds from your auction deposits here.</h5> */}
                {viewProduct.length ? (
                    <>
                        <div className="table-responsive dpTable">
                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">{'Refund Date'}</th>
                                        <th scope="col">{'Receipt#'}</th>
                                        <th scope="col">{'Amount'}</th>
                                        <th scope="col">{'Refund Mode'}</th>
                                        <th scope="col">{'Refund Type'}</th>
                                        <th scope="col">{'Refund Reason'}</th>
                                        <th scope="col">{'Refund Notes'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viewProduct.map((data, index) => (
                                        <tr key={index}>
                                            <td>{dateFormatFront(data.credit_date)}</td>
                                            <td>{data.invoice_no || '-'}</td>
                                            <td>{currencyFormat(data.amount)}</td>
                                            <td>{data.refund_mode}</td>
                                            <td>{data.refund_type}</td>
                                            <td>{data.refund_reason}</td>
                                            <td>{data.refund_notes}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                            {/* <h6>
                                {'Showing'} {search_allusercredit.setDisp} {'of'}{' '}
                                {search_allusercredit.totalRecords}
                            </h6>
                             */}
                            <Pagination
                                count={Math.ceil(
                                    refund_data?.records?.responseData?.refundCount /
                                        formik.values.limit,
                                )}
                                page={formik.values.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
        </DashboardLayout>
    )
}

export default MyReturn
