import React, { useEffect, useContext, useState, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Loaders from '@/custom/components/molecules/Loaders'
import Header from '@/custom/components/molecules/Header'
import Footer from '@/custom/components/molecules/Footer'
import MyAuctions from '@/views/Dashboard/MyAuctions'
import ExpiredAuction from '@/views/Dashboard/ExpiredAuction'
import SavedSearch from '@/views/Dashboard/SavedSearch'
import Profile from '@/views/Dashboard/Profile'
import MyCard from '@/views/Dashboard/MyCard'
import ChangePassword from '@/views/Dashboard/Profile/ChangePassword'
import Notifications from '@/views/Dashboard/Notifications'
import MyReturn from '@/views/Dashboard/MyReturn'
import MyCredits from '@/views/Dashboard/MyCredits'
import Checkout from '@/views/Checkout'

const Login = React.lazy(() => import('@/views/Login/login'))
const MyInvoices = React.lazy(() => import('@/views/Dashboard/MyInvoices'))
const Invoice = React.lazy(() => import('@/views/Invoice/index'))
const StaticPage = React.lazy(() => import('@/views/Static/index'))
const ContactUs = React.lazy(() => import('@/views/ContactUs'))
const ProductViewPage = React.lazy(() => import('@/views/ProductView'))
const ForgotPassword = React.lazy(() => import('@/views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('@/views/ResetPassword'))
const Registration = React.lazy(() => import('@/views/Registration'))
const ErrorPage = React.lazy(() => import('@/views/ErrorPage'))
const Home = React.lazy(() => import('@/views/Home'))
const Pickup = React.lazy(() => import('@/views/Static/Pickup'))
const HowPage = React.lazy(() => import('@/views/Static/HowPage'))
const Search = React.lazy(() => import('@/views/Search'))
const PastAuction = React.lazy(() => import('@/views/Search/PastAuction'))

//Routes function===>All major routes are handled here
//Initial Route====>/login
const Routes = () => {
    /*  const [sta, useSta] = useState([])
    let data = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
        },
    }
    let resetData = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
        },
    }
    const getSearch = (e) => {
        console.log('search###', e)
        useSta(e)
    }
     ```<Search
                            showItems={true}
                            filters={true}
                            sortby={true}
                            limit={true}
                            search={{ data, resetData }}
                            getSearch={getSearch}
     />```
    useEffect(() => {
        console.log('@#$sta', sta)
    }, [sta])*/
    return (
        <>
            <Header />
            {/* <Maintanence ipAddress={null} /> */}
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/maintanence" />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/registration">
                        <Registration multiform={false} />
                    </Route>
                    <Route exact path="/pickup_instructions" component={Pickup} />
                    <Route exact path="/how_it_works" component={HowPage} />
                    <Route exact path="/search">
                        <Search showItems={true} filters={true} sortby={true} limit={true} />
                    </Route>
                    <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/watchlist/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/expired_auction" component={ExpiredAuction} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/productView/:pid" component={ProductViewPage} />
                    <PrivateRoute exact path="/payments/saved_cards" component={MyCard} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    <PrivateRoute exact path="/return/:action" component={MyReturn} />
                    <PrivateRoute exact path="/user/credits" component={MyCredits} />
                    <PrivateRoute exact path="/pastAuctions" component={PastAuction} />
                    <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <Route exact path="/contact" component={ContactUs} />
                    <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <Route exact path="/reset_password" component={ResetPassword} />

                    {/* <Route exact path="/reset_password/:token" component={ResetPassword} />
                    {/* 
                    
                    <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/myoffers" component={MakeofferCart} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <Route exact path="/alloffers" component={Offers} />
                  
                    <Route exact path="/locationHours" component={LocationHours} />
                    <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                   
                    <PrivateRoute exact path="/cart" component={Cart} />
                   
                    
                    
                    
                   
                    
                    <PrivateRoute
                        exact
                        path="/return/invoice/:id"
                        component={SellerReturnInvoice}
                    />
                    <PrivateRoute exact path="/schedule/:action" component={MyPickup} />
                    <PrivateRoute exact path="/transactions" component={MyTransactions} />
                    
                    <PrivateRoute exact path="/depositinvoice/:id" component={DepositInvoice} />
                    <PrivateRoute exact path="/deposits/:action" component={MyDeposits} />
                    
                    <PrivateRoute exact path="/payments/bank_details" component={MyBank} />
                    <PrivateRoute exact path="/wallet" component={MyWallet} />

                    <PrivateRoute exact path="/rosoom_success" component={RosoomResponse} />
                    <Route exact path="/seller/templates" component={AuctionTemplates} />
                    <Route exact path="/seller/product/templates" component={ProductTemplates} />
                    <Route exact path="/payments/subscription" component={MySubscription} />
                    <Route exact path="/seller/organization" component={UserView} />
                    <Route exact path="/appointment/schedule/:id" component={AppointmentManage} />
                    <Route exact path="/seller/reports" component={Reports} />
                    <Route
                        exact
                        path="/post_project/auction_detail/:action"
                        component={AuctionType}
                    />
                    <Route exact path="/post_project/create_lot/:action" component={CreateLot} />

                    <PrivateRoute
                        exact
                        path="/seller/auctions/:action"
                        component={SellerMyAuctions}
                    />

                   

                    <PrivateRoute exact path="/seller/lots/:action" component={SellerListing} />

                    <PrivateRoute exact path="/seller/payments/bank_details" component={Bank} />
                    

                    <PrivateRoute exact path="/messages" component={Message} />

                   
                    <Route exact path="/subscription" component={Subscription} /> */}
                    <Route
                        exact
                        path={[
                            '/about',
                            '/expect',
                            '/open_jobs',
                            '/faq',
                            '/refer_a_friend',
                            '/auction_details',
                            '/services',
                            '/help',
                            // '/how_it_works',
                            // '/pickup_instructions',
                            '/guidelines',
                            '/privacy',
                            '/locationHours',
                            '/terms',
                            '/pickup',
                        ]}
                        component={StaticPage}
                    />
                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            {/* <Suspense fallback={<Loaders name="home" />}> */}
            {/* </Suspense> */}
        </>
    )
}
export default withRouter(Routes)
