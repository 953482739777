import {
    USER_LOADED,
    LOGOUT,
    COUNT_TEMP_LOADED,
    COUNT_LOADED,
    AUTHENTICATED,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    CALL_END,
} from './authTypes'

export default (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.data,
            }
        case AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: true,
            }
        case COUNT_LOADED:
            return {
                ...state,
                cartCount: action.payload,
            }
        case COUNT_TEMP_LOADED:
            return {
                ...state,
                cartTempCount: action.payload,
            }
        case LOGOUT:
            localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userData')
            return {
                ...state,
                token: null,
                isAdmin: false,
                isAuthenticated: false,
                loading: false,
                user: {},
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CALL_END:
            return {
                ...state,
                callEnd: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
